import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Typography from "@mui/material/Typography";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";

const OnboardingAccordion = ({ currentStep, thisStep, title, children, expanded, handleChange }) => {
  React.useEffect(() => {
    handleChange(currentStep)(null, true);
  }, [currentStep]);


  return (
    <Accordion
      disableGutters
      elevation={0}
      style={{ width: "90%" }}
      disabled={Number(currentStep) < thisStep}
      expanded={Number(expanded) === thisStep}
      onChange={handleChange(thisStep)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={"panel"+thisStep+"-content"}
        id={"panel"+thisStep+"-header"}
        sx={{ minHeight: "3rem" }}
      >
        {Number(currentStep) > thisStep && <CheckCircleIcon />}
        {Number(currentStep) === thisStep && <EditIcon />}
        <Typography color={"primary"} pl={1} >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default OnboardingAccordion;