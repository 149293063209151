import * as React from "react";
import { Grid, Typography, useMediaQuery, SvgIcon, Paper } from "@mui/material";
import { Box } from "@mui/system";
import useLabels from "../../../labels/useLabels";
import { useNavigate } from "react-router-dom";
import SpeedIcon from "@mui/icons-material/Speed";
import TodayIcon from "@mui/icons-material/Today";
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function ProfileOption(props) {
  const {getLabel} = useLabels();
  const isMobile = props.isMobile;
  const navigate = useNavigate();

  return (
    <Grid item xs={4}>
    <Box display="flex" justifyContent="center">
      <Paper
        elevation={2}
        sx={{
          width: "100px",
          height: "90px",
          borderRadius: "5px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={(event) => {
          if (props.navigateTo!==null && props.navigateTo!==undefined) {
            navigate(props.navigateTo);
          }
          if (props.callToAction!=null && props.callToAction!==undefined) {
            props.callToAction(event);
          }
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            justifyContent={"center"}
            display={"flex"}
            marginBottom={"0.5rem"}
          >
            {props.icon}
          </Grid>
          <Grid
            item
            xs={12}
            justifyContent={"center"}
            display={"flex"}
            paddingInline={"0.2rem"}

          >
            <Typography variant="body1" sx={{textAlign:"center"}}>
              {getLabel(props.label, props.defaultLabel)}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  </Grid>
  );
}
