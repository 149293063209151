import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Box, CircularProgress } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useMutation } from "@tanstack/react-query";
import HttpService from "../../services/HttpService";
import LoadingButton from "./LoadingButton";
import useLabels from "../../labels/useLabels";
import VisualMessage from "./VisualMessage";
import MainBackground from "./MainBackground";

const OTPInput = ({ recipient, from, onClose, onSuccess, openOTP=false }) => {
  const {getLabel} = useLabels();

  const [otpValue, setOtpValue] = React.useState("");
  const [otpIsValid, setOtpIsValid] = React.useState(false);
  const [visualMessage, setVisualMessage] = React.useState();

  const sendOTPMutation = useMutation((OTPRequest) => {
    if (openOTP) {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/login/open/otp/generate`,
        OTPRequest,
        { avoidBearer: true }
      );
    }
    else {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/login/otp/generate`,
        OTPRequest
      );
    }
  });

  const validateOTPMutation = useMutation(
    (OTPValidateRequest) => {
      if (openOTP) {
        return HttpService.getAxiosClient().post(
          window.API_URL + `/login/open/otp/validate`,
          OTPValidateRequest,
          { avoidBearer: true }
        ).then(() => OTPValidateRequest.combinationCode);
      }
      else{
        return HttpService.getAxiosClient().post(
          window.API_URL + `/login/otp/validate`,
          OTPValidateRequest
        ).then(() => OTPValidateRequest.combinationCode);
      }
    },
    {
      onSuccess: (value) => {
        setOtpIsValid(true);
        onSuccess(value);
      },
      onError: (values) => {
        setOtpIsValid(false);
        setVisualMessage({
          title: getLabel(
            "recoveryPassword.step1.verify.failed.tittle",
            "Verification failed"
          ),
          message: getLabel(
            "recoveryPassword.step1.verify.failed.message",
            "There was an error, was not able to lock the card."
          ),
          onClose: () => setVisualMessage(),
        });
      },
    }
  );

  const navigate = useNavigate();

  const handleBackButtonClick = onClose;

  const handleChange = (newValue) => {
    setOtpValue(newValue);
  };

  const handleComplete = (value) => {
    validateOTPMutation.mutate({
      recipient: recipient,
      from: from,
      combinationCode: value,
    });
  };

  React.useEffect(() => {
    sendOTPMutation.mutate({ recipient: recipient, from: from });
  }, []);

  return (
      <Grid
        justifyContent="center"
        alignItems="center"
        textAlign={"center"}
        container
        style={{ minHeight: "100vh" }}
      >
        {visualMessage && (
          <VisualMessage
            title={visualMessage.title}
            message={visualMessage.message}
            severity={visualMessage.severity}
            onClose={visualMessage.onClose}
          />
        )}
        <Grid item sm={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LockOutlinedIcon color="primary" sx={{ fontSize: 30 }} />
              <Typography color="primary" variant="h4">
                Verification
              </Typography>
            </Box>
            <Typography pt={3} pl={1} pr={1}>
              Please enter the validation code sent to [{recipient}] to
              complete the transaction
            </Typography>
            <Box
              pt={1}
              sx={{
                display: "flex",
                gap: "10px",
                maxWidth: "350px",
                marginInline: "auto",
                ".MuiOutlinedInput-input": {
                  fontSize: "25px",
                },
              }}
            >
              <MuiOtpInput
                length={6}
                value={otpValue}
                onChange={handleChange}
                autoFocus
                onComplete={handleComplete}
              />
            </Box>
            {validateOTPMutation.isLoading ? (
              <CircularProgress color="secondary"/>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography pt={2}>Didn't receive the code?</Typography>
                <LoadingButton
                  color="secondary"
                  type="submit"
                  loading={sendOTPMutation.isLoading}
                  onClick={(event) => {
                    event.preventDefault();
                    sendOTPMutation.mutate({
                      recipient: recipient,
                      from: from,
                    });
                  }}
                >
                  <Typography pt={2}>
                    {getLabel(
                      "recoveryPassword.step1.btnResend",
                      "Resend"
                    )}
                  </Typography>
                </LoadingButton>
              </Box>
            )}

            <Button onClick={handleBackButtonClick}>Back</Button>
          </div>
        </Grid>
      </Grid>
  );
};

export default OTPInput;
