import React, { useState, useEffect } from "react";
import { Alert, AlertTitle, Slide } from "@mui/material";

const VisualMessage = ({ title, message, onClose, severity = "error", autoClose= true,...props }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (autoClose) {
        setVisible(false);
        onClose();
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [onClose, autoClose]);

  const handleClose = () => {
    setVisible(false);
    onClose();
  };

  return (
    <Slide direction="down" in={visible} mountOnEnter unmountOnExit>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          width: 350,
          margin: "auto",
          zIndex: 9999,
        }}
      >
        <Alert color="secondary" severity={severity} onClose={handleClose}>
          <AlertTitle>{title}</AlertTitle>
          {message}
        </Alert>
      </div>
    </Slide>
  );
};

export default VisualMessage;
