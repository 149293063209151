import { useQuery } from "@tanstack/react-query";
import HttpService from "./HttpService";

async function fetchToken() {
  const response = await HttpService.getAxiosClient().get(
    window.API_URL + `/card/token`
  );
  return response;
}

const CardTokenService = () => {
  const { isLoading, isError, data, error, isFetching } = useQuery(
    ["CardToken"],
    fetchToken,
    {
      refetchOnWindowFocus: false,
      staleTime: 0,
      cacheTime: 0,
    }
  );

  const cardToken = data?.data || null;
 
  return {
    cardToken,
    isFetching,
    isLoading,
    isError,
    error
  };
};

export default CardTokenService;
