import * as React from "react";
import Box from "@mui/material/Box";
import { useParams } from 'react-router-dom';
import Background from "../components/ui/Background";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  useMediaQuery
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Typography from "@mui/material/Typography";

import { useFormik } from "formik";
import * as validatorYup from "yup";
import { useMutation } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import UserService from "../services/UserService";
import { useNavigate } from "react-router-dom";
import VisualMessage from "../components/ui/VisualMessage";
import LoadingButton from "../components/ui/LoadingButton";
import useLabels from "../labels/useLabels";
import LanguageIcon from "@mui/icons-material/Language";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage, setLoginLanguage } from "../store/slices/languageSlice";
import { setUser } from "../store/slices/userSlice";
import store from "../store/store";

const validationSchema = validatorYup.object({
  username: validatorYup.string().required(),
  password: validatorYup.string().required(),
});

const vsChangePassword = validatorYup.object({
  oldPassword: validatorYup.string().required(),
  newPassword1: validatorYup.string().required(),
  newPassword2: validatorYup.string().required(),
});

export default function LoginPage() {
  const {getLabel} = useLabels();

  const [loaded, setLoaded] = React.useState(false);
  const [changePassword, setChangePassword] = React.useState(false);
  const [backgroundImage, setBackgroundImage] = React.useState(null);
  const [logo, setLogo] = React.useState(null);
  const [visualMessage, setVisualMessage] = React.useState();
  const navigate = useNavigate();
  const langChangeFitTop = useMediaQuery("(min-height:740px)");
  

  React.useEffect(() => {
    async function getInitials(subdomain) {
      try {
        const [backgroundImage, logo] = await Promise.all([
          import(`../images/bg_login.${subdomain}.jpg`),
          import(`../images/logo.${subdomain}.svg`),
        ]);
        setBackgroundImage(backgroundImage.default);
        setLogo(logo.default);
      } catch (error) {
        getInitials("rv");
      }
    }
    getInitials(window.subdomain);
    dispatch(setUser(""));
  }, []);

  const LoginMutation = useMutation(
    (loginData) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/login`,
        loginData,
        { avoidBearer: true }
      );
    },
    {
      onSuccess: (value) => {
        UserService.setTokens(
          value.data.token,
          value.data.refreshToken,
          value.data.idToken
        );

        if (store.getState().language.profile === value.data.lang) 
          UserService.updateToken(() => {
            navigate("/home");
          });
        else {
          dispatch(setLanguage(value.data.lang ? value.data.lang : "en")); 
          UserService.updateToken(() => {
            navigate("/confirmLanguage");
          });
        }
      },
      onError: (values) => {
        if (values.response.data === "Account is not fully set up")
          setChangePassword(true);
        else
          setVisualMessage({
            title: getLabel("login.failed.title", "Login failed."),
            message: getLabel(
              "login.failed.message",
              "Please check your username and password and try again."
            ),
            onClose: () => setVisualMessage(),
          });
      },
    }
  );

  const changePasswordMutation = useMutation(
    (changePasswordData) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/login/changePassword`,
        changePasswordData,
        { avoidBearer: true }
      );
    },
    {
      onSuccess: (value) => {
        setVisualMessage({
          title: getLabel(
            "login.changePassword.ok.title",
            "Password successfully updated"
          ),
          message: getLabel(
            "login.changePassword.ok.message",
            "Your password has been successfully updated. Please log in using your new password."
          ),
          severity: "success",
          onClose: () => setVisualMessage(),
        });
        formik.resetForm();
        formikChangePassword.resetForm();
        setChangePassword(false);
      },
      onError: (values) => {
        setVisualMessage({
          title: getLabel(
            "login.changePassword.error.title",
            "Password update failed"
          ),
          message:
            values.response.status === 401
              ? "The old password is incorrect"
              : values.response?.statusText + " " + values.response?.data,
          severity: "error",
          onClose: () => setVisualMessage(),
        });
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      rememberMe: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      LoginMutation.mutate({ ...values });
    },
  });

  const formikChangePassword = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword1: "",
      newPassword2: "",
    },
    validationSchema: vsChangePassword,
    onSubmit: (values) => {
      values.username = formik.values.username;
      changePasswordMutation.mutate({ ...values });
    },
  });

  const [showPassword, setShowPassword] = React.useState(false);
  const language = useSelector((state) => state.language.profile);
  const dispatch = useDispatch();
  const { username : urlUsername } = useParams();
  const [usernameFromUrl, setUsernameFromUrl] = React.useState(urlUsername);

  React.useEffect(() => {
    if (usernameFromUrl) {
      let decodedData = window.atob(usernameFromUrl); // "Hello, World!"
      formik.setFieldValue("username", decodedData);
    }
  }, [usernameFromUrl]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const forgotPassword = () => {
    if (formik.values.username === "")
      setVisualMessage({
        title: getLabel("login.forgotPassword.error.title", "Error"),
        message: getLabel(
          "login.forgotPassword.error.message",
          "Please enter your username and try again."
        ),
        severity: "error",
        onClose: () => setVisualMessage(),
      });
    else {
      dispatch(setUser(formik.values.username));
      navigate("/recoveryPassword/step1");
    }
  };

  const changePasswordForm = () => (
    <Grid
      alignItems="center"
      container
      justifyContent="center"
      padding={0}
      sx={{ width: "100%", height: "100%" }}
    >
      <Typography
        variant="h6"
        sx={{
          fontFamily: "Open Sans",
          color: "loginText.main",
        }}
      >
        {getLabel(
          "login.changePassword.title",
          "Please change your password"
        )}
      </Typography>
      <Grid item sx={{ width: "85%" }}>
        <InputLabel
          htmlFor="oldPassword"
          sx={{
            fontFamily: "Open Sans",
            color: "loginText.main",
          }}
        >
          {getLabel(
            "login.changePassword.oldPassword.label",
            "Old password:"
          )}
        </InputLabel>
      </Grid>
      <Grid item sx={{ width: "85%" }}>
        <FormControl fullWidth variant="outlined">
          <OutlinedInput
            id="oldPassword"
            fullWidth
            autoComplete="password"
            sx={{
              bgcolor: "white",
            }}
            type={showPassword ? "text" : "password"}
            value={formikChangePassword.values.oldPassword}
            onChange={formikChangePassword.handleChange}
            error={
              formikChangePassword.touched.oldPassword &&
              Boolean(formikChangePassword.errors.oldPassword)
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
      <Grid item sx={{ width: "85%" }}>
        <InputLabel
          htmlFor="newPassword1"
          sx={{
            fontFamily: "Open Sans",
            color: "loginText.main",
          }}
        >
          {getLabel(
            "login.changePassword.newPassword1.label",
            "New password:"
          )}
        </InputLabel>
      </Grid>
      <Grid item sx={{ width: "85%" }}>
        <FormControl fullWidth variant="outlined">
          <OutlinedInput
            id="newPassword1"
            fullWidth
            autoComplete="password"
            sx={{
              bgcolor: "white",
            }}
            type={showPassword ? "text" : "password"}
            value={formikChangePassword.values.newPassword1}
            onChange={formikChangePassword.handleChange}
            error={
              formikChangePassword.touched.newPassword1 &&
              Boolean(formikChangePassword.errors.newPassword1)
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
      <Grid item sx={{ width: "85%" }}>
        <InputLabel
          htmlFor="newPassword2"
          sx={{
            fontFamily: "Open Sans",
            color: "loginText.main",
          }}
        >
          {getLabel(
            "login.changePassword.newPassword2.label",
            "Repeat the new password:"
          )}
        </InputLabel>
      </Grid>
      <Grid item sx={{ width: "85%" }}>
        <FormControl fullWidth variant="outlined">
          <OutlinedInput
            id="newPassword2"
            fullWidth
            autoComplete="password"
            sx={{
              bgcolor: "white",
            }}
            type={showPassword ? "text" : "password"}
            value={formikChangePassword.values.newPassword2}
            onChange={formikChangePassword.handleChange}
            error={
              formikChangePassword.touched.newPassword2 &&
              Boolean(formikChangePassword.errors.newPassword2)
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>

      <Grid item sx={{ width: "85%" }}>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={changePasswordMutation.isLoading}
          sx={{ width: "100%", mb: "10px" }}
        >
          {getLabel("changePassword.btnLabel", "Change Password")}
        </LoadingButton>
      </Grid>
    </Grid>
  );

  const loginForm = () => (
    <Grid
      alignItems="center"
      container
      justifyContent="center"
      padding={0}
      pt={2}
      sx={{ width: "100%", height: "100%" }}
    >
      <Grid item sx={{ width: "85%" }}>
        <InputLabel
          htmlFor="username"
          sx={{
            fontFamily: "Open Sans",
            color: "loginText.main",
          }}
        >
          {getLabel("login.username")}
        </InputLabel>
      </Grid>
      <Grid item sx={{ width: "85%" }}>
        <FormControl fullWidth variant="outlined">
          <OutlinedInput
            fullWidth
            id="username"
            sx={{
              bgcolor: "white",
            }}
            placeholder={getLabel("login.username.placeholder")}
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.touched.username && Boolean(formik.errors.username)}
          />
        </FormControl>
      </Grid>
      <Grid item sx={{ width: "85%" }}>
        <InputLabel
          htmlFor="password"
          sx={{
            fontFamily: "Open Sans",
            color: "loginText.main",
          }}
        >
          {getLabel("login.password")}
        </InputLabel>
      </Grid>
      <Grid item sx={{ width: "85%" }}>
        <FormControl fullWidth variant="outlined">
          <OutlinedInput
            id="password"
            fullWidth
            autoComplete="password"
            sx={{
              bgcolor: "white",
            }}
            type={showPassword ? "text" : "password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
      <Grid
        alignItems="center"
        container
        padding={0}
        pt={2}
        flexDirection={"row"}
        justifyContent="space-between"
        item
        sx={{ width: "85%" }}
      >
        <Grid item>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox size="large" sx={{ color: "primary" }} />}
              label={getLabel("login.rememberMe")}
              sx={{ color: "loginText.main" }}
            />
          </FormGroup>
        </Grid>
        <Grid item textAlign="right" alignContent="right">
          <Button
            size="small"
            sx={{ mr: 2, color: "loginText.main" }}
            width="100%"
            onClick={() => forgotPassword()}
          >
            {getLabel("login.forgotPassword")}
          </Button>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" sx={{ width: "85%" }}>
        <Grid item xs={12}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={LoginMutation.isLoading}
            sx={{ width: "100%", mb: "10px" }}
          >
            {getLabel("login.bntLabel")}
          </LoadingButton>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            variant="contained"
            color="secondary"
            sx={{ width: "100%", mb: "25px" }}
            onClick={() => navigate("/onboarding")}
          >
            {getLabel("login.signup.bntLabel","Sign Up")}
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );

  React.useEffect(() => {
    document.title = getLabel("login.title","Sign Up for a Rellevate Account Now! Start your financial wellness journey");
  }, [getLabel(("login.title"))]);
  

  return (
    <div style={{ display: loaded ? "block" : "none" }}>
      <Background image={backgroundImage} onLoad={() => setLoaded(true)}>
        {visualMessage && (
          <VisualMessage
            title={visualMessage.title}
            message={visualMessage.message}
            severity={visualMessage.severity}
            onClose={visualMessage.onClose}
          />
        )}
        <main>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={
              !changePassword
                ? formik.handleSubmit
                : formikChangePassword.handleSubmit
            }
          >
            <Grid container alignItems="center" sx={{ height: "100vh" }}>
              <Grid container alignItems="center" sx={{ height: "600px" }}>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <img src={logo} alt="Company Logo" width="266" />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "center", mb: 8 }}>
                  <Typography
                    color="primary"
                    variant="h1"
                    sx={{ fontSize: "24px" }}
                  >
                    {getLabel("login.header")}
                  </Typography>
                </Grid>
                <Grid container justifyContent="center">
                  <Box
                    sx={{
                      borderRadius: { sm: "10px" },
                      bgcolor: { sm: "hsla(210,3%,75%,.4)" },
                      width: { sm: "80%" },
                      height: "28rem",
                    }}
                  >
                    {!changePassword ? loginForm() : changePasswordForm()}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Typography
                      variant="inherit"
                      maxWidth={"85%"}
                      sx={{
                        fontFamily: "Open Sans",
                        backgroundColor: "loginText.back",
                        color: "loginText.main",
                        textAlign: "center",
                      }}
                    >
                      {getLabel("login.footer")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </main>
      </Background>
      <footer>
        <Box
          sx={{
            position: "fixed",
            bottom: langChangeFitTop ? "0" : "auto",
            top: langChangeFitTop ? "auto" : "0",
            left: 0,
            width: "100%",
            backgroundColor: "rgba(240, 240, 240, 0.5)",
            padding: "5px",
            display: "flex",
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
            <Typography variant="caption" sx={{ color: "loginText.main" }}>
              { 
                `version: ${String(new Date().getMonth() + 1).padStart(2, '0')}${String(new Date().getDate()).padStart(2, '0')}${window.BUILD_NUMBER}`
              }
            </Typography>
            <Button
              size="small"
              sx={{ mr: 2, color: "loginText.main" }}
              onClick={() => {
                dispatch(setLoginLanguage(language === "en" ? "es" : "en"));
              }}
            >
              <LanguageIcon sx={{ mr: 1 }} />
              {language === "en" ? "Español" : "English"}
            </Button>
        </Box>
      </footer>
    </div>
  );
}
