import Keycloak from "keycloak-js";
import StorageService from "./StorageService";

const _kc = new Keycloak('/keycloak.json?v=253');

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc.init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    checkLoginIframe: false,
    pkceMethod: 'S256',    
  })
    .then((authenticated) => {
        onAuthenticatedCallback();
    })
    .catch(console.error);
};

const doLogin = _kc.login;

const doLogout = (options) => {
  removeTokensFromStore();
  return _kc.logout(options);
};

const getToken = () => {
  if (typeof _kc.token === "undefined" && !!StorageService.get("_kc.token"))
    setTokens(
      StorageService.get("_kc.token"),
      StorageService.get("_kc.refreshToken"),
      StorageService.get("_kc.idToken")
    );
  return _kc.token;
};

const storeTokens = () => {
  StorageService.set("_kc.token", _kc.token);
  StorageService.set("_kc.refreshToken", _kc.refreshToken);
  StorageService.set("_kc.idToken", _kc.idToken);
};

const removeTokensFromStore = () => {
  StorageService.remove("_kc.token");
  StorageService.remove("_kc.refreshToken");
  StorageService.remove("_kc.idToken");
};

const setTokens = (token, refreshToken, idToken) => {
  _kc.token = token;
  _kc.refreshToken = refreshToken;
  _kc.idToken = idToken;
  storeTokens();
};

const isLoggedIn = () => {
  return !!getToken();
};

const updateToken = (successCallback) =>
  _kc
    .updateToken(5)
    .then(() => {
      storeTokens();
      return successCallback();
    })
    .catch((error) => {
      doLogout({ redirectUri: window.location.origin });
    });

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  setTokens
};

export default UserService;
