import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import * as React from "react";
import Menu from "../components/ui/Menu";
import MainBackground from "../components/ui/MainBackground";
import UserService from "../services/UserService";

export default function CardNotFound() {
  const isMobile = useMediaQuery("(max-width:600px)");

  const logoutFunc = (event) => {
    UserService.doLogout({ redirectUri: window.location.origin });
    event.preventDefault();
  };

  return (
    <div>
      <Dialog open={true}>
        <DialogTitle>IMPORTANT INFORMATION</DialogTitle>
        <DialogContent>
            <Typography>
              Thanks for applying for a Rellevate Account. Your application is
              currently in process, our team may need to reach out to you for
              additional information to complete the application. We will either
              send a text from 203.564.9692 or an email from
              service@rellevate.com. You can also contact us via these channels
              if you have questions.
            </Typography>
            <Divider sx={{padding:2}} />
            <Typography variant={"caption"}>
              Protecting you and your data is our priority, and sometimes that
              means we need extra information to process an application. Federal
              law requires that we collect this information so that we can
              verify your identity and help fight fraud. All financial
              institutions are required to obtain, verify, and record
              information that identifies each person who opens an account in
              accordance with the USA PATRIOTS ACT. Rellevate facilitates
              banking services through Sutton Bank. Rellevate Debit Card issued
              by Sutton Bank, Member FDIC, pursuant to a license from Visa
              U.S.A. Inc.
             </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={logoutFunc} variant={"outlined"}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <MainBackground isMobile={isMobile}/>
      <Menu isMobile={isMobile} cardFound={false} />
    </div>
  );
}
