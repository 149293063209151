import * as React from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import Menu from "../../components/ui/Menu";
import useLabels from "../../labels/useLabels";
import { useNavigate } from "react-router-dom";
import LanguageIcon from "@mui/icons-material/Language";
import LoadingButton from "../../components/ui/LoadingButton";
import { useMutation } from "@tanstack/react-query";
import HttpService from "../../services/HttpService";
import VisualMessage from "../../components/ui/VisualMessage";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../store/slices/languageSlice";
import MainBackground from "../../components/ui/MainBackground";


export default function ChangeLanguage({confirm}) {
  const {getLabel} = useLabels();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [visualMessage, setVisualMessage] = React.useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.profile);


  const langMutation = useMutation(
    (language) => {
      return HttpService.getAxiosClient()
        .post(window.API_URL + `/login/lang`, language)
        .then(() => language);
    },
    {
      onSuccess: (language) => {
        dispatch(setLanguage(language));
      },
      onError: (values) => {
        setVisualMessage({
          title: getLabel("changeLanguage.error.title", "Error"),
          message: getLabel(
            "changeLanguage.error.message",
            "Error changing language"
          ),
          onClose: () => setVisualMessage(),
        });
      },
    }
  );

  return (
    <div>
      {visualMessage && (
        <VisualMessage
          title={visualMessage.title}
          message={visualMessage.message}
          severity={visualMessage.severity}
          onClose={visualMessage.onClose}
        />
      )}
      <MainBackground
        component="form"
        noValidate
        autoComplete="off"
        isMobile={isMobile}
      >
        <Grid container style={{width:isMobile?"100%":"65%", margin:!isMobile?"3rem auto":0}}  >
          <Grid item xs={12} p={1}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LanguageIcon
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                  paddingRight: "1rem",
                })}
              />
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {confirm !== "yes"
                  ? getLabel("changeLanguage.title", "Select language")
                  : getLabel("confirmLanguage.title", "Confirm language")}
              </Typography>
            </div>
          </Grid>

          <FormControl>
            <FormLabel id="radio-buttons-group-label">
              {
                confirm !== "yes"
                ? getLabel("changeLanguage.text1", "The language selected will be applicable throughout the system from any device")
                : getLabel("confirmLanguage.text1", "The language selected will be applicable throughout the system from any device")}
            </FormLabel>
            <RadioGroup
              aria-labelledby="radio-buttons-group-label"
              defaultValue={language}
              name="radio-buttons-group"
              onChange={(event) => langMutation.mutate(event.target.value)}
            >
              <FormControlLabel
                value="en"
                control={<Radio disabled={langMutation.isLoading} />}
                label={"English"}
              />
              <FormControlLabel
                value="es"
                control={<Radio disabled={langMutation.isLoading} />}
                label="Español"
              />
            </RadioGroup>
          </FormControl>

          <Grid
            item
            xs={12}
            pt={0}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {langMutation.isLoading && <CircularProgress size={20} />}
          </Grid>

          <Grid item xs={12} pt={0}>
            <Grid alignContent="center" item>
              <LoadingButton
                fullWidth
                sx={{
                  marginTop: "1rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
                variant="contained"
                color="primary"
                onClick={() => {
                  if (confirm !== "yes") {
                    navigate(-1);
                  } else {
                    navigate("/home");
                  }
                }}
              >
                { confirm !== "yes"
                  ? getLabel("general.back", "Go Back")
                  : getLabel("general.continue", "Continue")
                }
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </MainBackground>
      <Menu isMobile={isMobile} />
    </div>
  );
}
