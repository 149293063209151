import { useQuery } from "@tanstack/react-query";
import HttpService from "./HttpService";

async function fetchPADInfo() {
  const response = await HttpService.getAxiosClient().get(
    window.API_URL + `/pad/info`
  );
  return response;
}

const PADService = (isEnabled) => {
  const { isLoading, isError, data, error } = useQuery(
    ["PADInfo"],
    fetchPADInfo,
    {
      refetchOnWindowFocus: false,
      staleTime: 10000,
      enabled: isEnabled
    }
  );

  const padInfo = data?.data || null;
 
  return {
    padInfo,
    isLoading,
    isError,
    error
  };
};

export default PADService;
