import {
  Grid,
  Typography,
  useMediaQuery,
  SvgIcon,
  FormControl,
  OutlinedInput,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import * as React from "react";
import { Box } from "@mui/system";
import Menu from "../../components/ui/Menu";
import useLabels from "../../labels/useLabels";
import LoadingButton from "../../components/ui/LoadingButton";
import VisualMessage from "../../components/ui/VisualMessage";
import { ReactComponent as sendMoneyIcon } from "../../images/sendmoney.svg";
import CardService from "../../services/CardService";
import { useFormik } from "formik";
import { ref as validatorYupRef } from "yup";
import * as validatorYup from "yup";
import { CircularProgress } from "@mui/material";
import { addDays, addMonths, format } from "date-fns";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import HttpService from "../../services/HttpService";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import RecipientService from "../../services/payments/RecipientService";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SuccessfullTransaction from "../../components/ui/SuccessfullTransaction";
import { useNavigate } from "react-router-dom";
import MainBackground from "../../components/ui/MainBackground";

export default function MainSite() {
  const {getLabel} = useLabels();
  const [visualMessage, setVisualMessage] = React.useState();
  const [visibleAddRecipient, setVisibleAddRecipient] = React.useState(false);
  const [openDP, setOpenDP] = React.useState(false);
  const [transactionData, setTransactionData] = React.useState(null);

  React.useEffect(() => {
    formikAddRecipient.resetForm()
  }, [visibleAddRecipient]);

  const addRecipientMutation = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/payment/recipient`,
        data
      );
    },
    {
      onSuccess: (value) => {
          setVisualMessage({
            title: getLabel(
              "sendMoney.addAgent.success.title",
              "Recipient added successfully"
            ),
            message: getLabel(
              "sendMoney.addAgent.success.message",
              "The recipient was added successfully."
            ),
            onClose: () => {
              setVisualMessage();
              setVisibleAddRecipient(false);
            },
          });
        },
      onError: (values) => {
        if (values.response.data) {
          setVisualMessage({
            title: getLabel(
              "sendMoney.addAgent.error.title",
              "Error adding payee"
            ),
            message: values.response.data,
            onClose: () => setVisualMessage(),
          });
        }
        else
        {
          setVisualMessage({
            title: getLabel(
              "sendMoney.addAgent.error.title",
              "Error adding payee"
            ),
            message: "There was an error adding the payee. Please try again later.",
            onClose: () => setVisualMessage(),
          });
        }
      },
    }
  );

  const nextDayMutation = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/payment/transfer`,
        data
      );
    },
    {
      onSuccess: (value) => {
        if (
          value.status >= 200 &&
          value.status < 300 &&
          value?.data?.transactionId
        ) {
          setTransactionData(value.data);
        } else {
          setVisualMessage({
            title: getLabel(
              "sendMoney.error.title",
              "Error during send Money Next-Day"
            ),
            message: getLabel(
              "sendMoney.error.message",
              "There was an error during send Money Next-Day. Please try again later."
            ),
            onClose: () => setVisualMessage(),
          });
        }
      },
      onError: (values) => {
        let errorMessage =
          "There was an error during send Money Next-Day. Please try again later.";
        if (values?.response?.data?.message) {
          errorMessage = values.response.data.message;
        }
        setVisualMessage({
          title: getLabel(
            "sendMoney.error.title",
            "Error during send Money Next-Day"
          ),
          message: getLabel("sendMoney.error.message", errorMessage),
          onClose: () => setVisualMessage(),
        });
      },
    }
  );

  const cardService = CardService();

  const validationSendMoneySchema = validatorYup.object({
    recipient: validatorYup
      .object()
      .required(getLabel("", "Recipient is required")),
    amount: validatorYup
      .number()
      .required("Amount is required")
      .moreThan(0, "Amount must be greater than zero")
      .typeError("Amount must be a number")
      .max(
        cardService.cardDetail.availableBalance,
        "Amount cannot exceed Available Balance"
      ),
    when: "",
    dateType: "",
    securityQuestion: validatorYup
      .string()
      .required(getLabel("", "Security Question is required")),
    securityAnswer: validatorYup
      .string()
      .required(getLabel("", "Security Answer is required")),
  });

  const validationAddRecipientSchema = validatorYup.object({
    firstName: validatorYup
      .string()
      .required(
        getLabel(
          "sendMoney.recipient.add.firstName",
          "First name is required"
        )
      ),
    lastName: validatorYup
      .string()
      .required(
        getLabel(
          "sendMoney.recipient.add.lastName",
          "Last name is required"
        )
      ),
    communicationType: validatorYup
      .string()
      .required(
        getLabel(
          "sendMoney.recipient.add.communicationType",
          "Communication type is required"
        )
      ),
    email: validatorYup.string().when("communicationType", {
      is: (value) => value === "EMAIL",
      then: () => validatorYup.string().email().required("Email is required"),
    }),
    confirmEmail: validatorYup.string().when("communicationType", {
      is: (value) => value === "EMAIL",
      then: () =>
        validatorYup
          .string()
          .required("Confirmation Email is required")
          .oneOf(
            [validatorYupRef("email"), null],
            getLabel(
              "sendMoney.recipient.add.emailConfirm",
              "Emails must match"
            )
          ),
    }),
    phone: validatorYup.string().when("communicationType", {
      is: (value) => value === "PHONE",
      then: () =>
        validatorYup
          .string()
          .matches(
            /^(\+1|1)?\s?-?\.?\(?\d{3}\)?\s?-?\.?\d{3}\s?-?\.?\d{4}$/,
            "Phone number is not valid"
          )
          .required("Phone is required"),
    }),
    confirmPhone: validatorYup.string().when("communicationType", {
      is: (value) => value === "PHONE",
      then: () =>
        validatorYup
          .string()
          .required("Confirmation Phone is required")
          .oneOf(
            [validatorYupRef("phone"), null],
            getLabel(
              "sendMoney.recipient.add.phoneConfirm",
              "Phone numbers must match"
            )
          ),
    }),
  });

  const queryClient = useQueryClient();


  const formikAddRecipient = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      communicationType: "",
      email: "",
      confirmEmail: "",
      phone: "",
      confirmPhone: "",
    },
    validationSchema: validationAddRecipientSchema,
    onSubmit: (values) => {
      const transformJson = (original) => {
        return {
          idCommunication: original.communicationType === "EMAIL" ? original.email : original.phone,
          firstName: original.firstName,
          lastName: original.lastName,
          isOwn: false,
          address: null,
          idMoneyMovementBeneficiary:null,
          idMoneyMovementItem:null,
          alias: null,
          typeMoneyMovementItem: null,
          typeCommunication: original.communicationType,
          mask: null,
        };
      };

      const transformedJson = transformJson(values);
      queryClient.setQueryData(["recipients", "nextDay"], (old) => {
        old.data.beneficiaries.push(transformedJson);
        return old;
      });

      setVisualMessage({
        title: getLabel(
          "sendMoney.addAgent.success.title",
          "Recipient added successfully"
        ),
        message: getLabel(
          "sendMoney.addAgent.success.message",
          "The recipient was added successfully."
        ),
        onClose: () => {
          setVisualMessage();
          setVisibleAddRecipient(false);
        },
      });
    },
  });

  const formikSendMoney = useFormik({
    initialValues: {
      recipient: null,
      amount: "",
      when: "",
      dateType: "",
      securityQuestion: "",
      securityAnswer: "",
    },
    validationSchema: validationSendMoneySchema,
    onSubmit: (values) => {
      const transformedJson = {
        lastName: values.recipient.lastName,
        amount: Number(values.amount),
        transferFrequency: values.dateType,
        idMoneyMovementBeneficiary: null,
        emailOrPhone: values.recipient.idCommunication,
        idMoneyMovementItem: values.recipient.id,
        communicationType: values.recipient.typeCommunication,
        transferDate: format(values.when, "yyyy/MM/dd"),
        firstName: values.recipient.firstName,
        transferType: "nextDay",
        typeMoneyMovementItem: values.recipient.typeMoneyMovementItem,
        secretAnswer: values.securityAnswer,
        secretQuestion: values.securityQuestion,
      };
      nextDayMutation.mutate(transformedJson);
    },
  });

  const navigate = useNavigate();

  const recipientService = RecipientService("nextDay");

  const isMobile = useMediaQuery("(max-width:600px)");

  if (recipientService.isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  const FormAddRecipient = () => {
    return (
      <Dialog
        open={visibleAddRecipient}
        onClose={(event, reason) => {
          reason !== "backdropClick" && setVisibleAddRecipient(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown={true}
      >
        <DialogTitle id="alert-dialog-title">
          {getLabel("sendMoney.repcipient.add.title", "Add Recipient")}
        </DialogTitle>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={formikAddRecipient.handleSubmit}
        >
          <DialogContent>
            <Grid container spacing={2} pt={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="First Name"
                  id="firstName"
                  type={"text"}
                  value={formikAddRecipient.values.firstName}
                  onChange={formikAddRecipient.handleChange}
                  onBlur={formikAddRecipient.handleBlur}
                  error={
                    formikAddRecipient.touched.firstName &&
                    Boolean(formikAddRecipient.errors.firstName)
                  }
                  helperText={
                    formikAddRecipient.touched.firstName &&
                    formikAddRecipient.errors.firstName
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Last Name"
                  id="lastName"
                  type={"text"}
                  value={formikAddRecipient.values.lastName}
                  onChange={formikAddRecipient.handleChange}
                  onBlur={formikAddRecipient.handleBlur}
                  error={
                    formikAddRecipient.touched.lastName &&
                    Boolean(formikAddRecipient.errors.lastName)
                  }
                  helperText={
                    formikAddRecipient.touched.lastName &&
                    formikAddRecipient.errors.lastName
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={6}
                    pr={2}
                    style={{ display: "flex", justifyContent: "right" }}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      color={
                        formikAddRecipient.values.communicationType === "EMAIL"
                          ? "secondary"
                          : "primary"
                      }
                      onClick={() =>
                        formikAddRecipient.setFieldValue(
                          "communicationType",
                          "EMAIL"
                        )
                      }
                    >
                      Email
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    pl={2}
                    style={{ display: "flex", justifyContent: "left" }}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      color={
                        formikAddRecipient.values.communicationType === "PHONE"
                          ? "secondary"
                          : "primary"
                      }
                      onClick={() =>
                        formikAddRecipient.setFieldValue(
                          "communicationType",
                          "PHONE"
                        )
                      }
                    >
                      Phone
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <FormHelperText sx={{ color: "red" }}>
                      {formikAddRecipient.touched.lastName && formikAddRecipient.errors.communicationType}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Grid>

              {formikAddRecipient.values.communicationType === "EMAIL" && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Email"
                      id="email"
                      type={"text"}
                      value={formikAddRecipient.values.email}
                      onChange={formikAddRecipient.handleChange}
                      onBlur={formikAddRecipient.handleBlur}
                      error={
                        formikAddRecipient.touched.email &&
                        Boolean(formikAddRecipient.errors.email)
                      }
                      helperText={
                        formikAddRecipient.touched.email &&
                        formikAddRecipient.errors.email
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Confirm email"
                      id="confirmEmail"
                      type={"text"}
                      value={formikAddRecipient.values.confirmEmail}
                      onChange={formikAddRecipient.handleChange}
                      onBlur={formikAddRecipient.handleBlur}
                      error={
                        formikAddRecipient.touched.confirmEmail &&
                        Boolean(formikAddRecipient.errors.confirmEmail)
                      }
                      helperText={
                        formikAddRecipient.touched.confirmEmail &&
                        formikAddRecipient.errors.confirmEmail
                      }
                    />
                  </Grid>
                </>
              )}
              {formikAddRecipient.values.communicationType === "PHONE" && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Phone"
                      id="phone"
                      type={"text"}
                      value={formikAddRecipient.values.phone}
                      onChange={formikAddRecipient.handleChange}
                      onBlur={formikAddRecipient.handleBlur}
                      error={
                        formikAddRecipient.touched.phone &&
                        Boolean(formikAddRecipient.errors.phone)
                      }
                      helperText={
                        formikAddRecipient.touched.phone &&
                        formikAddRecipient.errors.phone
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Confirm phone"
                      id="confirmPhone"
                      type={"text"}
                      value={formikAddRecipient.values.confirmPhone}
                      onChange={formikAddRecipient.handleChange}
                      onBlur={formikAddRecipient.handleBlur}s
                      error={
                        formikAddRecipient.touched.confirmPhone &&
                        Boolean(formikAddRecipient.errors.confirmPhone)
                      }
                      helperText={
                        formikAddRecipient.touched.confirmPhone &&
                        formikAddRecipient.errors.confirmPhone
                      }
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              variant="contained"
              size="small"
              type="submit"
              loading={addRecipientMutation.isLoading}
            >
              {getLabel("recipient.add.save", "Save Recipient")}
            </LoadingButton>
            <LoadingButton
              variant="contained"
              size="small"
              onClick={() => setVisibleAddRecipient(false)}
              color="secondary"
            >
              {getLabel("recipient.add.close", "Close")}
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
    );
  };

  const handleSuccessfullTransactionClose = () => {
    formikSendMoney.resetForm();
    setTransactionData();
  };

  return (
    <div style={{ display: true ? "block" : "none" }}>
      {transactionData && (
        <SuccessfullTransaction
          message={`Your reference number is ${transactionData.transactionId}`}
          onClose={handleSuccessfullTransactionClose}
        />
      )}
      {visualMessage && (
        <VisualMessage
          title={visualMessage.title}
          message={visualMessage.message}
          severity={visualMessage.severity}
          onClose={visualMessage.onClose}
        />
      )}
      <MainBackground
        component="form"
        noValidate
        autoComplete="off"
        isMobile={isMobile}
      >
          <Grid container style={{width:isMobile?"100%":"65%", margin:!isMobile?"3rem auto":0}}  >
          <Grid item xs={12} p={1}>
            <div style={{ display: "flex", justifyContent: "center", alignItems:"center" }}>
              <SvgIcon
                fontSize="medium"
                component={sendMoneyIcon}
                inheritViewBox
              />
              <Typography variant="h6" sx={{ fontWeight: "bold" }} pl={1}>
                {getLabel("sendMoney.title", "Send Money")}
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            pt={4}
            alignContent={"center"}
            textAlign={"center"}
          >
            <Autocomplete
              variant="outlined"
              disablePortal
              id="recipient"
              options={recipientService?.recipients?.beneficiaries?.map((item) => {
                return {
                  id: item.idMoneyMovementItem,
                  typeMoneyMovementItem: item.typeMoneyMovementItem,
                  idCommunication: item.idCommunication,
                  typeCommunication: item.typeCommunication,
                  firstName: item.firstName,
                  lastName: item.lastName,
                  label:
                    item.firstName + " " +
                    item.lastName +
                    " (" +
                    item.idCommunication +
                    ")",
                };
              })}
              value={formikSendMoney.values.recipient}
              onChange={(event, newValue) => {
                formikSendMoney.setFieldValue("recipient", newValue);
              }}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
              }}
              sx={{ maxWidth: "80rem", margin: "auto"}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="recipient"
                  error={
                    formikSendMoney.touched.recipient &&
                    Boolean(formikSendMoney.errors.recipient)
                  }
                  helperText={
                    formikSendMoney.touched.recipient &&
                    formikSendMoney.errors.recipient
                  }
                  label="Recipient"
                  onBlur={formikSendMoney.handleBlur}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      backgroundColor: 'white', // Add this line
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} pt={0}>
            <Grid
              container
              sx={{ maxWidth: "80rem", margin: "auto" }}
              justifyContent={"flex-end"}
            >
              <Grid item>
                <LoadingButton
                  sx={{ fontSize: "0.8rem",marginTop:"0.5rem", marginBottom:"1rem", marginRight:"0.5rem" }}
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    navigate("/transfers/recipients/manage/nextDay");;

                  }}
                >
                  Manage Recipients
                </LoadingButton>
              </Grid>
              <Grid item>
                <LoadingButton
                  sx={{ fontSize: "0.8rem",marginTop:"0.5rem", marginBottom:"1rem" }}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setVisibleAddRecipient(true);

                  }}
                >
                  Add Recipient
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            pt={1}
            alignContent={"center"}
            textAlign={"center"}
          >
            <FormControl
              fullWidth
              variant="outlined"
              sx={{ maxWidth: "80rem", margin: "auto" }}
            >
              <OutlinedInput
                id="amount"
                fullWidth
                sx={{
                  bgcolor: "white",
                  maxWidth: "80rem",
                  margin: "auto",
                }}
                type={"text"}
                placeholder={getLabel("sendMoney.amount", "Amount")}
                value={formikSendMoney.values.amount}
                onBlur={formikSendMoney.handleBlur}
                onChange={formikSendMoney.handleChange}
                error={
                  formikSendMoney.touched.amount &&
                  Boolean(formikSendMoney.errors.amount)
                }
              />
              <FormHelperText sx={{ color: "red" }}>
                {formikSendMoney.errors.amount}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} pt={0}>
            <Typography variant="body2" sx={{ textAlign: "center" }}>
              {getLabel(
                "sendMoney.available",
                "Available Balance: " +
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(cardService.cardDetail.availableBalance)
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} pt={5}>
              <Grid item sx={{ maxWidth: "80rem", margin: "auto" }}>
                <TextField  
                  fullWidth
                  label="Security Question"
                  id="securityQuestion"
                  type={"text"}
                  value={formikSendMoney.values.securityQuestion}
                  onChange={formikSendMoney.handleChange}
                  onBlur={formikSendMoney.handleBlur}
                  error={
                    formikSendMoney.touched.securityQuestion &&
                    Boolean(formikSendMoney.errors.securityQuestion)
                  }
                  helperText={
                    formikSendMoney.touched.securityQuestion &&
                    formikSendMoney.errors.securityQuestion
                  }
                  InputProps={{
                    style: {
                      backgroundColor: 'white',
                    },
                  }}
                />
              </Grid>
          </Grid>
          <Grid item xs={12} pt={1}>
              <Grid item sx={{ maxWidth: "80rem", margin: "auto" }}>
                <TextField
                  fullWidth
                  label="Security Answer"
                  id="securityAnswer"
                  type={"text"}
                  value={formikSendMoney.values.securityAnswer}
                  onChange={formikSendMoney.handleChange}
                  onBlur={formikSendMoney.handleBlur}
                  error={
                    formikSendMoney.touched.securityAnswer &&
                    Boolean(formikSendMoney.errors.securityAnswer)
                  }
                  helperText={
                    formikSendMoney.touched.securityAnswer &&
                    formikSendMoney.errors.securityAnswer
                  }
                  InputProps={{
                    style: {
                      backgroundColor: 'white',
                    },
                  }}
                />
              </Grid>
          </Grid>

          <Grid item xs={12} pt={3}>
            <Grid
              container
              textAlign={"center"}
              sx={{ width: isMobile?"100%":"90%", margin: "auto" }}
            >
              <Grid item xs={3}>
                <LoadingButton
                  sx={{
                    fontSize: isMobile?"0.7rem":"0.8rem",
                  }}
                  variant="contained"
                  color={
                    formikSendMoney.values.dateType === "Today"
                      ? "secondary"
                      : "primary"
                  }
                  onClick={() => {
                    formikSendMoney.setFieldValue("when", new Date());
                    formikSendMoney.setFieldValue("dateType", "Today");
                  }}
                >
                  Today
                </LoadingButton>
              </Grid>
              <Grid item xs={3}>
                <LoadingButton
                  sx={{
                    fontSize: isMobile?"0.7rem":"0.8rem",
                  }}
                  variant="contained"
                  color={
                    formikSendMoney.values.dateType === "FutureDate"
                      ? "secondary"
                      : "primary"
                  }
                  onClick={() => {
                    formikSendMoney.setFieldValue("dateType", "FutureDate");
                    setOpenDP(true);
                  }}
                >
                  Future
                </LoadingButton>
              </Grid>
              <Grid item xs={3}>
                <LoadingButton
                  sx={{
                    fontSize: isMobile?"0.7rem":"0.8rem",
                  }}
                  variant="contained"
                  color={
                    formikSendMoney.values.dateType === "Monthly"
                      ? "secondary"
                      : "primary"
                  }
                  onClick={() => {
                    formikSendMoney.setFieldValue("dateType", "Monthly");
                    setOpenDP(true);
                  }}
                >
                  Monthly
                </LoadingButton>
              </Grid>
              <Grid item xs={3}>
                <LoadingButton
                  sx={{
                    fontSize: isMobile?"0.7rem":"0.8rem",
                  }}
                  variant="contained"
                  color={
                    formikSendMoney.values.dateType === "Weekly"
                      ? "secondary"
                      : "primary"
                  }
                  onClick={() => {
                    formikSendMoney.setFieldValue("dateType", "Weekly");
                    setOpenDP(true);
                  }}
                >
                  Weekly
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} pt={3}>
            <Grid container sx={{ maxWidth: "80rem", margin: "auto" }}>
              <Box>
                {formikSendMoney.values.when || openDP ? (
                  <DatePicker
                    open={openDP}
                    id="when"
                    value={formikSendMoney.values.when}
                    onAccept={(date) => {
                      formikSendMoney.setFieldValue("when", date);
                      setOpenDP(false);
                    }}
                    minDate={new Date()}
                    maxDate={
                      formikSendMoney.values.dateType === "Weekly"
                        ? addDays(new Date(), 7)
                        : addMonths(new Date(), 1)
                    }
                    slots={{ openPickerButton: () => "" }}
                    slotProps={{ textField: { InputProps: { style: {
                      backgroundColor: 'white',
                    } } } }}

                  />
                ) : (
                  ""
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} pt={3}>
            <LoadingButton
              sx={{
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
              variant="contained"
              color="primary"
              fullWidth
              loading={nextDayMutation.isLoading}
              onClick={() => {
                formikSendMoney.handleSubmit();
              }}
            >
              Next-Day Send
            </LoadingButton>
          </Grid>
        </Grid>
      </MainBackground>
      {visibleAddRecipient && FormAddRecipient()}
      <Menu isMobile={isMobile} />
    </div>
  );
}
