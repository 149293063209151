import {
  Box,
  SvgIcon,
  Typography,
} from "@mui/material";
import * as React from "react";
import {ReactComponent as errorIcon} from "../images/error.svg";
import { useTheme } from "@mui/material";


export default function ErrorPage(props) {
  const theme = useTheme();
  //const isMobile = useMediaQuery("(max-width:600px)");

  
  return (
    <Box display="flex" 
    flexDirection="column" 
    alignItems="center" 
    justifyContent="center" 
    style={{ 
        height: '25vh', 
        width: '25vh', // Make the width the same as the height
        borderRadius: '50%', // Make the border radius 50% to create a circle
        border: '2px solid theme.palette.secondary.main', // Add a border to make the circle visible
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)', // Add a shadow
        backgroundColor: theme.palette.secondary.main, // Set the background color to gray

        padding: '1rem' // Add some padding
      }} >                         
        <SvgIcon  component={errorIcon} inheritViewBox style={{ fontSize: 40 }} />
        <Typography variant={props.message.length>40? "h7" : "h6"} color="white">
          {props.message}
        </Typography>
      </Box>
  );
}
