import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import * as React from "react";
import { Box } from "@mui/system";
import CardTransactions from "../components/ui/CardTransactions";
import CardActivation from "../components/ui/CardActivation";
import Menu from "../components/ui/Menu";
import useLabels from "../labels/useLabels";
import CardService from '../services/CardService';
import PADService from '../services/PADService';
import FeaturesService from "../services/config/FeaturesService";
import CardView from "../components/ui/CardView";
import MainBackground from "../components/ui/MainBackground";
import CardDisclousure from "../components/ui/CardDisclousure";
import CardNotFound from "./CardNotFound";
import AtomicWidget from "./atomic/AtomicWidget";

export default function MainSite() {
  const {getLabel} = useLabels();
  
  const getGreeting = () => {
    const now = new Date();
    const hour = now.getHours();
    var greeting = "";
  
    if (hour >= 0 && hour < 12) {
      greeting = getLabel("main.greeting.day","Good Day");
    } else if (hour >= 12 && hour < 18) {
      greeting = getLabel("main.greeting.afternoon","Good Afternoon");
    } else {
      greeting = getLabel("main.greeting.evening","Good Evening");
    }
    return greeting;
  };

  const cardService = CardService();
  const { isLoading: featuresServiceIsLoading, isError: featuresServiceIsError, features, error: featuresServiceError } = FeaturesService();
  const { isLoading: padServiceIsLoading, isError: padServiceIsError, padInfo, error: padServiceError } = PADService(features?.PAD);

  const [loaded, setLoaded] = React.useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const cardDisclousure = CardDisclousure();

  
  const { isLoading, isError, cardDetail, error } = cardService;

  if (isLoading || padServiceIsLoading || featuresServiceIsLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (error?.response?.status===404) {
    return <CardNotFound/>;
  }

  return (
    <div style={{ display: loaded  ? "block" : "none"  }} >
      <MainBackground isMobile={isMobile}>
        <Grid container>
          <Grid item xs={12}>
            <Card elevation={0} sx={{backgroundColor:"rgba(255, 255, 255, 0)",}}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }} >
              {getGreeting()}, {cardDetail.completeName}
            </Typography>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <CardView onLoad={()=>setLoaded(true)}/>
          </Grid>
          <AtomicWidget />
        </Grid>
        {cardDetail.statusDisplay.toUpperCase() === "ACTIVE" || cardDetail.statusDisplay.toUpperCase() === "SUSPENDED"  
        || cardDetail.statusDisplay.toUpperCase() === "LOST"  || cardDetail.statusDisplay.toUpperCase() === "STOLEN" ? (
          <Grid
            container
            justifyContent="center"
          >
            <Grid
              item
              xs={12}
              style={{
                maxWidth: "80rem",
                marginBottom: "0.2rem",
              }}
            >
              <Card elevation={2} sx={{ borderRadius: "10px", backgroundColor: "rgba(255, 255, 255, 0.25)", }}>
                <CardContent>
                <Grid container>
                    <Grid item xs={features?.PAD ? 6 : 12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            variant="h5"
                            color="primary"
                            sx={{
                              textAlign: "center",
                              m: 0,
                              p: 0,
                              fontWeight: "bold",
                            }}
                          >
                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cardDetail.availableBalance)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="body1"
                            color="black"
                            sx={{ textAlign: "center", fontWeight: "lighter" }}
                          >
                            {getLabel("main.availableBalance","Available Balance")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {features?.PAD ? (
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            variant="h5"
                            color="secondary"
                            sx={{
                              textAlign: "center",
                              m: 0,
                              p: 0,
                              fontWeight: "bold",
                            }}
                          >
                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(padInfo?.available)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="body1"
                            color="black"
                            sx={{ textAlign: "center", fontWeight: "lighter" }}
                          >
                            {getLabel("main.pad.availableBalance","Pay Any-Day Balance")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    ) : null}
                    <Grid item xs={12}>
                      <Typography variant="caption" align="center" color={"#bdbdbd"}>
                        <div>
                        {cardDisclousure}
                        </div>
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item
              xs={12}
              style={{
                maxWidth: "80rem",
                marginBottom: "0.8rem",
              }}>
              <Paper
                xs={12}
                sx={{
                  borderRadius: "10px",
                  maxWidth: "80rem",
                  backgroundColor: "rgba(255, 255, 255, 0.25)",
                }}
                elevation={4}
              >
                <CardTransactions />
              </Paper>
            </Grid>
          </Grid>
        ) : cardDetail.statusDisplay.toUpperCase() === "PENDING" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Paper
              xs={12}
              sx={{
                borderRadius: "10px",
                maxWidth: "80rem",
                backgroundColor: "rgba(255, 255, 255, 0.25)",
              }}
              elevation={4}
            >
              <CardActivation />
            </Paper>
          </div>
        ) : null}
      </MainBackground>
      <Menu isMobile={isMobile} />
    </div>
  );
}
