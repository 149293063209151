import * as React from "react";
import { Grid, Typography, useMediaQuery, SvgIcon, Paper } from "@mui/material";
import { Box } from "@mui/system";
import Menu from "../../components/ui/Menu";
import useLabels from "../../labels/useLabels";
import { useNavigate } from "react-router-dom";
import SpeedIcon from "@mui/icons-material/Speed";
import TodayIcon from "@mui/icons-material/Today";
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import { ReactComponent as sendMoneyIcon } from "../../images/sendmoney.svg";
import PageMenuOption from "../../components/PageMenuOption";
import MainBackground from "../../components/ui/MainBackground";


export default function MainSite() {
  const {getLabel} = useLabels();
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();

  return (
    <div style={{ display: true ? "block" : "none" }}>
      <MainBackground 
        component="form"
        noValidate
        autoComplete="off"
        isMobile={isMobile}
       
        >
        <Grid container style={{width:isMobile?"100%":"70%", margin:!isMobile?"3rem auto":0}}  >
          <Grid item xs={12} p={1} >

            <div style={{ display: "flex", justifyContent: "center" }}>
              <SvgIcon
                fontSize="large"
                component={sendMoneyIcon}
                inheritViewBox
                sx={{ paddingRight: "1rem" }}
              />
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {getLabel("sendMoney.title", "Send Money")}
              </Typography>
            </div>
          </Grid>


          <PageMenuOption 
            isMobile={isMobile} 
            navigateTo="/transfers" 
            label="sendmoney.myTransfers" 
            defaultLabel="View My Transfers"
            icon={<HistoryToggleOffIcon sx={{ fontSize:"1.2rem", paddingLeft:"1rem" }} />}
          />
          <Grid container pt={2} spacing={2} justifyContent="center" marginTop="1rem">

            <Grid item xs={12} p={1}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="body2">
                  {getLabel(
                    "sendMoney.line1",
                    "Select your desired send money type"
                  )}
                </Typography>
              </div>
            </Grid>

            <Grid container pt={2} spacing={10} justifyContent="center">
              <Grid item xs={6}>
                <Box display="flex" justifyContent="right">
                  <Paper
                    elevation={2}
                    sx={{
                      width: "90px",
                      height: "90px",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/sendMoney/instant");
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        justifyContent={"center"}
                        display={"flex"}
                      >
                        <SpeedIcon />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        justifyContent={"center"}
                        display={"flex"}
                      >
                        <Typography variant="caption">
                          {getLabel("sendMoney.Instant", "Instant")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" justifyContent="left">
                  <Paper
                    elevation={2}
                    sx={{
                      width: "90px",
                      height: "90px",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/sendMoney/nextDay");
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        justifyContent={"center"}
                        display={"flex"}
                      >
                        <TodayIcon />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        justifyContent={"center"}
                        display={"flex"}
                      >
                        <Typography variant="caption">
                          {getLabel("sendMoney.NextDay", "Next-Day")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </MainBackground>
      <Menu isMobile={isMobile} />
    </div>
  );
}
