import { useEffect, useState } from "react";
import store from "../store/store";
import rv from "./rv";

const useLabels = () => {
  const [module, setModule] = useState();
  const [isLabelLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function loadModule() {
      try {
        const mod = await import(`./${window.subdomain}`);
        setModule(mod.default);
      } catch (error) {
        setModule(rv);
      }
    }
    setIsLoading(true);
    loadModule();
    setIsLoading(false);
  }, []);

  const getLabel = (key, defaultValue = "", forceLanguage) => {
    if (module===undefined) return "";
    const language = forceLanguage || store.getState().language.profile;
    
    let returnValue;
    const value = module[language ? language : "en"][key];
    returnValue =
      value !== undefined
        ? value
        : language !== "en"
        ? getLabel(key, defaultValue, "en")
        : defaultValue;
    return returnValue !== "" ? returnValue : key;
  };

  return { getLabel, isLabelLoading};
};

export default useLabels;