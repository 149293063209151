import * as React from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Dialog,
  FormHelperText,
} from "@mui/material";
import { Box } from "@mui/system";
import Menu from "../../components/ui/Menu";
import useLabels from "../../labels/useLabels";
import { useNavigate } from "react-router-dom";
import PasswordIcon from "@mui/icons-material/Password";
import LoadingButton from "../../components/ui/LoadingButton";
import { useMutation } from "@tanstack/react-query";
import HttpService from "../../services/HttpService";
import VisualMessage from "../../components/ui/VisualMessage";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import UserService from "../../services/UserService";
import { useFormik } from "formik";
import * as validatorYup from "yup";
import OTPInput from "../../components/ui/OTPInput";
import MainBackground from "../../components/ui/MainBackground";

export default function ChangePassword() {
  const {getLabel} = useLabels();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [showPassword, setShowPassword] = React.useState(false);
  const [visualMessage, setVisualMessage] = React.useState();
  const navigate = useNavigate();
  const [askOTP, setAskOTP] = React.useState(false);
  const [combinationCode, setCombinationCode] = React.useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const logoutFunc = () => {
    UserService.doLogout({ redirectUri: window.location.origin });
  };

  const changePasswordMutation = useMutation(
    (changePasswordData) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/login/changePassword`,
        changePasswordData,
      );
    },
    {
      onSuccess: (value) => {
        setVisualMessage({
          title: getLabel(
            "login.changePassword.ok.title",
            "Password successfully updated"
          ),
          message: getLabel(
            "login.changePassword.ok.message",
            "Your password has been successfully updated. Please log in using your new password."
          ),
          severity: "success",
          onClose: () => logoutFunc(),
        });
      },
      onError: (values) => {
        setVisualMessage({
          title: getLabel(
            "login.changePassword.error.title",
            "Password update failed"
          ),
          message:
            values.response.status === 401
              ? "The old password is incorrect"
              : values.response?.statusText + " " + values.response?.data,
          severity: "error",
          onClose: () => setVisualMessage(),
        });
      },
    }
  );

  const vsChangePassword = validatorYup.object({
    oldPassword: validatorYup.string().required(getLabel("login.changePassword.oldPassword.required", "Old password is required")),
    newPassword1: validatorYup.string().min(6, "At least 6 characters")
    .max(20, "At most 20 characters")
    .matches(/\d/, "At least one number")
    .matches(/[ _!@#$%&*]/, "At least one special character (_!@#$%&*)")
    .required(getLabel("login.changePassword.newPassword1.required", "New password is required")),
    newPassword2: validatorYup
      .string()
      .oneOf([validatorYup.ref("newPassword1")], getLabel("login.changePassword.newPassword2.match", "Passwords must match"))
      .required(getLabel("login.changePassword.newPassword2.required", "Repeat the new password")),
  });

  const formikChangePassword = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword1: "",
      newPassword2: "",
    },
    validationSchema: vsChangePassword,
    onSubmit: (values) => {
      values.username = UserService.getUsername();
      values.combinationCode = combinationCode;
      changePasswordMutation.mutate({ ...values });
    },
  });

  const handleOnSuccessOTP = (code) => {
    setCombinationCode(code);
    setAskOTP(false);
  };

  React.useEffect(() => {
    if (
      combinationCode !== undefined &&
      combinationCode !== null &&
      combinationCode !== ""
    ) {
      formikChangePassword.handleSubmit();
    }
  }, [combinationCode]);

  const FormOTP = () => (
    <Dialog
      open={askOTP}
      fullScreen
      onClose={(event, reason) => {
        reason !== "backdropClick" && setAskOTP(false);
      }}
      disableEscapeKeyDown={true}
    >
      <OTPInput
        recipient={UserService.getUsername()}
        from={"change-password"}
        onClose={() => setAskOTP(false)}
        onSuccess={(code) => handleOnSuccessOTP(code)}
      />
    </Dialog>
  );

  return (
    <div>
      <FormOTP />
      {visualMessage && (
        <VisualMessage
          title={visualMessage.title}
          message={visualMessage.message}
          severity={visualMessage.severity}
          onClose={visualMessage.onClose}
        />
      )}
      <MainBackground 
        component="form"
        noValidate
        autoComplete="off"
        isMobile={isMobile}
      >
        <Grid container style={{width:isMobile?"100%":"65%", margin:!isMobile?"3rem auto":0}}  >
          <Grid container>
            <Grid container justifyContent="center">
              <Grid container >
                <Grid item xs={12} p={1}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <PasswordIcon
                      sx={(theme) => ({
                        color: theme.palette.primary.main,
                        paddingRight: "1rem",
                      })}
                    />
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {getLabel(
                        "changePassword.title",
                        "Change Password"
                      )}
                    </Typography>
                  </div>
                </Grid>

                <Grid
                  container
                  justifyContent="center"
                  sx={{ width: "100%", height: "100%" }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Open Sans",
                    }}
                  >
                    {getLabel(
                      "login.changePassword.title",
                      "Please change your password"
                    )}
                  </Typography>
                  <Grid item sx={{ width: "100%" }}>
                    <InputLabel
                      htmlFor="oldPassword"
                      sx={{
                        fontFamily: "Open Sans",
                      }}
                    >
                      {getLabel(
                        "login.changePassword.oldPassword.label",
                        "Old password:"
                      )}
                    </InputLabel>
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <FormControl fullWidth variant="outlined">
                      <OutlinedInput
                        autoFocus
                        id="oldPassword"
                        fullWidth
                        autoComplete="password"
                        sx={{
                          bgcolor: "white",
                        }}
                        type={showPassword ? "text" : "password"}
                        value={formikChangePassword.values.oldPassword}
                        onChange={formikChangePassword.handleChange}
                        onBlur={formikChangePassword.handleBlur}
                        error={
                          formikChangePassword.touched.oldPassword &&
                          Boolean(formikChangePassword.errors.oldPassword)
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                                            {!!formikChangePassword.errors.oldPassword && (
                        <FormHelperText error id="oldPassword-error">
                          {formikChangePassword.errors.oldPassword}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <InputLabel
                      htmlFor="newPassword1"
                      sx={{
                        fontFamily: "Open Sans",
                        color: "loginText.main",
                      }}
                    >
                      {getLabel(
                        "login.changePassword.newPassword1.label",
                        "New password:"
                      )}
                    </InputLabel>
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <FormControl fullWidth variant="outlined">
                      <OutlinedInput
                        id="newPassword1"
                        fullWidth
                        autoComplete="password"
                        sx={{
                          bgcolor: "white",
                        }}
                        type={showPassword ? "text" : "password"}
                        value={formikChangePassword.values.newPassword1}
                        onChange={formikChangePassword.handleChange}
                        onBlur={formikChangePassword.handleBlur}
                        error={
                          formikChangePassword.touched.newPassword1 &&
                          Boolean(formikChangePassword.errors.newPassword1)
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {!!formikChangePassword.errors.newPassword1 && (
                        <FormHelperText error id="newPassword1-error">
                          {formikChangePassword.errors.newPassword1}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <InputLabel
                      htmlFor="newPassword2"
                      sx={{
                        fontFamily: "Open Sans",
                        color: "loginText.main",
                      }}
                    >
                      {getLabel(
                        "login.changePassword.newPassword2.label",
                        "Repeat the new password:"
                      )}
                    </InputLabel>
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <FormControl fullWidth variant="outlined">
                      <OutlinedInput
                        id="newPassword2"
                        fullWidth
                        autoComplete="password"
                        sx={{
                          bgcolor: "white",
                        }}
                        type={showPassword ? "text" : "password"}
                        value={formikChangePassword.values.newPassword2}
                        onChange={formikChangePassword.handleChange}
                        onBlur={formikChangePassword.handleBlur}
                        error={
                          formikChangePassword.touched.newPassword2 &&
                          Boolean(formikChangePassword.errors.newPassword2)
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {!!formikChangePassword.errors.newPassword2 && (
                        <FormHelperText error id="newPassword2-error">
                          {formikChangePassword.errors.newPassword2}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item sx={{ width: "100%" }}>
                    <LoadingButton
                      variant="contained"
                      color="secondary"
                      loading={changePasswordMutation.isLoading}
                      sx={{ width: "100%"}}
                      onClick={() => {
                        if (
                          Object.keys(formikChangePassword.errors).length ===
                            0 &&
                          formikChangePassword.values.oldPassword.length > 0 &&
                          formikChangePassword.values.newPassword2.length > 0 &&
                          formikChangePassword.values.newPassword1.length > 0
                        )
                        {
                          setCombinationCode("");
                          setAskOTP(true);
                        }
                      }}
                    >
                      {getLabel(
                        "changePassword.btnLabel",
                        "Change Password"
                      )}
                    </LoadingButton>
                  </Grid>
                </Grid>

                <Grid item xs={12} pt={0}>
                  <Grid alignContent="center" item>
                    <LoadingButton
                      fullWidth
                      sx={{
                        marginTop: "0.5rem",
                        paddingLeft: "1rem",
                        paddingRight: "1rem",
                      }}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      {getLabel("general.back", "Go Back")}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainBackground>
      <Menu isMobile={isMobile} />
    </div>
  );
}
