const sessionStorage = window.sessionStorage;

function set(key, data) {
    sessionStorage.setItem(key, JSON.stringify(data));
  }
  
  function get(key) {
    const data = sessionStorage.getItem(key);
    if (data === "undefined") return undefined;
    return data ? JSON.parse(data) : null;
  }

  function remove(key) {
    sessionStorage.removeItem(key);
  }

  const StorageService = {
    set,
    get,
    remove
  };
  
  export default StorageService;