import { useQuery } from "@tanstack/react-query";
import HttpService from "./HttpService";

async function fetchRecipientLandingInfo (params)  {

  const token = params.queryKey[1];
  try
  {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/payment/receive/start?token=${token}`
    );
    return response;
  }  catch (error) {
    if (error.response && error.response.status === 400) {
      return { data: { errorMessage: "The token is invalid" }}; // or any other default value
    } else {
      throw error;
    }
  }
}

const RecipientLandingService = (token) => {
  const { isLoading, isError, data, error } = useQuery(
    ["RecipientLandingInfo", token],
    fetchRecipientLandingInfo,
    {
      refetchOnWindowFocus: false,
      staleTime: 10000,
      retry: (failureCount, error) => !(error.response && error.response.status === 400),
    }
  );

  const recipientLandingInfo = data?.data || null;
 
  return {
    recipientLandingInfo,
    isLoading,
    isError,
    error
  };
};

export default RecipientLandingService;
