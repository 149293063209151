import {
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import * as React from "react";
import { Box } from "@mui/system";
import CardActivation from "../components/ui/CardActivation";
import Menu from "../components/ui/Menu";
import useLabels from "../labels/useLabels";
import CardService from '../services/CardService';
import PADService from '../services/PADService';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import LockIcon from '@mui/icons-material/Lock';
import PinIcon from '@mui/icons-material/Pin';
import { useNavigate } from "react-router-dom";
import FeaturesService from "../services/config/FeaturesService";
import MainBackground from "../components/ui/MainBackground";
import CardView from "../components/ui/CardView";
import CardDisclousure from "../components/ui/CardDisclousure";





export default function CardAdmin() {
  const cardDisclousure = CardDisclousure();
  const {getLabel} = useLabels();

  const cardService = CardService();
  const { isLoading: featuresServiceIsLoading, isError: featuresServiceIsError, features, error: featuresServiceError } = FeaturesService();
  const { isLoading: padServiceIsLoading, isError: padServiceIsError, padInfo, error: padServiceError } = PADService(features?.PAD);


  const [loaded, setLoaded] = React.useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  const navigate = useNavigate();


  const { isLoading, isError, cardDetail, error } = cardService;  

  if (isLoading || padServiceIsLoading|| featuresServiceIsLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (isError) {
    return <span>Error: {error.message} </span>;
  }

  return (
    <div style={{ display: loaded ? "block" : "none" }}>
      <MainBackground
        isMobile={isMobile}
      >
        <Grid container style={{width:isMobile?"100%":"60%", margin:!isMobile?"3rem auto":0}}  >
          <Grid item xs={12} p={1}>
            <div style={{ display: "flex", justifyContent: "center" }}>
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems:"center" }}>
              <CreditCardIcon sx={(theme) => ({ color: theme.palette.primary.main , paddingRight: "1rem" })}/>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {getLabel("cardAdmin.title", "Card Admin")}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <CardView onLoad={()=>setLoaded(true)}/>
          </Grid>
        </Grid>
        {cardDetail.statusDisplay.toUpperCase() === "ACTIVE" || cardDetail.statusDisplay.toUpperCase() === "SUSPENDED"
        || cardDetail.statusDisplay.toUpperCase() === "LOST"  || cardDetail.statusDisplay.toUpperCase() === "STOLEN" ? (
          <Grid container style={{width:isMobile?"100%":"60%", margin:!isMobile?"3rem auto":0, justifyContent:"center"}}  >


            <Grid
              item
              xs={12}
              style={{
                maxWidth: "80rem",
                marginBottom: "0.8rem",
              }}
            >
                <CardContent>
                <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="caption" align="center" color={"#bdbdbd"}>
                        <div>
                        {cardDisclousure}
                        </div>
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
            </Grid>

            
            <Grid item
              xs={12}
              style={{
                maxWidth: "80rem",
                marginBottom: "0.8rem",
                display: cardDetail.statusDisplay.toUpperCase() === "ACTIVE" ? "block" : "none",

              }}>
              <Button
                variant="contained"
                fullWidth
                xs={12}
                sx={{
                  maxWidth: "80rem",
                  marginTop: "0.8rem",
                  height: "2.5rem",
                  display: cardDetail.statusDisplay.toUpperCase() === "ACTIVE" ? "flex" : "none" ,
                  flexDirection:"row",
                  paddingTop:"0.5rem",
                  paddingBottom:"0.5rem",
                  paddingLeft:"1rem",
                  alignContent:"center",
                  alignItems:"center"

                }}
                elevation={4}
                onClick={() => {
                  navigate("/lock");;
                }}
              >
                <LockIcon sx={{paddingRight:"1rem"}} />
                <Typography >
                  {getLabel("cardAdmin.lockCard", "Lock Card")}
                </Typography>
              </Button>
            </Grid>


            <Grid item
              xs={12}
              style={{
                maxWidth: "80rem",
                marginBottom: "0.8rem",
                display: cardDetail.statusDisplay.toUpperCase() === "SUSPENDED" ? "block" : "none",
              }}>
              <Button
                variant="contained"
                fullWidth              
                xs={12}
                sx={{
                  maxWidth: "80rem",
                  marginTop: "0.8rem",
                  height: "2.5rem",
                  display: cardDetail.statusDisplay.toUpperCase() === "SUSPENDED"  ? "flex" : "none" ,
                  flexDirection:"row",
                  paddingTop:"0.5rem",
                  paddingBottom:"0.5rem",
                  paddingLeft:"1rem",
                  alignContent:"center",
                  alignItems:"center"

                }}
                elevation={4}
                onClick={() => {
                  navigate("/unlock");;
                }}
              >
                <LockIcon sx={{paddingRight:"1rem"}} />
                <Typography >
                  {getLabel("cardAdmin.unlockCard", "Unlock Card")}
                </Typography>
              </Button>
            </Grid>

            <Grid item
              xs={12}
              style={{
                maxWidth: "80rem",
                marginBottom: "0.8rem",
                display: cardDetail.statusDisplay.toUpperCase() === "ACTIVE" && features?.CHANGE_PIN ? "block" : "none",
              }}>
              <Button
                variant="contained"
                fullWidth              
                xs={12}
                sx={{
                  maxWidth: "80rem",
                  marginTop: "0.8rem",
                  height: "2.5rem",
                  display: cardDetail.statusDisplay.toUpperCase() === "ACTIVE" && features?.CHANGE_PIN ? "flex" : "none",
                  flexDirection:"row",
                  paddingTop:"0.5rem",
                  paddingBottom:"0.5rem",
                  paddingLeft:"1rem",
                  alignContent:"center",
                  alignItems:"center"

                }}
                elevation={4}
                onClick={() => {
                  navigate("/changePin");;
                }}
              >
                <PinIcon sx={{paddingRight:"1rem"}} />
                <Typography >
                  {getLabel("cardAdmin.changePin", "Change Pin")}
                </Typography>
              </Button>
            </Grid>


            <Grid item
              xs={12}
              style={{
                maxWidth: "80rem",
                marginBottom: "0.8rem",
                display: features?.REPLACE_CARD ? "block" : "none",

              }}>
              <Button
                variant="contained"
                fullWidth              
                xs={12}
                sx={{
                  maxWidth: "80rem",
                  marginTop: "0.8rem",
                  height: "2.5rem",
                  display: features?.REPLACE_CARD ? "flex" : "none",
                  flexDirection:"row",
                  paddingTop:"0.5rem",
                  paddingBottom:"0.5rem",
                  paddingLeft:"1rem",
                  alignContent:"center",
                  alignItems:"center"

                }}
                elevation={4}
                onClick={() => {
                  navigate("/replaceCard");;
                }}
              >
                <AutorenewIcon sx={{paddingRight:"1rem"}} />
                <Typography >
                  {getLabel("cardAdmin.replaceCard", "Replace Card")}
                </Typography>
              </Button>
            </Grid>

          </Grid>
        ) : cardDetail.statusDisplay.toUpperCase() === "PENDING" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Paper
              xs={12}
              sx={{
                borderRadius: "10px",
                maxWidth: "80rem",
                backgroundColor: "rgba(255, 255, 255, 0.25)",
              }}
              elevation={4}
            >
              <CardActivation />
            </Paper>
          </div>
        ) : null}
      </MainBackground>
      <Menu isMobile={isMobile} />
    </div>
  );
}
