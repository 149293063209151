import React from "react";
import useLabels from "../../labels/useLabels";
import CardService from "../../services/CardService";

function CardDisclousure() {
  const { getLabel } = useLabels();
  const notFound = "NOT FOUND";
  const cardService = CardService();
  if (cardService.cardDetail === null) return "";
  var value;
  value = getLabel(
    `main.card.disclousure.${cardService.cardDetail.brand}.${cardService.cardDetail.shortLabel}`,
    notFound
  );
  if (value !== notFound) return value;
  value = getLabel(
    `main.card.disclousure.${cardService.cardDetail.brand}`,
    notFound
  );
  if (value !== notFound) return value;
  value = getLabel(`main.card.disclousure`, "");
  return value;
}

export default CardDisclousure;
