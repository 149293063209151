import { useQuery } from "@tanstack/react-query";
import HttpService from "../HttpService";

async function fetchPayees() {
  try
  {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/payment/payee`
    );
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return { data: { beneficiaries: [] }}; // or any other default value
    } else {
      throw error;
    }
  }

}

const PayeeService = () => {
  const { isLoading, isError, data, error } = useQuery(
    ["payees"],
    fetchPayees,
    {
      refetchOnWindowFocus: false,
      staleTime: 10000,
      retry: (failureCount, error) => !(error.response && error.response.status === 404),
    }
  );

  const payees = data?.data || null;

  return {
    payees,
    isLoading,
    isError,
    error
  };
};

export default PayeeService;
