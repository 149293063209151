import { useMutation } from '@tanstack/react-query';
import { useLocation, useParams } from 'react-router-dom';
import HttpService from '../../../services/HttpService';
import React from 'react';
import MainOnboarding from '../MainOnboarding';
import { Box, CircularProgress } from '@mui/material';

export function CompanyOnboarding() {
  const [showMainOnboarding , setShowMainOnboarding] = React.useState(false);
  const { companyId: employerId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const firstName = queryParams.get('firstName');
  const middleName = queryParams.get('middleName');
  const lastName = queryParams.get('lastName');
  const email = queryParams.get('email');
  const employeeId = queryParams.get('employeeId');
  const mobilePhone = queryParams.get('primaryNumber');
  const isMobileApp = queryParams.has('mobileApp') ? queryParams.get('mobileApp')==="true" : false;

  

  const process = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/onboarding/preOnboarding`,
        data,
        { avoidBearer: true }
      );
    },
    {
      onSuccess: (values) => {
        setShowMainOnboarding(true);
      },
      onError: (values) => {
        console.error(values);
      },
    }
  );

  React.useEffect(() => {
    if (email === null || email === undefined) {
      setShowMainOnboarding(true);
      return;
    }
    const bagValues = {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      email: email,
      employerId: employerId,
      employerIdDisabled: (employerId) ? true : false,
      employeeId: employeeId,
      employeeIdDisabled: (employeeId) ? true : false,
      mobilePhone: mobilePhone
    };

    const transformedValues = {
      email: email,
      step: 2, 
      bagContent: { ...bagValues },
    };
    process.mutate({ ...transformedValues });
  } , []);


  if (!!!showMainOnboarding) {
    <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
  }

  return (
    showMainOnboarding && <MainOnboarding  directEmail={email} directEmployerId={employerId} isMobileAppProp={isMobileApp} />
  );
}