/* Password Creation */

import * as React from "react";
import { Grid, TextField, MenuItem } from "@mui/material";
import LoadingButton from "../../components/ui/LoadingButton";
import useLabels from "../../labels/useLabels";

export default function StepAmount({ onSave }) {
  const currentStep = "6";
  const { getLabel } = useLabels();
  const [amount, setAmount] = React.useState("");

  return (
    <>
      <Grid container>
        <Grid item xs={12} p={0.5} pt={1.5}>
          <TextField
            fullWidth
            id="amount"
            type="number"
            label="Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            error={amount === ""}
          />
        </Grid>

        <Grid item xs={12} p={0.5}>
          <LoadingButton
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              !isNaN(amount) && amount !== "" && onSave(amount);
            }}
          >
            {getLabel(
              "external-payment." + currentStep + ".btnContinue",
              "Continue"
            )}
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
}
