import * as React from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  SvgIcon,
  Paper
} from "@mui/material";
import { Box } from "@mui/system";
import Menu from "../../components/ui/Menu";
import useLabels from "../../labels/useLabels";
import { useNavigate } from "react-router-dom";
import SpeedIcon from "@mui/icons-material/Speed";
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import { ReactComponent as sendMoneyIcon } from "../../images/sendmoney.svg";
import MainBackground from "../../components/ui/MainBackground";

export default function MainSite() {
  const {getLabel} = useLabels();
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();

  return (
    <div style={{ display: true ? "block" : "none" }}>
      <MainBackground isMobile={isMobile}
        component="form"
        noValidate
        autoComplete="off"
      >
        <Grid container style={{width:isMobile?"100%":"70%", margin:!isMobile?"3rem auto":0}}  >
          <Grid item xs={12} p={1}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {getLabel("sendMoney.title", "Send Money")}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} p={0}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <SvgIcon
                fontSize="large"
                component={sendMoneyIcon}
                inheritViewBox
              />
            </div>
          </Grid>

          <Grid container pt={6} spacing={0} justifyContent="center">
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Paper
                  elevation={0}
                  sx={{
                    width: "90px",
                    height: "90px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: (theme) => theme.palette.primary.main,
                  }}
                >
                  <Grid container color="white">
                    <Grid
                      item
                      xs={12}
                      justifyContent={"center"}
                      display={"flex"}
                    >
                      <SpeedIcon />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      justifyContent={"center"}
                      display={"flex"}
                    >
                      <Typography variant="caption">
                        {getLabel("sendMoney.Instant", "Instant")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={12} justifyContent={"center"} display={"flex"} pt={6}>
              <Typography variant="caption">
                {getLabel("sendMoney.Instant.select", "Select the destination account type")}
              </Typography>
            </Grid>
            <Grid item xs={6} pt={3}>
              <Box display="flex" justifyContent="right"  pr={10}>
                <Paper
                  elevation={2}
                  sx={{
                    width: "90px",
                    height: "90px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/sendMoney/instant/own")}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      justifyContent={"center"}
                      display={"flex"}
                    >
                      <SelfImprovementIcon />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      justifyContent={"center"}
                      display={"flex"}
                    >
                      <Typography variant="caption">
                        {getLabel("sendMoney.Instant.own", "Own Account")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={6}  pt={3}>
              <Box display="flex" justifyContent="left"  pl={10}>
                <Paper
                  elevation={2}
                  sx={{
                    width: "90px",
                    height: "90px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/sendMoney/instant/others")}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      justifyContent={"center"}
                      display={"flex"}
                    >
                      <WorkspacesOutlinedIcon />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      justifyContent={"center"}
                      display={"flex"}
                    >
                      <Typography variant="caption">
                        {getLabel("sendMoney.Instant.others", "Others")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </MainBackground>
      <Menu isMobile={isMobile} />
    </div>
  );
}
