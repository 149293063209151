/* Password Creation */

import * as React from "react";
import { Grid, TextField, MenuItem, Typography } from "@mui/material";
import LoadingButton from "../../components/ui/LoadingButton";
import useLabels from "../../labels/useLabels";
import { useMutation } from "@tanstack/react-query";
import HttpService from "../../services/HttpService";


export default function StepChooseCard({ onSave, recipients, processStepUrl, recipient, token, goToAddDebitCardStep, setStep, paymentToken}) {
  const currentStep = "4";
  const { getLabel } = useLabels();
  const [selectedRecipient, setSelectedRecipient] = React.useState("");

  const processStep = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + processStepUrl,
        {...data, recipient: recipient, token: token},
        { avoidBearer: true }
      );
    },
    {
      onSuccess: (values, data) => {
        onSave({...values, ...data});
      },
      onError: (values, data) => {
        onSave(values, ...data);
      },
    }
  );


  if (recipients === undefined || recipients.length === 0) return <></>;
  if (typeof recipients === 'string') {
    try {
      recipients = JSON.parse(recipients);
    } catch (e) {
      console.error('Error parsing string to JSON', e);
    }
  }
  //const recipientsArray = JSON.parse(recipients); // Convert to array

  return (
    <>
      <Grid container>
        <Grid item xs={12} p={0.5} pt={1.5}>
          {recipients!==null && recipients.length > 0 && (
          <TextField
            fullWidth
            id="select-recipients"
            select
            label="Select your card"
            value={selectedRecipient}
            onChange={(e) => { setSelectedRecipient(e.target.value); setStep(currentStep);  }}
            error={selectedRecipient === ""}
          >
            {recipients.map((option) => (
              <MenuItem key={option.idMoneyMovementItem} value={option.idMoneyMovementItem}>
                <Typography align="left">{option.mask}</Typography>
              </MenuItem>
            ))}
          </TextField>)}
        </Grid>

        <Grid container item xs={12} p={0.5} pt={1.5} alignContent={"space-around"} justifyContent={"center"} >
          <Grid item  p={0.5}>
            <LoadingButton
              size="small"
              variant="contained"
              color="primary"
              loading={processStep.isLoading}
              onClick={() => {

                if (selectedRecipient !== "") {
                  const filteredCard = recipients.filter(recipient => recipient.idMoneyMovementItem === selectedRecipient);
                  
                  processStep.mutate(filteredCard[0]);

                }

                //selectedRecipient !== "" && onSave(selectedRecipient);
              }}
            >
              {getLabel(
                "external-payment." + currentStep + ".btnContinue",
                "Continue"
              )}
            </LoadingButton>
            </Grid>

            <Grid item  p={0.5} sx={{ display : processStep.isLoading ? "none" : "inline-block"  }}>

            <LoadingButton
              
              size="small"
              variant="contained"
              color="secondary"
              onClick={() => {
                if (paymentToken!==null && paymentToken!==undefined && paymentToken!=="") {
                  goToAddDebitCardStep();
                } else {
                  setStep(1);
                }
              }}
            >
              {getLabel(
                "external-payment." + currentStep + ".btnContinue",
                "Add"
              )}
            </LoadingButton>
          </Grid>
        </Grid>      

      </Grid>
    </>
  );
}
