/* Password Creation */

import * as React from "react";
import { Grid, TextField, MenuItem, Typography } from "@mui/material";
import LoadingButton from "../../components/ui/LoadingButton";
import useLabels from "../../labels/useLabels";

export default function StepChooseRecipient({ onSave, recipients }) {
  const currentStep = "6";
  const { getLabel } = useLabels();
  const [selectedRecipient, setSelectedRecipient] = React.useState("");

  if (recipients === undefined || recipients.length === 0) return <></>;

  return (
    <>
      <Grid container>
        <Grid item xs={12} p={0.5} pt={1.5}>
          <TextField
            fullWidth
            id="select-recipients"
            select
            label="Recipient"
            value={selectedRecipient}
            onChange={(e) => setSelectedRecipient(e.target.value)}
            error={selectedRecipient === ""}
          >
            {recipients.map((option) => (
              <MenuItem key={option.idCustomer} value={option.username}>
                <Typography align="left">{option.username}</Typography>
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {console.log("selectedRecipient::::", selectedRecipient)}

        <Grid item xs={12} p={0.5}>
          <LoadingButton
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              selectedRecipient !== "" && onSave(selectedRecipient);
            }}
          >
            {getLabel(
              "external-payment." + currentStep + ".btnContinue",
              "Continue"
            )}
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
}
