/* Password Creation */

import * as React from "react";
import { Grid, CircularProgress, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import HttpService from "../../services/HttpService";
import useLabels from "../../labels/useLabels";
import LoadingButton from "../../components/ui/LoadingButton";

export default function StepConfirmation({
  step,
  recipient,
  token,
  preProcessUrl,
  processStepUrl,
  onSave, 
}) {
  const currentStep = 5;

  const [confirmationInfo, setConfirmationInfo] = React.useState(null);


  const preProcessStep = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + preProcessUrl,
        data,
        { avoidBearer: true }
      );
    },
    {
      onSuccess: (values) => {
        setConfirmationInfo({...values});
      },
      onError: (values) => {
        onSave({...values, redirectTo: 1});
      },
    }
  );

  const { getLabel } = useLabels();


  const processStep = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + processStepUrl,
        data,
        { avoidBearer: true }
      );
    },
    {
      onSuccess: (values) => {
        onSave(values);
      },
      onError: (values) => {

        const errorMessage = values?.response?.data?.message;
        onSave({...values, errorMessage});        

      },
    }
  );


  React.useEffect(() => {
    if (recipient !== "" && token !== "" && step === currentStep) {
      const data = {
        recipient,
        token
      };
      preProcessStep.mutate(data);
    }
  }, [recipient, token, step]);

  if (preProcessStep.isLoading) return <CircularProgress />;
  return (
    <>
      <Grid container padding={0} margin={0}>
        <Grid item xs={12} p={0}>
          <Typography variant="body2" sx={{fontWeight:"bold"}} gutterBottom color={"secondary"}> 
            Sender:
          </Typography>
          <Typography variant="body1" gutterBottom color={"black"}> 
            {confirmationInfo?.data?.data?.fullName}
          </Typography>
          <Typography variant="body2" sx={{fontWeight:"bold"}} gutterBottom color={"secondary"}> 
            Amount:
          </Typography>
          <Typography variant="body1" gutterBottom color={"black"}> 
           {parseFloat(confirmationInfo?.data?.data?.amount).toFixed(2)}  USD
          </Typography>
          <Typography variant="body2" sx={{fontWeight:"bold"}} gutterBottom color={"secondary"}> 
            Destination:
          </Typography>
          <Typography variant="body1" gutterBottom color={"black"}> 
             {confirmationInfo?.data?.data?.destination}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{paddingTop:"1rem"}}>
          <LoadingButton
            size="small"
            variant="contained"
            color="primary"
            onClick={
              () => { 
                processStep.mutate({recipient, token});
              }
            }
            loading={processStep.isLoading}
          >
            {getLabel("onboarding.1.btnContinue", "Receive Funds")}
          </LoadingButton>
        </Grid>
    </>
  );
}
