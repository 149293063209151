/* Password Creation */

import * as React from "react";
import { Checkbox, Grid, TextField, FormControlLabel, MenuItem, Select, FormControl, Input, InputLabel } from "@mui/material";
import LoadingButton from "../../components/ui/LoadingButton";
import useLabels from "../../labels/useLabels";
import { useMutation } from "@tanstack/react-query";
import HttpService from "../../services/HttpService";
import ReactGoogleAutocomplete from "react-google-autocomplete";

export default function StepBillingAddress({ onSave, googleApiKey, recipients, processStepUrl, recipient, token }) {
  const currentStep = "2";
  const { getLabel } = useLabels();
  const [addressLine1, setAddressLine1] = React.useState("");
  const [addressCity, setAddressCity] = React.useState("");
  const [addressZipCode, setAddressZipCode] = React.useState("");
  const [addressState, setAddressState] = React.useState("");
  const [isChecked, setIsChecked] = React.useState(false);


  const processStep = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + processStepUrl,
        data,
        { avoidBearer: true }
      );
    },
    {
      onSuccess: (values) => {
        onSave(values);
      },
      onError: (values) => {
      },
    }
  );


  const onPlaceSelected = (place) => {
    const { address_components: addressComponents } = place;
    let city;
    const streetComponent = addressComponents.filter((item) => item.types.includes("street_number"))[0];
    const routeComponent = addressComponents.filter((item) => item.types.includes("route"))[0];
    const cityComponent = addressComponents.filter((item) => item.types.includes("locality"))[0];
    const neighborhoodComponent = addressComponents.filter((item) => item.types.includes("neighborhood"))[0];
    const subLocalityComponent = addressComponents.filter((item) => item.types.includes("sublocality"))[0];
    const administrativeAreaComponent = addressComponents.filter((item) =>
        item.types.includes("administrative_area_level_3"),
    )[0];
    city = cityComponent && cityComponent !== null ? cityComponent.long_name : null;
    if (city === null) {
        city = subLocalityComponent && subLocalityComponent !== null ? subLocalityComponent.long_name : null;
    }
    if (city === null) {
        city = neighborhoodComponent && neighborhoodComponent !== null ? neighborhoodComponent.long_name : null;
    }
    if (city === null) {
        city =
            administrativeAreaComponent && administrativeAreaComponent !== null
                ? administrativeAreaComponent.long_name
                : null;
    }
    const zipCodeComponent = addressComponents.filter((item) => item.types[0] === "postal_code")[0];
    const zipCode = zipCodeComponent && zipCodeComponent !== null && zipCodeComponent.short_name;
    const state = addressComponents.filter((item) => item.types[0] === "administrative_area_level_1")[0].short_name;
    const address1 = `${!streetComponent ? "" : streetComponent.long_name} ${
        !routeComponent ? "" : routeComponent.long_name
    }`;
    console.log('------------------------------------');
    console.log(address1+" "+city+" "+zipCode+" "+state );
    console.log('------------------------------------');

    setAddressLine1(address1);
    setAddressCity(city);
    setAddressZipCode(zipCode);
    setAddressState(state);

    // this.setState({ address1, city, zipCode, stateSelect: state });
  };

  if (recipients === undefined || recipients.length === 0) return <></>;



  return (
    <>
      <Grid container>
        <Grid item xs={12} p={0.5} pt={1.5} >

          <TextField
            fullWidth
            id="address-autocomplete"
            label="Address"
            sx={{ paddingBottom: 1, display: isChecked ? "none" : "block" }}
            InputProps={{
            inputComponent: (props) => (
              <ReactGoogleAutocomplete 
                apiKey={googleApiKey}
                onPlaceSelected={onPlaceSelected}
                options={{
                  types: ["address"],
                  componentRestrictions: { country: "us" },
                }}
                {...props}
              />
            ),
          }}
          >
          </TextField>

          <Grid 
            sx={{ paddingLeft:2 }}
            item 
            xs={12} 
            alignContent={"space-around"} 
            container 
            alignItems="center"
          >
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={(e, checked) => { setIsChecked(checked); console.log("isChecked"); }}
                id="manual-cbox"
                sx={{ paddingRight: 1 }}
              />
            }
            label="Enter the address manually."
            sx={{  display: 'flex', alignItems: 'center' } }
          />
          </Grid>

          <Grid item xs={12} p={2} pt={2} alignContent={"space-around"}  >

            <TextField
              id="address1"
              type={"text"}
              label="Address"
              value={addressLine1}
              disabled={!isChecked}
              onChange={(e) => setAddressLine1(e.target.value)}
              sx={{ paddingBottom: 1, width: "100%"}}
            >
            </TextField>
            <TextField
              id="city"
              type={"text"}
              label="City"
              value={addressCity}
              disabled={!isChecked}
              onChange={(e) => setAddressCity(e.target.value)}
              sx={{ paddingBottom: 1, width: "100%" }}
            >
            </TextField>
            {/*
            <TextField
              id="state"
              type={"text"}
              label="State"
              value={addressState}
              disabled={!isChecked}
              onChange={(e) => setAddressState(e.target.value)}
              inputProps={{ maxLength: 2, pattern: "\\a*" }}
              sx={{ paddingBottom: 1 , width: "100%"}}
            >
            </TextField>
            */}
            {isChecked ? (
              <FormControl fullWidth>
                <InputLabel fullWidth id="state-label">State</InputLabel>
                <Select
                  //labelId="state-label"
                  fullWidth
                  id="state"
                  label="state"
                  value={addressState|| ""}
                  onChange={(e) => setAddressState(e.target.value)}
                  sx={{ paddingBottom: 1, height: "80%", textAlign: 'left' }}
                >
                  <MenuItem value="">
                    <em>Select the State</em>
                  </MenuItem>
                  <MenuItem value={"AL"}>Alabama</MenuItem>
                  <MenuItem value={"AK"}>Alaska</MenuItem>
                  <MenuItem value={"AZ"}>Arizona</MenuItem>
                  <MenuItem value={"AR"}>Arkansas</MenuItem>
                  <MenuItem value={"CA"}>California</MenuItem>
                  <MenuItem value={"CO"}>Colorado</MenuItem>
                  <MenuItem value={"CT"}>Connecticut</MenuItem>
                  <MenuItem value={"DE"}>Delaware</MenuItem>
                  <MenuItem value={"FL"}>Florida</MenuItem>
                  <MenuItem value={"GA"}>Georgia</MenuItem>
                  <MenuItem value={"HI"}>Hawaii</MenuItem>
                  <MenuItem value={"ID"}>Idaho</MenuItem>
                  <MenuItem value={"IL"}>Illinois</MenuItem>
                  <MenuItem value={"IN"}>Indiana</MenuItem>
                  <MenuItem value={"IA"}>Iowa</MenuItem>
                  <MenuItem value={"KS"}>Kansas</MenuItem>
                  <MenuItem value={"KY"}>Kentucky</MenuItem>
                  <MenuItem value={"LA"}>Louisiana</MenuItem>
                  <MenuItem value={"ME"}>Maine</MenuItem>
                  <MenuItem value={"MD"}>Maryland</MenuItem>
                  <MenuItem value={"MA"}>Massachusetts</MenuItem>
                  <MenuItem value={"MI"}>Michigan</MenuItem>
                  <MenuItem value={"MN"}>Minnesota</MenuItem>
                  <MenuItem value={"MS"}>Mississippi</MenuItem>
                  <MenuItem value={"MO"}>Missouri</MenuItem>
                  <MenuItem value={"MT"}>Montana</MenuItem>
                  <MenuItem value={"NE"}>Nebraska</MenuItem>
                  <MenuItem value={"NV"}>Nevada</MenuItem>
                  <MenuItem value={"NH"}>New Hampshire</MenuItem>
                  <MenuItem value={"NJ"}>New Jersey</MenuItem>
                  <MenuItem value={"NM"}>New Mexico</MenuItem>
                  <MenuItem value={"NY"}>New York</MenuItem>
                  <MenuItem value={"NC"}>North Carolina</MenuItem>
                  <MenuItem value={"ND"}>North Dakota</MenuItem>
                  <MenuItem value={"OH"}>Ohio</MenuItem>
                  <MenuItem value={"OK"}>Oklahoma</MenuItem>
                  <MenuItem value={"OR"}>Oregon</MenuItem>
                  <MenuItem value={"PA"}>Pennsylvania</MenuItem>
                  <MenuItem value={"RI"}>Rhode Island</MenuItem>
                  <MenuItem value={"SC"}>South Carolina</MenuItem>
                  <MenuItem value={"SD"}>South Dakota</MenuItem>
                  <MenuItem value={"TN"}>Tennessee</MenuItem>
                  <MenuItem value={"TX"}>Texas</MenuItem>
                  <MenuItem value={"UT"}>Utah</MenuItem>
                  <MenuItem value={"VT"}>Vermont</MenuItem>
                  <MenuItem value={"VA"}>Virginia</MenuItem>
                  <MenuItem value={"WA"}>Washington</MenuItem>
                  <MenuItem value={"WV"}>West Virginia</MenuItem>
                  <MenuItem value={"WI"}>Wisconsin</MenuItem>
                  <MenuItem value={"WY"}>Wyoming</MenuItem>
                </Select>
              </FormControl>

            ) : (
              <TextField
                id="state"
                type={"text"}
                label="State"
                value={addressState}
                disabled={!isChecked}
                onChange={(e) => setAddressState(e.target.value)}
                inputProps={{ maxLength: 2 }}
                sx={{ paddingBottom: 1 , width: "100%"}}
              >
              </TextField>
            )}
            <TextField
              id="zip"
              type={"text"}
              label="Zip Code"
              value={addressZipCode}
              disabled={!isChecked}
              onChange={(e) => {
                const re = /^[0-9\b]+$/; // regular expression - match numbers only
                if (e.target.value === '' || re.test(e.target.value)) {
                  setAddressZipCode(e.target.value);
                }
              }}              
              sx={{ marginTop: 1 , width: "100%" }}
              inputProps={{ maxLength: 5, pattern: "\\d*" }}
            >
            </TextField>
          </Grid>
        </Grid>


        <Grid item xs={12} p={0.5}  >
          <LoadingButton
            disabled={addressLine1 === "" || addressCity === "" || addressZipCode === "" || addressState === "" ||
            addressLine1 === undefined || addressCity === undefined || addressZipCode === undefined || addressState === undefined}
            size="small"
            variant="contained"
            color="primary"
            loading={processStep.isLoading}
            onClick={() => {
              let address = {
                addressLine1: addressLine1,
                city: addressCity,
                postalCode: addressZipCode,
                state: addressState,
                country: "US",
              };
              processStep.mutate({recipient, token, address});
             }
            }
          >
            {getLabel("external-payment." + currentStep + ".btnContinue","Continue")}
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
}
