import * as React from "react";
import Box from "@mui/material/Box";
import Background from "../../components/ui/Background";
import {
  Grid,
} from "@mui/material";
import Typography from "@mui/material/Typography";

import Header from "../../components/ui/Header";
import VisualMessage from "../../components/ui/VisualMessage";
import useLabels from "../../labels/useLabels";
import StepEmail from "../onboarding/StepEmail";
import OnboardingAccordion from "../../components/ui/OnboardingAccordion";
import StepChooseRecipient from "./StepChooseRecipient";
import StepAmount from "./StepAmount";
import StepPayment from "./StepPayment";

export default function Main() {
  const { getLabel } = useLabels();
  const [step, setStep] = React.useState("1");
  const [loaded, setLoaded] = React.useState(false);
  const [backgroundImage, setBackgroundImage] = React.useState(null);
  const [bgDesktop2, setBgDesktop2] = React.useState(null);
  const [visualMessage, setVisualMessage] = React.useState();
  const [email, setEmail] = React.useState("");
  const [token, setToken] = React.useState("");
  const [recipients, setRecipients] = React.useState([]);
  const [selectedRecipient, setSelectedRecipient] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [expanded, setExpanded] = React.useState(step);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  React.useEffect(() => {console.log("selectedRecipient", selectedRecipient)}, [selectedRecipient]);

  React.useEffect(() => {
    async function getInitials(subdomain) {
      try {
        const [backgroundImage, bgImage2] = await Promise.all([
          import(`../../images/bg_login.${subdomain}.jpg`),
          import(`../../images/bg_desktop.2.${subdomain}.jpg`),
        ]);
        setBackgroundImage(backgroundImage.default);
        setBgDesktop2(bgImage2.default);
      } catch (error) {
        getInitials("rv");
      }
    }

    getInitials(window.subdomain);
  }, []);

  return (
    <div style={{ display: loaded ? "block" : "none" }}>
      <Background image={backgroundImage} onLoad={() => setLoaded(true)}>
        {visualMessage && (
          <VisualMessage
            title={visualMessage.title}
            message={visualMessage.message}
            severity={visualMessage.severity}
            onClose={visualMessage.onClose}
          />
        )}
        <main>
          <Box>
            <Grid container alignItems="flex-start" sx={{ height: "100vh" }}>
              <Grid container sx={{ height: "600px" }}>
                <Header />
                <Grid
                  item
                  xs={6}
                  sx={{
                    textAlign: "center",
                    backgroundImage: `url(${bgDesktop2})`,
                    backgroundSize: "cover",
                    backgroundPosition: "40% center",
                    width: "100%",
                    height: "100vh",
                    display: { xs: "none", sm: "block" },
                  }}
                />
                <Grid item xs={12} sm={6} sx={{ textAlign: "center" }}>
                  <Grid
                    container
                    justifyContent="center"
                    sx={{ height: "100vh" }}
                  >
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <main>
                        <Box>
                          <Grid
                            alignItems="center"
                            container
                            justifyContent="center"
                            padding={0}
                            pt={1}
                            sx={{
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <Grid item xs={12} mb={2}>
                              <Typography color={"primary"} variant="h6">
                                {getLabel(
                                  "external-payment.welcome.line1",
                                  "Rellevate Payments Made Easy!"
                                )}
                              </Typography>
                              <Typography color={"primary"}>
                                {getLabel(
                                  "external-payment.welcome.line2",
                                  "Ready to transfer funds to a Rellevate account? Follow these simple steps for seamless payments."
                                )}
                              </Typography>
                            </Grid>
                            <OnboardingAccordion
                              currentStep={step}
                              thisStep={1}
                              title={
                                getLabel(
                                  "external-payment.acordion01.label",
                                  "Email Verification"
                                ) + (email ? ` (${email})` : "")
                              }
                              expanded={expanded}
                              handleChange={handleChange}
                            >
                              <StepEmail
                                onSave={(email, values) => {
                                  setEmail(email);
                                  setToken(values.data.code);
                                  setRecipients(values.data.recipients);
                                  setStep(2);
                                }}
                                processStepUrl="/externalPayment/fetchRecipients"
                                from={"external-payment"}
                              />
                            </OnboardingAccordion>
                            <OnboardingAccordion
                              currentStep={step}
                              thisStep={2}
                              title={getLabel(
                                "external-payment.acordion02.label",
                                "Choose recipient"
                              )}
                              expanded={expanded}
                              handleChange={handleChange}
                            >
                              <StepChooseRecipient
                                email={email}
                                token={token}
                                recipients={recipients}
                                onSave={(selectedRecipient) => {
                                  setSelectedRecipient(selectedRecipient);
                                  setStep(3);
                                }}
                              />
                            </OnboardingAccordion>
                            <OnboardingAccordion
                              currentStep={step}
                              thisStep={3}
                              title={getLabel(
                                "external-payment.acordion03.label",
                                "Enter the amount"
                              )}
                              expanded={expanded}
                              handleChange={handleChange}
                            >
                              <StepAmount
                                onSave={(amount) => {
                                  setAmount(amount);
                                  setStep(4);
                                }}
                              />
                            </OnboardingAccordion>
                            <OnboardingAccordion
                              currentStep={step}
                              thisStep={4}
                              title={getLabel(
                                "external-payment.acordion04.label",
                                "Complete the payment"
                              )}
                              expanded={expanded}
                              handleChange={handleChange}
                            >
                              <StepPayment
                                email={email}
                                code={token}
                                recipient={selectedRecipient}
                                amount={amount}
                                onSave={(amount) => {
                                  setAmount(amount);
                                  setStep(4);
                                }}
                              />
                            </OnboardingAccordion>
                          </Grid>
                        </Box>
                      </main>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </main>
      </Background>
    </div>
  );
}
