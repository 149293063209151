import * as React from "react";
import { useParams } from 'react-router-dom';

import Box from "@mui/material/Box";
import Background from "../../components/ui/Background";
import {
  CircularProgress,
  Grid,
} from "@mui/material";
import Typography from "@mui/material/Typography";

import Header from "../../components/ui/Header";
import VisualMessage from "../../components/ui/VisualMessage";
import useLabels from "../../labels/useLabels";
import StepEmail from "../onboarding/StepEmail";
import OnboardingAccordion from "../../components/ui/OnboardingAccordion";
import StepAmount from "../externalPayments/StepAmount";
import StepPayment from "../externalPayments/StepPayment";
import RecipientLandingService from "../../services/RecipientLandingService";
import ErrorPage from "../ErrorPage";
import StepValidationCode from "./StepValidationCode";
import StepBillingAddress from "./StepBillingAddress";
import StepAddDebitCard from "./StepAddDebitCard";
import StepChooseCard from "./StepChooseCard";
import StepConfirmation from "./StepConfirmation";
import SuccessfullTransaction from "../../components/ui/SuccessfullTransaction";


export default function Main() {

  const { getLabel } = useLabels();
  const [step, setStep] = React.useState("1");
  const [loaded, setLoaded] = React.useState(false);
  const [backgroundImage, setBackgroundImage] = React.useState(null);
  const [bgDesktop2, setBgDesktop2] = React.useState(null);
  const [visualMessage, setVisualMessage] = React.useState();
  const [validationCode, setValidationCode] = React.useState(""); 
  const [moneyMovementTransactionId, setMoneyMovementTransactionId] = React.useState("");
  const [googleApiKey, setGoogleApiKey] = React.useState("");
  const [recipient, setRecipient] = React.useState("");
  const [selectedDebitCard, setSelectedDebitCard] = React.useState("");
  const [token, setToken] = React.useState("");
  const [validatedToken, setValidatedToken] = React.useState("");
  const [paymentToken, setPaymentToken] = React.useState("");
  const [recipients, setRecipients] = React.useState([]);
  const [selectedRecipient, setSelectedRecipient] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [expanded, setExpanded] = React.useState(step);
  const [address, setAddress] = React.useState(null);
  const [isAddressSubmitted, setIsAddressSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const [transferId, setTransferId] = React.useState(null);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { token : urlToken } = useParams();

  const { recipientLandingInfo, isLoading, isError, error } = RecipientLandingService(urlToken);
  //React.useEffect(() => {console.log("selectedRecipient", selectedRecipient)}, [selectedRecipient]);

  React.useEffect(() => {
    async function getInitials(subdomain) {
      try {
        const [backgroundImage, bgImage2] = await Promise.all([
          import(`../../images/bg_login.${subdomain}.jpg`),
          import(`../../images/bg_recipient.${subdomain}.jpg`),
        ]);
        setBackgroundImage(backgroundImage.default);
        setBgDesktop2(bgImage2.default);
      } catch (error) {
        getInitials("rv");
      }
    }

    getInitials(window.subdomain);  
  }, []);


  React.useEffect(() => {
    if (recipientLandingInfo) {
      setRecipient(recipientLandingInfo?.data?.recipient);
      setToken(recipientLandingInfo?.token);
      if (recipientLandingInfo?.errorMessage) {
        setErrorMessage(recipientLandingInfo.errorMessage);
      }
    }
  }, [recipientLandingInfo]);


  // Now you can use the `token` variable
  console.log(urlToken);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }


  if (success) return (
    <div style={{ width: '100%', display: "grid" }}>
        <SuccessfullTransaction  
          message={'Your reference number is:'}
          id={transferId}
        />
    </div>
);

  return (
    <div style={{ display: loaded ? "block" : "none" }}>

      <Background image={backgroundImage} onLoad={() => setLoaded(true)}>
        {visualMessage && (
          <VisualMessage
            title={visualMessage.title}
            message={visualMessage.message}
            severity={visualMessage.severity}
            onClose={visualMessage.onClose}
          />
        )}

        <main>

          <Box>

            <Grid container alignItems="flex-start" sx={{ height: "100vh" }}>
              <Grid container sx={{ height: "600px" }}>
                <Header />

                <Grid
                  item
                  xs={6}
                  sx={{     
                                   
                    textAlign: "center",
                    backgroundImage: `url(${bgDesktop2})`,
                    backgroundSize: "cover",
                    backgroundPosition: "30% center",
                    width: "100%",
                    height: "95vh",
                    display: { xs: "none", sm: "block" },
                  }}
                />

                <Grid item xs={12} sm={6} sx={{ textAlign: "center" }}>
                  <Grid
                    container
                    justifyContent="center"
                    sx={{ height: "100vh", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                    
                      {recipientLandingInfo && errorMessage ?
                          <ErrorPage message={errorMessage} />
                      
                      :
                      <main>

                        <Box>
                          <Grid
                            alignItems="center"
                            container
                            justifyContent="center"
                            padding={0}
                            pt={1}
                            sx={{
                              width: "100%",
                              height: "100%",
                            }}
                          >
                          
                            <Grid item xs={12} mb={2}>
                              <Typography color={"primary"} variant="h6">
                                {getLabel(
                                  "external-payment.welcome.line1",
                                  "Rellevate Payments Made Easy!"
                                )}
                              </Typography>
                              <Typography color={"black"} sx={{paddingInline: "1rem", paddingTop:"1rem"}}>
                                {getLabel(
                                  "external-payment.welcome.line2",
                                  "Welcome "+recipientLandingInfo?.data?.fullName+", an Instant Send was made with your name. Follow the next steps to get your money into your debit card."
                                )}
                              </Typography>
                            </Grid>
                            
                            <OnboardingAccordion
                              currentStep={step}
                              thisStep={1}
                              title={
                                getLabel(
                                  "external-payment.acordion01.label",
                                  "Verification"
                                ) + (validationCode ? ` (${validationCode})` : "")
                              }
                              expanded={expanded}
                              handleChange={handleChange}
                            >
                              <Typography color={"black"} sx={{paddingInline: "1rem", paddingBottom:"1rem"}}>
                                {getLabel(
                                  "external-payment.welcome.line2",
                                  "Enter your confirmation code. Please ask the sender if you do not have it yet."
                                )}
                              </Typography>

                              <StepValidationCode
                                recipient={recipient}
                                token={token}
                                onSave={(validationCode, values) => {
                                  setValidatedToken(values?.data?.token);
                                  setRecipients(values?.data?.data?.beneficiaries);
                                  setGoogleApiKey(values?.data?.data?.googleApiKey);
                                  setMoneyMovementTransactionId(values?.data?.data?.moneyMovementTransactionId);
                                  setPaymentToken(values?.data?.recipientToken);
                                  //setRecipients(values.data.recipients);
                                  setStep(isAddressSubmitted ? (recipients.length>0 ? 4 : 3) : 2);
                                }}
                                processStepUrl="/payment/receive/validate"
                                from={"instant-send-others"}
                                setErrorMessage={setErrorMessage}
                              />
                            </OnboardingAccordion>
                            <OnboardingAccordion
                              currentStep={step}
                              thisStep={2}
                              title={getLabel(
                                "external-payment.acordion02.label",
                                "Billing Address"
                              )}
                              expanded={expanded}
                              handleChange={handleChange}
                            >
                              <Typography color={"black"} sx={{paddingInline: "1rem", paddingBottom:"1rem"}}>
                                {getLabel(
                                  "external-payment.welcome.line2",
                                  "Please, enter the Billing Address of your debit card."
                                )}
                              </Typography>
                              <StepBillingAddress
                                //validationCode={validationCode}
                                recipient={recipient}
                                token={validatedToken}
                                recipients={recipients}
                                googleApiKey={googleApiKey}
                                processStepUrl="/payment/receive/address"
                                onSave={(address) => {
                                  const alreadyHaveACardActive =
                                    recipients.length > 0 &&
                                    recipients.filter(recipient => recipient.idMoneyMovementItem !== null &&
                                      recipient.idMoneyMovementItem !== undefined &&
                                      recipient.idMoneyMovementItem !== "").length >0 ;
                                  setAddress(address);
                                  setStep(alreadyHaveACardActive ? 4 : 3);
                                  setIsAddressSubmitted(true);
                                }}
                              />
                            </OnboardingAccordion>
                            <OnboardingAccordion
                              currentStep={step}
                              thisStep={3}
                              title={getLabel(
                                "external-payment.acordion03.label",
                                "Setup your debit card"
                              )}
                              expanded={expanded}
                              handleChange={handleChange}
                            >
                              <StepAddDebitCard
                                recipient={recipient}
                                token={validatedToken}
                                setStep={setStep}
                                onSave={(response, data) => {
                                    if (response?.instrumentStatus !== "ACTIVE" && response?.instrumentStatus !== "UNPROVED") {
                                      /*TODO comment or delete the UNPROVED for production */
                                      setPaymentToken(null);
                                      setValidationCode(null);
                                      setStep(1);
                                    }
                                    else {
                                      setPaymentToken(null);
                                      setValidationCode(null);
                                      setRecipients(response?.data?.data?.beneficiaries);
                                      setStep(4);
                                    }
                                  } 
                                }
                                recipients={recipients}
                                paymentToken={paymentToken}
                                processStepUrl="/payment/receive/instrument"
                                isVisible={step === 3}
                              />
                            </OnboardingAccordion>
                            <OnboardingAccordion
                              currentStep={step}
                              thisStep={4}
                              title={getLabel(
                                "external-payment.acordion03.label",
                                "Select the debit card"
                              )}
                              expanded={expanded}
                              handleChange={handleChange}
                            >
                              <StepChooseCard
                                recipient={recipient}
                                token={validatedToken}
                                setStep={setStep}
                                onSave={(debitCard) => {
                                  setSelectedDebitCard(debitCard);
                                  setStep(5);
                                  } 
                                }
                                recipients={recipients}
                                paymentToken={paymentToken}
                                goToAddDebitCardStep={() => { setStep(3)} }
                                processStepUrl="/payment/receive/instrument"
                                isVisible={paymentToken!==null && paymentToken!==undefined && paymentToken!=="" && isAddressSubmitted}
                              />
                            </OnboardingAccordion>
                            <OnboardingAccordion
                              currentStep={step}
                              thisStep={5}
                              title={getLabel(
                                "external-payment.acordion04.label",
                                "Complete the payment"
                              )}
                              expanded={expanded}
                              handleChange={handleChange}
                            >
                              <StepConfirmation
                                recipient={recipient}
                                token={validatedToken}
                                preProcessUrl="/payment/receive/confirm"
                                processStepUrl="/payment/receive/complete"
                                email={validationCode}
                                step={step}
                                onSave={(response) => {
                                  if (response?.response?.data?.exceptionMessage === "TRANSACTION_ALREADY_COMPLETED" ||
                                    response?.response?.data?.exceptionMessage === "TRANSACTION_NOT_AVAILABLE") {
                                    setErrorMessage(response?.response?.data?.message);
                                  } 
                                  if (response?.redirectTo===1) {
                                    setStep(1);
                                  }
                                  if (response?.errorMessage) {
                                    setErrorMessage(response.errorMessage);
                                  }
                                  if (response?.data?.data?.transferId) {
                                    setTransferId(response.data.data.moneyMovementTransactionId);
                                    setSuccess(true);
                                  }
                                }}
                              />
                            </OnboardingAccordion>
                          </Grid>
                        </Box>
                      </main>
                      }

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </main>
      </Background>
    </div>
  );
}
