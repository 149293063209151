import { useQuery } from "@tanstack/react-query";
import HttpService from "./HttpService";

async function fetchPADDetail() {
  const response = await HttpService.getAxiosClient().get(
    window.API_URL + `/pad/time/detail`
  );
  return response;
}

const PADDetailService = () => {
  const { isLoading, isError, data, error } = useQuery(
    ["PADDetailInfo"],
    fetchPADDetail,
    {
      refetchOnWindowFocus: false,
      staleTime: 10000
    }
  );

  const padDetailInfo = data?.data || null;
 
  return {
    padDetailInfo,
    isLoading,
    isError,
    error
  };
};

export default PADDetailService;
