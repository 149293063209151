import { useQuery } from "@tanstack/react-query";
import HttpService from "../HttpService";

async function fetchAgents(filter_string) {
  const response = await HttpService.getAxiosClient().get(
    window.API_URL + `/payment/agent?filter_string=${filter_string}`,
  );
  return response;
}

const AgentService = (filter_string) => {
  const { isLoading, isError, data, error } = useQuery(
    ["agents", filter_string],
    () => fetchAgents(filter_string),
    {
      refetchOnWindowFocus: false,
      staleTime: 10000
    }
  );

  const agents = data?.data || null;

  return {
    agents,
    isLoading,
    isError,
    error
  };
};

export default AgentService;
