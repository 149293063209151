/* Password Creation */

import * as React from "react";
import { Grid, TextField, Typography } from "@mui/material";
import LoadingButton from "../../components/ui/LoadingButton";
import useLabels from "../../labels/useLabels";
import { useFormik } from "formik";
import * as validatorYup from "yup";
import { useMutation } from "@tanstack/react-query";
import HttpService from "../../services/HttpService";
import { useNavigate } from "react-router-dom";

export default function StepPassword({ onSave, email, token, loginMutationIsLoading }) {
  const thisStep = "7";
  const { getLabel } = useLabels();
  const navigate = useNavigate();
  const validationSchema = validatorYup.object({
    password: validatorYup
      .string()
      .min(6, "At least 6 characters")
      .max(20, "At most 20 characters")
      .matches(/\d/, "At least one number")
      .matches(/[ _!@#$%&*]/, "At least one special character (_!@#$%&*)")
      .required("Required field"),
    confirmPassword: validatorYup
      .string()
      .oneOf([validatorYup.ref("password"), null], "Passwords must match")
      .required("Required field"),
  });
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const transformedValues = {
        email: email,
        token: token,
        step: (Number(thisStep) + 1).toString(), 
        bagContent: { ...values },
      };
      processStep.mutate({ ...transformedValues });
    },
  });
  const processStep = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/onboarding/saveStep`,
        data,
        { avoidBearer: true }
      );
    },
    {
      onSuccess: (values) => {
        values?.data?.intercepted ? navigate("/underReview")  : onSave(formik.values.password);
      },
      onError: (values) => {
      },
    }
  );

  return (
    <>
      <Grid
        container
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <Grid item xs={12} textAlign={"left"}>
          <Typography variant="caption">
            {getLabel(
              "onboarding." + thisStep + ".PasswordHint.line1",
              "The password must have at least 6 characters and 20 characters maximum"
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign={"left"}>
          <Typography variant="caption">
            {getLabel(
              "onboarding." + thisStep + ".PasswordHint.line2",
              "It must contain at least one number"
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign={"left"}>
          <Typography variant="caption">
            {getLabel(
              "onboarding." + thisStep + ".PasswordHint.line3",
              "It must contain at least one special character, e.g., _!@#$%&*"
            )}
          </Typography>
        </Grid>
        <Grid item xs={6} p={0.5} pt={1.5}>
          <TextField
            required
            size="small"
            label={"Password"}
            id="password"
            type={"password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.touched.password && formik.errors.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            autoComplete="password"
            fullWidth
          />
        </Grid>
        <Grid item xs={6} p={0.5} pt={1.5}>
          <TextField
            required
            size="small"
            label="Confirm password"
            id="confirmPassword"
            type={"password"}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            autoComplete="confirmPassword"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} p={0.5}>
          <LoadingButton
            size="small"
            variant="contained"
            color="primary"
            type="submit"
            loading={processStep.isLoading || loginMutationIsLoading}
          >
            {getLabel("onboarding." + thisStep + ".btnContinue", "Create Rellevate Account")}
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
}
