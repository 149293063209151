import {
  Card,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  SvgIcon,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import * as React from "react";
import { Box } from "@mui/system";
import Menu from "../components/ui/Menu";
import useLabels from "../labels/useLabels";
import LoadingButton from "../components/ui/LoadingButton";
import VisualMessage from "../components/ui/VisualMessage";
import { ReactComponent as payanydayIcon } from "../images/payanyday.svg";
import PADService from "../services/PADService";
import PADDetailService from "../services/PADDetailService";
import CardService from "../services/CardService";
import { useFormik } from "formik";
import * as validatorYup from "yup";
import { CircularProgress } from "@mui/material";
import { eachDayOfInterval, startOfDay, endOfDay, format } from "date-fns";
import { useTheme } from "@mui/material/styles";
import { useMutation } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import { useNavigate } from "react-router-dom";
import MainBackground from "../components/ui/MainBackground";

export default function MainSite() {
  const {getLabel} = useLabels();
  const navigate = useNavigate();
  const theme = useTheme();

  const padService = PADService();
  const padDetailService = PADDetailService();
  const cardService = CardService();

  const isMobile = useMediaQuery("(max-width:600px)");
  const [visualMessage, setVisualMessage] = React.useState();

  const transferPADMutation = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/pad/transfer`,
        data
      );
    },
    {
      onSuccess: (value) => {
        setVisualMessage({
          title: getLabel("pad.ok.title", "Pay Any-Day Success"),
          message: getLabel(
            "pad.ok.message",
            "Pay Any-Day was successful"
          ),
          severity: "success",
          onClose: () => {
            setVisualMessage();
            navigate("/home");
          },
        });
      },
      onError: (values) => {
        setVisualMessage({
          title: getLabel("pad.error.title", "Pay Any-Day Error"),
          message: getLabel(
            "pad.error.message",
            "Pay Any-Day was not successful"
          ),
          onClose: () => setVisualMessage(),
        });
      },
    }
  );

  const validationSchema = validatorYup.object({
    amount: validatorYup
      .number()
      .transform((value) => (isNaN(value) ? "" : value))
      .typeError(getLabel("pad.validation.amount.number", "Amount must be a number"))
      .required(getLabel("pad.validation.amount.required", "Amount is required"))
      .min(20, getLabel("pad.validation.amount.greaterThan","Amount must be greater than 20"))
      .test(
        "is-valid-amount",
        getLabel("pad.validation.amount.lessThan","Amount cannot be greater than your available PAD balance"),
        (value) => {
          return value <= padService.padInfo.available;
        }
      ),
  });

  const formik = useFormik({
    initialValues: {
      amount: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      transferPADMutation.mutate({ ...values });
    },
  });

  async function getInitials(subdomain) {}

  React.useEffect(() => {
    getInitials();
  }, []);

  if (
    padService.isLoading ||
    padDetailService.isLoading ||
    cardService.isLoading
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  const getDatesBetween = (startDate, endDate) => {
    return eachDayOfInterval({
      start: startOfDay(new Date(startDate)),
      end: endOfDay(new Date(endDate)),
    });
  };

  const afterBalance = (amount) => {
    if (Boolean(formik.errors.amount)) { return cardService?.cardDetail?.availableBalance; }
    const _amount = formik.values.amount ? formik.values.amount : 0;
    const _balance = cardService?.cardDetail?.availableBalance
      ? cardService?.cardDetail?.availableBalance
      : 0;
    const _fee = padService.padInfo.feeToApply
      ? padService.padInfo.feeToApply
      : 0;
    const afterBalance = Number(_balance) + Number(_amount) - Number(_fee);
    return afterBalance;
  };

  return (
    <div style={{ display: true ? "block" : "none" }}>
      {visualMessage && (
        <VisualMessage
          title={visualMessage.title}
          message={visualMessage.message}
          severity={visualMessage.severity}
          onClose={visualMessage.onClose}
        />
      )}
      <MainBackground 
        isMobile={isMobile}         
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <Grid container style={{width:isMobile?"100%":"65%", margin:!isMobile?"3rem auto":0}}  >
          <Grid item xs={12} p={1}>
            <div style={{ display: "flex", justifyContent: "center", alignItems:"center" }}>
              <SvgIcon
                fontSize="medium"
                component={payanydayIcon}
                inheritViewBox
              />
              <Typography variant="h6" sx={{ fontWeight: "bold" }} pl={1}>
                Pay Any-Day
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Paper
                xs={12}
                sx={{
                  borderRadius: "10px",
                  maxWidth: "80rem",
                  backgroundColor: "rgba(255, 255, 255, 0.25)",
                }}
                elevation={4}
              >
                <Grid container pt={4} alignContent={"center"}>
                  <Grid item xs={padService.padInfo.pendingPayback>0? 4 : 6} textAlign={"center"}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(padService.padInfo.accumulated)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          {getLabel("pad.totalThisPeriod", "Total This Period")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {padService.padInfo.pendingPayback>0 &&
                  <Grid item xs={4} textAlign={"center"}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="h6" color="red">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(padService.padInfo.pendingPayback)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          {getLabel("pad.pendingPayback", "Pending Payback")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  }
                  <Grid item xs={padService.padInfo.pendingPayback > 0 ? 4 : 6} textAlign={"center"}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(padService.padInfo.taken)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          {getLabel("pad.alreadyAdvanced", "Already Advanced")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {padService.padInfo.activePeriod==="true" && 
                  <Grid
                    item
                    xs={12}
                    textAlign={"center"}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Card
                      sx={{
                        borderRadius: "10px",
                        width: "95%",
                        backgroundColor: "rgba(255, 255, 255, 0.25)",
                      }}
                    >
                      <Grid container pt={2}>
                        <Grid xs={6} item textAlign={"right"} pr={1}>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: "bold" }}
                          >
                            {getLabel("pad.thisPeriod", "This Period") + ": "}
                          </Typography>
                        </Grid>
                        <Grid xs={6} item textAlign={"left"}>
                          <Typography variant="body2">
                            {new Date(
                              padService.padInfo.periodStartDate
                            ).toLocaleDateString()}{" "}
                            -{" "}
                            {new Date(
                              padService.padInfo.periodEndDate
                            ).toLocaleDateString()}
                          </Typography>
                        </Grid>
                        <Grid xs={12} item pt={1}>
                          <Grid container>
                            <Grid item xs={12}>
                              <Box
                                sx={{
                                  display: "flex",
                                  overflowX: "scroll",
                                  flexWrap: "nowrap",
                                  justifyContent: "center",
                                  maxWidth: "350px",
                                  margin: "auto",
                                }}
                              >
                                {getDatesBetween(
                                  padService.padInfo.periodStartDate,
                                  padService.padInfo.periodEndDate
                                ).map((date, index) => {
                                  const detail =
                                    padDetailService.padDetailInfo.padTimeDetail.find(
                                      (detail) =>
                                        new Date(
                                          detail.dateOfWork
                                        ).toDateString() === date.toDateString()
                                    );

                                  const backgroundColor =
                                    detail && detail.availablePerDay > 0
                                      ? theme.palette.primary.main
                                      : "#c0c0c0";

                                  return (
                                    <Box
                                      key={index}
                                      sx={{
                                        borderRadius: "5px",
                                        margin: 0.15,
                                        marginTop: 0,
                                        marginBottom: 0,
                                        minWidth: "25px",
                                        minHeight: "30px",
                                        backgroundColor: backgroundColor,
                                      }}
                                    >
                                      <Typography
                                        variant="caption"
                                        sx={{
                                          color: "white",
                                          fontWeight: "light",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div>{format(date, "dd")}</div>
                                          <div>{format(date, "MMM")}</div>
                                        </div>
                                      </Typography>
                                    </Box>
                                  );
                                })}
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid xs={12} item textAlign={"center"} pt={1}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Grid container>
                                <Grid
                                  item
                                  xs={6}
                                  display="flex"
                                  justifyContent="right"
                                >
                                  <Box
                                    sx={{
                                      borderRadius: "10px",
                                      margin: "2px",
                                      width: "10px",
                                      height: "10px",
                                      backgroundColor:
                                        theme.palette.primary.main,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6} textAlign={"left"}>
                                  <Typography variant="body2">
                                    {getLabel("pad.worked", "Worked")}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={6}>
                              <Grid container>
                                <Grid
                                  item
                                  xs={6}
                                  display="flex"
                                  justifyContent="right"
                                >
                                  <Box
                                    sx={{
                                      borderRadius: "10px",
                                      width: "10px",
                                      height: "10px",
                                      margin: "2px",
                                      backgroundColor: "#c0c0c0",
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6} textAlign={"left"}>
                                  <Typography variant="body2">
                                    {getLabel("pad.notWorked", "Not Worked")}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  }
                  <Grid item xs={12} textAlign={"center"} pt={3}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          color={"secondary"}
                          sx={{ fontWeight: "bold" }}
                        >
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(padService.padInfo.available)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1" sx={{ fontWeight: "900" }}>
                          {getLabel("pad.availableToAdvance", "Available to Advance")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            pt={1}
            alignContent={"center"}
            textAlign={"center"}
          >
            <FormControl fullWidth variant="outlined" sx={{maxWidth: "80rem", margin: "auto"}}>
              <OutlinedInput
                id="amount"
                fullWidth
                sx={{
                  bgcolor: "white",
                  maxWidth: "80rem",
                  margin: "auto",
                }}
                type={"text"}
                placeholder={getLabel("pad.amount", "Amount to Advance")}
                value={formik.values.amount}
                onChange={formik.handleChange}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
              />
              <FormHelperText sx={{ color: "red"}}>
                {formik.errors.amount}
              </FormHelperText>
            </FormControl>
          </Grid>
          {(formik.values.amount!=='' && !Boolean(formik.errors.amount)) ? (
            <Grid
              item
              xs={12}
              pt={1}
              alignContent={"center"}
              textAlign={"center"}
            >
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                loading={transferPADMutation.isLoading}
                color="primary"
                sx={{
                  maxWidth: "80rem",
                  margin: "auto",
                }}
              >
                {getLabel("pad.advance", "Click for Pay Any-Day")}
              </LoadingButton>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} pt={0}>
            <Grid
              container
              sx={{ maxWidth: "80rem", margin: "auto" }}
              justifyContent={"flex-end"}
            >
              <Grid item>
                <Typography
                  variant="caption"
                  color={"secondary"}
                  textAlign={"right"}
                >
                  {getLabel("pad.fee", "Transaction fee:") +
                    " " +
                    padService.padInfo.feeToApply}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} pt={0}>
            <div
              style={{
                // backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Paper
                xs={12}
                sx={{
                  borderRadius: "10px",
                  backgroundColor: "rgba(255, 255, 255, 0.25)",
                  width: "100%",
                  maxWidth: "80rem",
                }}
                elevation={4}
              >
                <Grid container pt={1} mb={5} alignContent={"center"}>
                  <Grid item xs={12} textAlign={"center"}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      {getLabel(
                        "pad.padYourAccountBalance",
                        "Your Account Balance"
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} textAlign={"center"}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(cardService?.cardDetail?.availableBalance)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          {getLabel(
                            "pad.padBeforeAdvance",
                            "Before Advance"
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} textAlign={"center"}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(afterBalance())}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          {getLabel(
                            "pad.padAfterAdvance",
                            "After Advance"
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </Grid>
          <Grid item xs={12} pt={2}>
            <Grid container sx={{ maxWidth: "80rem", margin: "auto" }}>
              <Typography variant="caption" color={"primary"}>
                {getLabel("pad.padDisclaimer18old", "Pad Disclaimer 1")}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container sx={{ maxWidth: "80rem", margin: "auto" }}>
              <Typography variant="caption" color={"primary"}>
                {getLabel(
                  "pad.padDisclaimer50percent",
                  "Pad Disclaimer 2"
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </MainBackground>
      <Menu isMobile={isMobile} />
    </div>
  );
}
