import {
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  SvgIcon,
  Box,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import HttpService from "../services/HttpService";
import { useNavigate } from "react-router-dom";
import StatementsListService from "../services/StatementsListService";
import VisualMessage from "../components/ui/VisualMessage";
import React from "react";
import { ReactComponent as statementsIcon } from "../images/statements.svg";
import useLabels from "../labels/useLabels";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import Menu from "../components/ui/Menu";
import LoadingButton from "../components/ui/LoadingButton";
import MainBackground from "../components/ui/MainBackground";


export default function Statements() {
  const {getLabel} = useLabels();
  const navigate = useNavigate();
  const statementsListService = StatementsListService();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [visualMessage, setVisualMessage] = React.useState();

  const getMonthName = (monthNumber) => {
    // Create a Date object with the desired month number
    // Note: JavaScript counts months from 0 (January) to 11 (December), so we subtract 1 from the input
    const date = new Date(2020, monthNumber - 1);  
    // Use toLocaleString to get the abbreviated month name
    const monthName = date.toLocaleString('en-US', { month: 'long' });
    return monthName;
  };


  const [isDownloading, setIsDownloading] = React.useState(false);

  async function getInitials(subdomain) {}

  React.useEffect(() => {
    getInitials();
  }, []);


  const handleDownloadClick = (event, key) => {
    setIsDownloading(true);

    HttpService.getAxiosClient()
      .get(window.API_URL + `/customer/statements/` + key, {
        responseType: "blob",
      })
      .then((response) => {
        setIsDownloading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = key+".pdf";
        document.body.appendChild(a); // append the element to the dom
        a.click();
        a.remove(); // afterwards, remove the element

      })
      .catch((error) => {
        setIsDownloading(false);
        console.error(error);
      });
    
    event.preventDefault();
  };




  if (
    statementsListService.isLoading || isDownloading
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }


  return (
    <div style={{ display: true ? "block" : "none" }}>
      {visualMessage && (
        <VisualMessage
          title={visualMessage.title}
          message={visualMessage.message}
          severity={visualMessage.severity}
          onClose={visualMessage.onClose}
        />
      )}
      <MainBackground
        isMobile={isMobile}
      >
        <Grid container style={{width:isMobile?"100%":"65%", margin:!isMobile?"3rem auto":0}}  >
          <Grid item xs={12} p={1}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <SvgIcon
                fontSize="medium"
                component={statementsIcon}
                inheritViewBox
              />
              <Typography variant="h6" sx={{ fontWeight: "bold" }} pl={1}>
                {getLabel("statements.title", "Statements")}
              </Typography>
            </div>
            <br></br>
            <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="body1" sx={{ fontWeight: "normal" }} pl={1}>
            {getLabel("statements.subtitle", "To provide you with accurate financial records, kindly select your preferred monthly statement from the list below")}
            </Typography>

            </div>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "1rem",
                maxWidth:"100%",
                flexWrap: 'wrap',
              }}
            >

              {statementsListService.statementsListInfo.statementsList.map((statement) => (
                <Grid key={statement.idStatementLine}>
                  <Paper
                  xs={12}
                  sx={{
                    borderRadius: "10px",
                    maxWidth: "14rem",
                    minWidth: "14rem",
                    backgroundColor: "white",
                    margin: "0.5rem",
                  }}
                  elevation={4}
                  key={statement.idStatementLine}
                >
                    <Grid item xs={12} textAlign={"left"} padding={"0.5rem"} marginInline={"1rem"}  >
                      <Box display="flex" alignItems="center" gap={2} 
                          onClick={(event) =>
                              handleDownloadClick(event, statement.idStatementLine)
                            } >
                        <ArticleOutlinedIcon />
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}  alignItems={"center"} >
                          {getMonthName(statement.month)+ " " +Number(statement.year).toFixed(0)} 
                        </Typography>
                        </Box>
                      </Grid>
                  </Paper>
                </Grid>


                )
                      )}
            </div>
          </Grid>
          <Grid item xs={12} pt={0}>
            <div
              style={{
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
            </div>
            <Grid alignContent="center" item>
              <LoadingButton
                sx={{
                  marginTop: "1rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  navigate(-1);;
                }}
              >
                {getLabel("general.back", "Go Back")}
              </LoadingButton>
            </Grid>
          </Grid>
          <Grid item xs={12} pt={0}>
            <div
              style={{
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
            </div>
          </Grid>
        </Grid>
      </MainBackground>
      <Menu isMobile={isMobile} />
    </div>
  );
}
