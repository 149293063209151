import { useQuery } from "@tanstack/react-query";
import HttpService from "../HttpService";

async function fetchConfig(id) {
  const response = await HttpService.getAxiosClient().get(
    window.API_URL + `/onboarding/config/${id}`,
    {avoidBearer: true}
  );
  return response;
}

const OnboardingConfigService = (id) => {
  const { isLoading, isError, data, error } = useQuery(
    ["onboarding.config", id],
    () => fetchConfig(id),
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
      retry: () => false,
      enabled: id !== undefined
    }
  );

  const config = data?.data || null;

  return {
    config,
    isLoading,
    isError,
    error
  };
};

export default OnboardingConfigService;
