/* Setup Validation Code */
import * as React from "react";
import { Grid, TextField } from "@mui/material";

import LoadingButton from "../../components/ui/LoadingButton";
import useLabels from "../../labels/useLabels";
import { useMutation } from "@tanstack/react-query";
import HttpService from "../../services/HttpService";
import VisualMessage from "../../components/ui/VisualMessage";


export default function StepValidationCode({ onSave, processStepUrl, recipient, token, setErrorMessage}) {
  const [validationCode, setValidationCode] = React.useState("");
  const [validationCodeError, setValidationCodeError] = React.useState("");
  //const [askOTP, setAskOTP] = React.useState(false);
  const [visualMessage, setVisualMessage] = React.useState();

  const handleSave = () => {
    const validationCodeRegex = /^\d{6}$/;;
    if (!validationCode) {
      setValidationCodeError("Validation code is required");
    } else if (!validationCodeRegex.test(validationCode)) {
      setValidationCodeError("Invalid validation, code, must be six digits");
    } else {
      setValidationCodeError("");
      validateCode(validationCode);
    }
  };

  const processStep = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + processStepUrl,
        data,
        { avoidBearer: true }
      );
    },
    {
      onSuccess: (values) => {
        onSave(validationCode, values);
        setValidationCode("");
      },
      onError: (values) => {
        if (values?.response?.data?.exceptionMessage === "TRANSACTION_ALREADY_COMPLETED" ||
          values?.response?.data?.exceptionMessage === "TRANSACTION_NOT_AVAILABLE" ) {
          setErrorMessage(values?.response?.data?.message);
        } else {
          setVisualMessage({
            title: getLabel(
              "sendMoney.validationCode.error.title",
              "Validation Code Error"
            ),
            message:"The validation code does not match.",
            onClose: () => {setVisualMessage()},
          });
          setValidationCode("");
          if (values.response && values.response.data && values.response.data.error) {
            setValidationCodeError(values.response.data.error);
          }
        }

      },
    }
  );


  const validateCode = (code) => {
    //setAskOTP(false);
    processStep.mutate({recipient, token, combinationCode: code});
  };

  const { getLabel } = useLabels();


  return (
    <>
      <Grid container>
        {visualMessage && (
            <VisualMessage
              title={visualMessage.title}
              message={visualMessage.message}
              severity={visualMessage.severity}
              onClose={visualMessage.onClose}
            />
        )}
        <Grid item xs={12} p={0.5}>
          <TextField
            size="small"
            label="Validation Code"
            id="validationCode"
            required
            type={"text"}
            value={validationCode}
            onChange={(e) => setValidationCode(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleSave();
              }
            }}
            error={!!validationCodeError}
            helperText={validationCodeError}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sx={{paddingTop:"1rem"}}>
          <LoadingButton
            size="small"
            variant="contained"
            color="primary"
            onClick={handleSave}
            loading={processStep.isLoading}
          >
            {getLabel("onboarding.1.btnContinue", "Continue")}
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
}
