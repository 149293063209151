import * as React from "react";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Header = (props) => {
  const [logo, setLogo] = React.useState(null);
  const [closeImg, setCloseImg] = React.useState(null);
  const navigate = useNavigate();
  const isClosable = props.isClosable ?? true;
  const showLogo = props.showLogo ?? true;

  console.log('------------------------------------');
  console.log('isClosable:',isClosable);
  console.log('showLogo:',showLogo);
  console.log('------------------------------------');
  React.useEffect(() => {
    async function getInitials(subdomain) {
      try {
        const [logo, closeImg] = await Promise.all([
          import(`../../images/logo.${subdomain}.svg`),
          import(`../../images/close-button.${subdomain}.svg`),
        ]);
        setLogo(logo.default);
        setCloseImg(closeImg.default);
      } catch (error) {
        getInitials("rv");
      }
    }
    getInitials(window.subdomain);
  }, []);

  return (
    <Grid container alignItems="flex-start" sx={{ flexWrap: "nowrap" }} height="40px">
      <Grid item xs={11} ml={10} sx={{ textAlign: "center" }}    >
      {showLogo &&
        (      
          <img src={logo} alt="Company Logo" width="136" height={30} />
        )
      }
      </Grid>
      <Grid item xs={1} mr={1} sx={{ textAlign: "right" }}>
        {isClosable && (
          <Button
          size="small"
          sx={{ mr: 2, color: "loginText.main" }}
          width="100%"
          onClick={() => navigate("/")}
        >
          <img src={closeImg} alt="close" />
        </Button>)}

      </Grid>
    </Grid>
  );
};

export default Header;
