import React from 'react';
import { Box } from '@mui/material';

export default function MainBackground(props) {
  const [backgroundImage, setBackgroundImage] = React.useState(null);
  const { isMobile, ...otherProps } = props;

  React.useEffect(() => {
    async function getInitials(subdomain) {
      try {
        const [backgroundImage] = await Promise.all([
          import(`../../images/bg_login.${subdomain}.jpg`),
        ]);
        setBackgroundImage(backgroundImage.default);
      } catch (error) {
        getInitials("rv");
      }
    }
    getInitials(window.subdomain);
  }, []);

  return (
    <Box
      {...otherProps}
      minHeight={isMobile ? "calc(100vh - 65px)" : "calc(100vh - 15px)"}
      display="flex"
      flexDirection="column"
      maxHeight="0px"
      sx={{
        ...props.sx,
        m: 0,
        p: 1,
        paddingLeft: !isMobile ? "16.5rem" : null,
        overflowY: "auto",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundImage: `url(${backgroundImage})`,
      }}
      onLoad={props.onLoad}
    >
      {props.children}
    </Box>
  );
}