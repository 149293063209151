import { useQuery } from "@tanstack/react-query";
import HttpService from "../HttpService";

async function fetchRecipients(type) {
  try {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/payment/recipient?type=${type}`
    );
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return { data: { beneficiaries: [] }}; // or any other default value
    } else {
      throw error;
    }
  }

}

const RecipientService = (type) => {
  const { isLoading, isError, data, error } = useQuery(
    ["recipients", type],
    () => fetchRecipients(type),
    {
      refetchOnWindowFocus: false,
      staleTime: 10000,
      retry: (failureCount, error) => !(error.response && error.response.status === 404),
    }
  );

  const recipients = data?.data || null;

  return {
    recipients,
    isLoading,
    isError,
    error
  };
};

export default RecipientService;
