/* Password Creation */

import * as React from "react";
import AddDebitCard from "./AddDebitCard";


export default function StepAddDebitCard({ onSave, recipients, paymentToken, isVisible, processStepUrl, recipient, token, setStep }) {
  console.log('------------isVisible from StedAddDebitCard------------------------');
  console.log(isVisible);
  console.log('------------------------------------');
  if (!isVisible) {
    return <></>;
  }

  return <AddDebitCard 
      onSave={onSave}
      recipients={recipients}
      paymentToken={paymentToken}
      processStepUrl={processStepUrl}
      recipient={recipient}
      token={token}
      setStep={setStep}
    />

 
}
