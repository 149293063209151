import {
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  SvgIcon,
  Box,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import HttpService from "../../services/HttpService";
import { useNavigate } from "react-router-dom";
import VisualMessage from "../../components/ui/VisualMessage";
import React from "react";
import { ReactComponent as sendMoneyIcon } from "../../images/sendmoney.svg";
import useLabels from "../../labels/useLabels";
import Menu from "../../components/ui/Menu";
import DeleteIcon from '@mui/icons-material/Delete';
import { useQueryClient } from "@tanstack/react-query";
import RecipientService from "../../services/payments/RecipientService";
import { useParams } from 'react-router-dom';
import LoadingButton from "../../components/ui/LoadingButton";
import MainBackground from "../../components/ui/MainBackground";



export default function Beneficiaries() {
  const {getLabel} = useLabels();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  const isSmallScreen = useMediaQuery("(max-width:1024px)");
  const isStandardScreen = useMediaQuery("(max-width:1200px)");

  const queryClient = useQueryClient()


  const [cancelId, setCancelId] = React.useState();
  const [visualMessage, setVisualMessage] = React.useState();
  
  const { sendMoneyTypeSelection } = useParams();

  const recipientService = RecipientService(sendMoneyTypeSelection);

  const removeRecipientMutation = useMutation(
    (idRecipient) => {
      return HttpService.getAxiosClient().delete(
        window.API_URL + `/payment/recipient/`+idRecipient+`?type=`+sendMoneyTypeSelection,
      );
    },
    {
      onSuccess: (value) => {
        setVisualMessage({
          title: getLabel("recipient.remove.ok.title", "Recipient removed"),
          message: getLabel(
            "recipient.remove.ok.message",
            "The recipient was successfully removed"
          ),
          severity: "success",
          onClose: () => {
            setVisualMessage();
            queryClient.invalidateQueries({ queryKey: ['recipients'] })
          },
        });
        setIsSending(false);
      },
      onError: (values) => {
        const message = values?.response?.data?.message;
        const exceptionMessage = values?.response?.data?.exceptionMessage;

        setVisualMessage({
          title: getLabel("recipient.remove.error.title", "Recipient not removed"),
          message: getLabel(
            "recipient.remove.error.message."+message,
            exceptionMessage!==null && exceptionMessage!==undefined? exceptionMessage : "The recipient was not successfully removed"
          ),
          onClose: () => setVisualMessage(),
        });
        setIsSending(false);
      },
    }
  );

  const [isSending, setIsSending] = React.useState(false);


  async function getInitials(subdomain) {}

  React.useEffect(() => {
    getInitials();
  }, []);


  const handleCancelClick = (id) => {
    setIsSending(true);
    setCancelId(id);
    removeRecipientMutation.mutate(id);
  };


  return (
    <div style={{ display: true ? "block" : "none" }}>
      {visualMessage && (
        <VisualMessage
          title={visualMessage.title}
          message={visualMessage.message}
          severity={visualMessage.severity}
          onClose={visualMessage.onClose}
        />
      )}
      <MainBackground isMobile={isMobile}>
        <Grid container style={{width:isMobile?"100%":"65%", margin:!isMobile?"3rem auto":0}}  >
          <Grid item xs={12} p={1}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <SvgIcon
                fontSize="medium"
                component={sendMoneyIcon}
                inheritViewBox
              />
              <Typography variant="h6" sx={{ fontWeight: "bold" }} pl={1}>
                Send Money
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} p={1}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography variant="h6" sx={{ fontWeight: "normal" }} pl={1}>
                  Manage Recipients
              </Typography>
            </div>
          </Grid>
          { (recipientService.isLoading) ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width:"100%",
              }}
            >
              <CircularProgress color="secondary" />
            </Box>
          ) : recipientService.isError ? (
            <Typography variant="h6" sx={{ fontWeight: "bold" }} color="secondary">
              Error: {recipientService.error.message}
            </Typography>
          ) : (
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "1rem",
                }}
              >
                <Paper
                  xs={12}
                  sx={{
                    borderRadius: "10px",
                    maxWidth:  "95%",
                    minWidth:  "95%",
                    backgroundColor: "rgba(255, 255, 255, 0.25)",
                    margin: "0.5rem",
                  }}
                  elevation={4}                
                >
                <br></br>
                  {recipientService?.recipients?.beneficiaries?.reduce((result, current) => {
                      const found = result.find(item => item.idBeneficiary === current.idBeneficiary);
                      if (!found || current.idBeneficiary === null || current.idBeneficiary === undefined) {
                        return result.concat([current]);
                      } else {
                        return result;
                      }
                    }, []).map((beneficiary) => { 
                    return(
                    <Grid item xs={12} textAlign={"left"} padding={"0.5rem"} marginInline={"0.5rem"} key={beneficiary.idMoneyMovementItem+"-"+beneficiary.idMoneyMovementBeneficiary} >
                      <Box display="flex" alignItems="center" gap={2}  >                     
                        <Box display="flex" alignItems="center" flexDirection={"column"}>
                          <Typography sx={{ minWidth: isMobile ? "14rem" : "14rem", maxWidth: isMobile ? "14rem" : "14rem", fontSize: "1rem", fontWeight: "bold" }} >
                          {beneficiary?.isOwn === true ? beneficiary?.alias : beneficiary?.firstName + " " + beneficiary?.lastName}
                          </Typography>
                          {
                            isMobile || isSmallScreen  ? (
                              <div style={{maxWidth: isMobile ? "14rem" : "14rem", textOverflow: "ellipsis", overflow:"hidden" }}>
                                <Typography sx={{ minWidth: isMobile ? "14rem" : "14rem", maxWidth: isMobile ? "14rem" : "14rem", fontSize: "0.9rem",  color: "black", textOverflow: "ellipsis", overflow: "hidden" }} >
                                {beneficiary?.isOwn === true ? beneficiary?.mask : beneficiary?.idCommunication}
                                </Typography>
                              </div>
                            ):null
                          }
                        </Box>  
                        {
                          (!isMobile && !isSmallScreen ) ? (
                            <Box display="flex" alignItems="center" flexDirection={"column"}>
                              <div style={{maxWidth: isMobile ? "14rem" : "14rem", textOverflow: "ellipsis", overflow:"hidden" }}>
                              <Typography sx={{ minWidth: isMobile ? "14rem" : "14rem", maxWidth: isMobile ? "14rem" : "14rem", fontSize: "0.9rem",  color: "black", textOverflow: "ellipsis", overflow: "hidden" }} >
                              {beneficiary?.isOwn === true ? beneficiary?.mask : beneficiary?.idCommunication}
                              </Typography>
                              </div>
                            </Box> 
                          ): null
                        }
                        { (<Box display="flex" alignItems="center" flexDirection="row" color="red" minWidth="1rem" maxWidth="1rem" onClick={() => handleCancelClick(sendMoneyTypeSelection==="nextDay" ? beneficiary.idMoneyMovementItem : beneficiary.idBeneficiary)} > {isSending && cancelId=== (sendMoneyTypeSelection==="nextDay" ? beneficiary.idMoneyMovementItem : beneficiary.idBeneficiary) ? <CircularProgress color="secondary" size="1rem" /> : <DeleteIcon size="1rem"/>} </Box>) }
                        {!isMobile && !isSmallScreen && !isStandardScreen ? (<Box display="flex" alignItems="center" flexDirection="row" color="red" > <Typography fontSize="1rem"> REMOVE</Typography> </Box>):null}
                      </Box>
                    </Grid>
                  )})}
                  <br></br>

                </Paper>
              </div>
            </Grid>
            )
          }
          <Grid item xs={12} pt={0}>
            <div
              style={{
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
            </div>
            <Grid alignContent="center" item>
              <LoadingButton
                sx={{
                  marginTop: "1rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  navigate("/sendMoney/nextDay");;
                }}
              >
                {getLabel("general.back", "Go Back")}
              </LoadingButton>
          </Grid>
          </Grid>
        </Grid>
      </MainBackground>
      <Menu isMobile={isMobile} />
    </div>
  );
}
