import {
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import * as React from "react";
import { Box } from "@mui/system";
import Menu from "../components/ui/Menu";
import useLabels from "../labels/useLabels";
import LoadingButton from "../components/ui/LoadingButton";
import { useFormik } from "formik";
import * as validatorYup from "yup";
import { useMutation } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import VisualMessage from "../components/ui/VisualMessage";
import { useNavigate } from "react-router-dom";
import CardService from '../services/CardService';
import MainBackground from "../components/ui/MainBackground";

export default function ReplaceCard() {
  const {getLabel} = useLabels();
  const cardService = CardService();

  const isMobile = useMediaQuery("(max-width:600px)");
  const [image, setImage] = React.useState(null);
  const [visualMessage, setVisualMessage] = React.useState();
  const navigate = useNavigate();

  const replaceMutation = useMutation(
    (replaceData) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/card/replace`,
        replaceData
      );
    },
    {
      onSuccess: (value) => {
        setVisualMessage({
          title: getLabel("replace.ok.title", "Replace Card"),
          message: getLabel(
            "replace.ok.message",
            "The card was replaced successfully"
          ),
          severity: "success",
          onClose: () => {
            setVisualMessage();
            navigate("/home");
          },
        });
      },
      onError: (values) => {
        setVisualMessage({
          title: getLabel("replace.error.title", "Replace Card Error"),
          message: getLabel(
            "replace.error.message",
            "There was an error, was not able to replace the card."
          ),
          onClose: () => setVisualMessage(),
        });
      },
    }
  );

  const validationSchema = validatorYup.object({
    status: validatorYup
      .string(),
    notes: validatorYup
      .string()
      .required("Notes are required"),
  });

  const formik = useFormik({
    initialValues: {
      status: "stolen",
      notes: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      replaceMutation.mutate({ ...values });
    },
  });

  async function getInitials(subdomain) {
    try {
      const [image] = await Promise.all([
        import(`../images/replace.${window.subdomain}.svg`),
      ]);
      setImage(image.default);
    } catch (error) {
      getInitials("rv");
    }
  }

  React.useEffect(() => {
    getInitials();
  }, []);

  return (
    <div style={{ display: true ? "block" : "none" }}>
      {visualMessage && (
        <VisualMessage
          title={visualMessage.title}
          message={visualMessage.message}
          severity={visualMessage.severity}
          onClose={visualMessage.onClose}
        />
      )}
      <MainBackground
        isMobile={isMobile}
      >
        <Grid container style={{width:isMobile?"100%":"65%", margin:!isMobile?"3rem auto":0}}  >
     
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        minHeight={isMobile ? "calc(100vh - 65px)" : "calc(100vh - 15px)"}
        display="flex"
        flexDirection="column"
        maxHeight="0px"
      >
        <Grid container sx={{ backgroundColor: "white" }}>
          <Grid item xs={12} p={1}>
            <Card elevation={0}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Replace your card - {cardService.cardDetail?.completeName}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                alt="reason"
                src={image}
                width={isMobile ? "130rem" : "200rem"}
                style={{ margin: "0 auto" }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Paper
                xs={12}
                sx={{
                  borderRadius: "10px",
                  maxWidth: "80rem",
                  backgroundColor: "white",
                }}
                elevation={4}
              >
                <Grid container p={2}>
                  <Grid item xs={12} pb={2} p={1}>
                    <Typography variant="body1" sx={{ fontWeight: "bolda" }}>
                    Replace your card for uninterrupted access and secure transactions. Enjoy the convenience of a fully functional card.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} p={1}>
                    <InputLabel htmlFor="status">Reason:</InputLabel>
                  </Grid>
                  <Grid item xs={12} p={1}>
                    <FormControl>
                      <RadioGroup
                        id="status"
                        name="status"
                        value={formik.values.reason}
                        onChange={ (event)=> formik.handleChange(event) }
                        row
                      >
                        <FormControlLabel value="stolen" control={<Radio />} label="Stolen" />
                        <FormControlLabel value="damaged" control={<Radio />} label="Damage" />
                        <FormControlLabel value="lost" control={<Radio />} label="Lost" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} p={1}>
                    <InputLabel htmlFor="notes">Notes:</InputLabel>
                  </Grid>
                  <Grid item xs={12} p={1}>
                    <FormControl fullWidth variant="outlined">
                      <OutlinedInput
                        id="notes"
                        fullWidth
                        sx={{
                          bgcolor: "white",
                        }}
                        value={formik.values.notes}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.notes && Boolean(formik.errors.notes)
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} p={1}>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={replaceMutation.isLoading}
                      sx={{ width: "100%", mb: "10px" }}
                    >
                      REPLACE THE CARD
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </Grid>
        </Grid>
      </Box>
      </Grid>
      </MainBackground>
      <Menu isMobile={isMobile} />
    </div>
  );
}
