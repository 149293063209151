/* Password Creation */

import * as React from "react";
import { Grid, CircularProgress } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import HttpService from "../../services/HttpService";

export default function StepPayment({
  onSave,
  email,
  code,
  recipient,
  amount,
}) {
  const currentStep = "6";
  const [sessionId, setSessionId] = React.useState("");

  const generateSessionID = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + "/externalPayment/createPaymentSession",
        data,
        { avoidBearer: true }
      );
    },
    {
      onSuccess: (values) => {
        console.log("sessionId 2: ", values);
        setSessionId(values.data.sessionId);
      },
      onError: (values) => {},
    }
  );

  React.useEffect(() => {
    console.log("sessionId 1: ", sessionId);
    if (email !== "" && code !== "" && recipient !== "" && amount !== "") {
      const data = {
        email,
        code,
        recipient,
        amount,
      };
      generateSessionID.mutate(data);
    }
  }, [email, code, recipient, amount]);

  console.log("sessionId: ", sessionId);

  return (
    <>
      <Grid container padding={0} margin={0}>
        <Grid item xs={12} p={0}>
          {sessionId ? (
            <iframe
              src={`https://stats.slimcd.com/soft/showsession.asp?sessionid=${sessionId}`}
              width="100%"
              height="500px"
              style={{ border: 0 }}
              title="Payment"
            />
          ) : (
            <div>
              <CircularProgress />
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
}
