import {
  CardMedia,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  SvgIcon,
} from "@mui/material";
import { Box } from "@mui/system";
import LogoutIcon from "@mui/icons-material/Logout";
import React from "react";
import UserService from "../../services/UserService";
import HomeIcon from "@mui/icons-material/Home";
import useLabels from "../../labels/useLabels";
import {ReactComponent as payanydayIcon} from "../../images/payanyday.svg";
import {ReactComponent as sendMoneyIcon} from "../../images/sendmoney.svg";
import {ReactComponent as billPayIcon} from "../../images/billpay.svg";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import FeaturesService from "../../services/config/FeaturesService";

export default function Menu(props) {
  const { cardFound = true } = props;
  const {getLabel} = useLabels();
  const featuresService = FeaturesService();
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = React.useState(false);
  const [smallLogo, setSmallLogo] = React.useState(null);
  const [logo, setLogo] = React.useState(null);

  

  async function getInitials(subdomain) {
    try {
      const [smallLogo, logo] = await Promise.all([
        import(`../../images/smallLogo.${subdomain}.svg`),
        import(`../../images/logo.${subdomain}.svg`),
      ]);
      setSmallLogo(smallLogo.default);
      setLogo(logo.default);
    } catch (error) {
      getInitials("rv");
    }
  }

  React.useEffect(() => {
    getInitials(window.subdomain);
  }, []);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowMenu(open);
  };

  const logoutFunc = (event) => {
    UserService.doLogout({ redirectUri: window.location.origin });
    event.preventDefault();
  };

  if (!smallLogo || !logo) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  return (
    <Box>
      <Drawer
        variant={props.isMobile ? "temporary" : "permanent"}
        anchor={props.isMobile ? "bottom" : "left"}
        open={props.isMobile ? showMenu : true}
        onClose={toggleDrawer(false)}
        PaperProps={{sx: {backgroundColor:props.isMobile ? undefined : "rgba(255, 255, 255, 0.5)", } }}
      >
        <Box onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)} p={1} pr={2} >
          {!props.isMobile ? (
            <Paper
              sx={{
                textAlign: "left",
                backgroundColor:"rgba(255, 255, 255, 0)"
              }}
              elevation={0}
            >
              <CardMedia
                image={logo}
                sx={{
                  height: "32px",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
            />
            </Paper>
          ) : null}
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate("/home")}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary={getLabel("menu.home", "Account")} />
              </ListItemButton>
            </ListItem>
            <Divider />
            {cardFound && featuresService.features?.PAD ? (
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate("/pad")}>
                <ListItemIcon>
                  <SvgIcon  component={payanydayIcon} inheritViewBox />
                </ListItemIcon>
                <ListItemText primary={getLabel("pad.home", "Pay Any-Day")} />
              </ListItemButton>
            </ListItem>
            ) : null}
            {cardFound && featuresService.features?.SM ? (
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate("/sendMoney")}>
                <ListItemIcon>
                  <SvgIcon  component={sendMoneyIcon} inheritViewBox />
                </ListItemIcon>
                <ListItemText primary={getLabel("sendMoney.home", "Send Money")} />
              </ListItemButton>
            </ListItem>
            ) : null}
            {cardFound && featuresService.features?.BP ? (
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate("/billPay")}>
                <ListItemIcon>
                  <SvgIcon  component={billPayIcon} inheritViewBox />
                </ListItemIcon>
                <ListItemText primary={getLabel("billPay.home", "BillPay")} />
              </ListItemButton>
            </ListItem>
            ) : null}
            {cardFound ? (
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate("/profile")}>
                <ListItemIcon>
                  <AccountBoxIcon/>
                </ListItemIcon>
                <ListItemText primary={getLabel("profile.home", "Profile")} />
              </ListItemButton>
            </ListItem>
            ) : null}
            <Divider />
            <ListItem disablePadding>
              <ListItemButton onClick={logoutFunc}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText
                  primary={getLabel("menu.logout", "Logout")}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      {props.isMobile ? (
        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            textAlign: "center",
          }}
          elevation={0}
        >
          <Paper
            sx={{
              borderRadius: "100px",
              height: "45px",
              width: "80px",
              margin: "auto",
            }}
            variant="outlined"
            onClick={(event) => {
              setShowMenu(!showMenu);
              event.preventDefault();
            }}
          >
            <CardMedia
              image={smallLogo}
              sx={{
                height: "40px",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            />
          </Paper>
        </Paper>
      ) : null}
    </Box>
  );
}
