/* Employer information */

import * as React from "react";
import { Grid, TextField, Typography } from "@mui/material";
import LoadingButton from "../../components/ui/LoadingButton";
import useLabels from "../../labels/useLabels";
import { useFormik } from "formik";
import * as validatorYup from "yup";
import { useMutation } from "@tanstack/react-query";
import HttpService from "../../services/HttpService";

export default function StepEmployerInformation({
  onSave,
  email,
  token,
  directEmployerId,
  bagContent,
}) {
  const thisStep = "2";
  const [error, setError] = React.useState("");
  const { getLabel } = useLabels();
  const validationSchema = validatorYup.object({
    employerId: validatorYup.string().required("Required field"),
    employeeId: validatorYup.string().required("Required field"),
  });
  const formik = useFormik({
    initialValues: {
      employerId: "",
      employeeId: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const transformedValues = {
        email: email,
        token: token,
        step: (Number(thisStep) + 1).toString(),
        bagContent: { ...values },
      };
      setError("");
      processStep.mutate({ ...transformedValues });
    },
  });
  const processStep = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/onboarding/saveStep`,
        data,
        { avoidBearer: true }
      );
    },
    {
      onSuccess: (values) => {
        onSave(formik.values.employerId);
      },
      onError: (values) => {
        setError(values.response.data);
      },
    }
  );
  React.useEffect(() => {
    if (bagContent !== null) {
      directEmployerId!==undefined && directEmployerId!==null && directEmployerId!=="" 
        ? formik.setFieldValue("employerId", directEmployerId) 
        : bagContent?.employerId
        ? formik.setFieldValue("employerId", bagContent?.employerId)
        : formik.setFieldValue("employerId", "");
      bagContent?.employeeId
        ? formik.setFieldValue("employeeId", bagContent?.employeeId)
        : formik.setFieldValue("employeeId", "");
    }
  }, [bagContent, directEmployerId]);

  React.useEffect(() => {
    setError("");
  }, [formik.values]);

  return (
    <>
      <Grid
        container
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <Grid item xs={6} p={0.5}>
          <TextField
            required
            size="small"
            label={"Employer code"}
            id="employerId"
            type={"text"}
            value={formik.values.employerId}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={bagContent?.employerIdDisabled==="true" || (directEmployerId!==undefined && directEmployerId!==null && directEmployerId!=="" ) }
            helperText={formik.touched.employerId && formik.errors.employerId}
            error={
              formik.touched.employerId && Boolean(formik.errors.employerId)
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={6} p={0.5}>
          <TextField
            required
            size="small"
            label="Your employee Id"
            id="employeeId"
            type={"text"}
            value={formik.values.employeeId}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={bagContent?.employeeIdDisabled==="true"}
            helperText={formik.touched.employeeId && formik.errors.employeeId}
            error={
              formik.touched.employeeId && Boolean(formik.errors.employeeId)
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2"> 
            {getLabel("onboarding." + thisStep + ".helpTitle", "This information should be provided by your employer.")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption"> 
            {getLabel("onboarding." + thisStep + ".helpContent", "If your employer is not participating in the Rellevate program, please put a zero \"0\" for the Employer and Employee number. You can participate in all the benefits of the Rellevate Digital Account, with the exception of the Pay Any-Day feature.")}
          </Typography>
        </Grid>
        <Grid item xs={12} p={0.5}>
          <LoadingButton
            size="small"
            variant="contained"
            color="primary"
            type="submit"
            loading={processStep.isLoading}
          >
            {getLabel("onboarding." + thisStep + ".btnContinue", "Continue")}
          </LoadingButton>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Typography variant="caption" color={"red"}>{error}</Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
}
