import { useQuery } from "@tanstack/react-query";
import HttpService from "./HttpService";

async function fetchToken() {
  const response = await HttpService.getAxiosClient().get(
    window.API_URL + `/payment/token?type=instant`
  );
  return response;
}

const PaymentTokenService = ({alias}) => {
  const { isLoading, isError, data, error } = useQuery(
    ["PaymentToken", alias],
    fetchToken,
    {
      refetchOnWindowFocus: false,
      staleTime: 10000
    }
  );

  const paymentToken = data?.data || null;
 
  return {
    paymentToken,
    isLoading,
    isError,
    error
  };
};

export default PaymentTokenService;
