import {
  Card,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import * as React from "react";
import { Box } from "@mui/system";
import Menu from "../components/ui/Menu";
import useLabels from "../labels/useLabels";
import LoadingButton from "../components/ui/LoadingButton";
import { useFormik } from "formik";
import * as validatorYup from "yup";
import { useMutation } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import VisualMessage from "../components/ui/VisualMessage";
import { useNavigate } from "react-router-dom";
import CardService from '../services/CardService';
import MainBackground from "../components/ui/MainBackground";

export default function Lock() {
  const {getLabel} = useLabels();

  const cardService = CardService();

  const isMobile = useMediaQuery("(max-width:600px)");
  const [image, setImage] = React.useState(null);
  const [visualMessage, setVisualMessage] = React.useState();
  const navigate = useNavigate();

  const lockMutation = useMutation(
    (lockData) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/card/lock`,
        lockData
      );
    },
    {
      onSuccess: (value) => {
        setVisualMessage({
          title: getLabel("lock.ok.title", "Lock Card"),
          message: getLabel(
            "lock.ok.message",
            "The card was succesfully locked"
          ),
          severity: "success",
          onClose: () => {
            setVisualMessage();
            navigate("/home");
          },
        });
      },
      onError: (values) => {
        setVisualMessage({
          title: getLabel("lock.error.title", "Lock Card Error"),
          message: getLabel(
            "lock.error.message",
            "There was an error, was not able to lock the card."
          ),
          onClose: () => setVisualMessage(),
        });
      },
    }
  );

  const validationSchema = validatorYup.object({
    reason: validatorYup
      .string()
      .required("Reason is required"),
  });

  const formik = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      lockMutation.mutate({ ...values });
    },
  });

  async function getInitials(subdomain) {
    try {
      const [image] = await Promise.all([
        import(`../images/lock.${window.subdomain}.svg`),
      ]);
      setImage(image.default);
    } catch (error) {
      getInitials("rv");
    }
  }

  React.useEffect(() => {
    getInitials();
  }, []);

  return (
    <div style={{ display: true ? "block" : "none" }}>
      {visualMessage && (
        <VisualMessage
          title={visualMessage.title}
          message={visualMessage.message}
          severity={visualMessage.severity}
          onClose={visualMessage.onClose}
        />
      )}
      <MainBackground
        isMobile={isMobile}
      >
        <Grid container style={{width:isMobile?"100%":"65%", margin:!isMobile?"3rem auto":0}}  >
     
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        minHeight={isMobile ? "calc(100vh - 65px)" : "calc(100vh - 200px)"}
        display="flex"
        flexDirection="column"
        maxHeight="0px"
      >
        <Grid container sx={{ backgroundColor: "white" }}>
          <Grid item xs={12} p={1}>
            <Card elevation={0}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Lock your card - {cardService.cardDetail?.completeName}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                alt="reason"
                src={image}
                width={isMobile ? "130rem" : "200rem"}
                style={{ margin: "0 auto" }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Paper
                xs={12}
                sx={{
                  borderRadius: "10px",
                  maxWidth: "80rem",
                  backgroundColor: "white",
                }}
                elevation={4}
              >
                <Grid container p={2}>
                  <Grid item xs={12} pb={2} p={1}>
                    <Typography variant="body1" sx={{ fontWeight: "bolda" }}>
                      You can easily lock your card to prevent unauthorized use. Your card will be temporarily disabled, and you will not be able to make any new purchases until you unlock it again. This feature provides extra security and peace of mind, giving you control over the access to your account.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} p={1}>
                    <InputLabel htmlFor="reason">Reason:</InputLabel>
                  </Grid>
                  <Grid item xs={12} p={1}>
                    <FormControl fullWidth variant="outlined">
                      <OutlinedInput
                        id="reason"
                        fullWidth
                        autoComplete="password"
                        sx={{
                          bgcolor: "white",
                        }}
                        // type={showPassword ? "text" : "password"}
                        value={formik.values.reason}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.reason && Boolean(formik.errors.reason)
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              // onClick={handleClickShowPassword}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {/* {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )} */}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} p={1}>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={lockMutation.isLoading}
                      sx={{ width: "100%", mb: "10px" }}
                    >
                      LOCK THE CARD
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </Grid>
        </Grid>
        </Box>
      </Grid>
      </MainBackground>
      <Menu isMobile={isMobile} />
    </div>
  );
}
