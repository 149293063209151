import * as React from "react";
import { Atomic, Product } from "@atomicfi/transact-javascript";
import { Button, Grid, Typography } from "@mui/material";
import DirectDepositService from "../../services/DirectDepositService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import HttpService from "../../services/HttpService";

export default function AtomicWidget( ) {
  const [showAtomicWidget, setShowAtomicWidget] = React.useState(false);
  const [publicToken, setPublicToken] = React.useState("");
  const [atomicCompanyID, setAtomicCompanyID] = React.useState("");
  const [showComponent, setShowComponent] = React.useState(false);
  const directDepositService = DirectDepositService();
  const queryClient = useQueryClient();

  const AtomicMutation = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/customer/direct-deposit-assisted`,
        data
      );
    },
    {
      onSuccess: (value) => {
        queryClient.invalidateQueries({ queryKey: ["direct-deposit"] });
      },
    }
  );

  const renderAtomic = () => {
    const configuration = {
        publicToken: publicToken,
        tasks: [{ operation: Product.DEPOSIT }],
        ...(atomicCompanyID && atomicCompanyID !== "" && {
          deeplink: { step: "login-company", companyId: atomicCompanyID },
        }),
      };
    Atomic.transact({
      config: configuration,
      onFinish: (data) => {
        setShowAtomicWidget(false);
        AtomicMutation.mutate(data);
      },
      onClose: () => {
        setShowAtomicWidget(false);
      },
    });
  };
  
  React.useEffect(() => {
    const depositInfo = directDepositService?.directDepositInfo;
    depositInfo?.publicToken && setPublicToken(depositInfo?.publicToken);
    depositInfo?.atomicCompanyId && setAtomicCompanyID(depositInfo?.atomicCompanyId);

    if (depositInfo?.depositFlowCompeted?.toLowerCase() === "true"  || depositInfo?.atomicEnabled?.toLowerCase() === "false"|| depositInfo?.atomicEnabled === undefined) {
      setShowComponent(false);
      return;
    }
    setShowComponent(depositInfo?.directDeposit?.toLowerCase() === "false");
  }
  , [directDepositService]);

  React.useEffect(() => {
    if (showAtomicWidget) {
      renderAtomic();
    }
  }
  , [showAtomicWidget]);

  return (
    <>
      {showComponent &&  
      <Grid container justifyContent="center" pb={1} item xs={12} style={{ textAlign: 'center' }}>
            <Button
              variant="contained" 
              size="small"
              color="secondary" 
              onClick={() => setShowAtomicWidget(true)}
              sx={{
                '&:hover .MuiTypography-root': {
                  color: 'primary.main',
                }
              }}
            >
              <Typography color="white" variant="caption" sx={{ fontWeight: 'bold',transition: 'color 0.3s ease', }}>
                Direct Deposit Not Set Up... Easily set it up here
              </Typography>
            </Button>
          </Grid>
      }
    </>
  );
}
