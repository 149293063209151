import React from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import HttpService from "../../services/HttpService";
import { useInView } from "react-intersection-observer";
import { Box } from "@mui/system";
import { Button, CircularProgress, Typography, Grid } from "@mui/material";
import useLabels from "../../labels/useLabels";

const formatMoney=(amount) => {
  const formattedAmount = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
  return formattedAmount;
}



async function fetchCardTransactions({pageParam = 0}) {
  const response = await HttpService.getAxiosClient().get(
    window.API_URL + "/card/transactions?cursor=" + pageParam
  );
  return { data: response.data, nextPage: pageParam+1 };
}

export default function CardTransactions() {
  const {getLabel} = useLabels();

  const { ref, inView } = useInView();

  const {
    status,
    data,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(["cardTransactions"], fetchCardTransactions, {
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.data.hasMore)
        return lastPage.data.pageNumber + 1;
      return undefined;
    },
  });

  React.useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  const getDateForTransaction = (dateString) => {
    const date = new Date(dateString);
    const monthNames = [
      getLabel("cardTransaction.Jan","Jan"),
      getLabel("cardTransaction.Feb","Feb"),
      getLabel("cardTransaction.Mar","Mar"),
      getLabel("cardTransaction.Apr","Apr"),
      getLabel("cardTransaction.May","May"),
      getLabel("cardTransaction.Jun","Jun"),
      getLabel("cardTransaction.Jul","Jul"),
      getLabel("cardTransaction.Aug","Aug"),
      getLabel("cardTransaction.Sep","Sep"),
      getLabel("cardTransaction.Oct","Oct"),
      getLabel("cardTransaction.Nov","Nov"),
      getLabel("cardTransaction.Dec","Dec"),
    ];
    const monthIndex = date.getMonth();
    const monthName = monthNames[monthIndex];
    const day = date.getDate();
    const year = date.getFullYear();
  
    const outputDateStr = `${monthName}-${day}, ${year}`;
    return outputDateStr;
  };

  return (
    <Box sx={{ m: 2 }}>
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        {getLabel("main.cardTransaction.title","Transactions:")}
      </Typography>
      {status === "loading" ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : status === "error" ? (
        <Typography variant="h6" sx={{ fontWeight: "bold" }} color="secondary">
          Error: {error.message}
        </Typography>
      ) : (
        <Grid container>
          {data.pages.map((page) => (
              <React.Fragment key={page.nextPage}>
              {page?.data?.transactions?.map((transaction) => {
                return(
                <Grid item key={transaction.id} xs={12} sx={{p:1}}>
                  <Grid container>
                    <Grid item xs={3} >
                      <Grid container>
                        <Grid borderColor={"primary"} item xs={12}  p={0} m={0} r={1} ml={ {md: 3} } mr={{xs: 1, md: 3} } sx={{ border: '2px solid', borderRadius: '20px' }}>
                            <Typography variant="body1" align="center" sx={{ color: transaction.amount < 0 ? 'red' : 'inherit', fontSize : transaction?.amount?.toString().length > 5 ? "0.7rem" :"1rem", paddingTop: transaction?.amount?.toString().length > 5 ? "0.15rem" :"0", paddingBottom: transaction?.amount?.toString().length > 5 ? "0.15rem" :"0"  }}>
                              {formatMoney(transaction.amount)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="caption" align="center">
                            <div align="center">
                              {formatMoney(transaction.availableBalance)}
                            </div>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={9}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography color="primary" sx={{ fontWeight: "bold" }}>
                            {transaction.concept}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography color="primary" variant="caption" >
                            {transaction.merchantName && transaction.merchantName.toUpperCase()}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography color="primary" variant="caption">
                            {getDateForTransaction(transaction.transactionDate)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )})}
            </React.Fragment>
          ))}
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button
              ref={ref}
              size="small"
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage || isFetchingNextPage}
              sx={{ textAlign: 'center' }}
            >
              {isFetchingNextPage
                ? getLabel("cardTransaction.loading","Loading more transactions...")
                : hasNextPage
                ? ""
                : getLabel("cardTransaction.noMore","No more transactions")}
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
//                    
