import { useQuery } from "@tanstack/react-query";
import HttpService from "./HttpService";

async function fetchDirectDepositDetails() {
  const response = await HttpService.getAxiosClient().get(
    window.API_URL + `/customer/direct-deposit-assisted`
  );
  return response;
}

const DirectDepositService = () => {
  const { isLoading, isError, data, error } = useQuery(
    ["direct-deposit"],
    fetchDirectDepositDetails,
    {
      refetchOnWindowFocus: false,
      staleTime: 10000
    }
  );

  const directDepositInfo = data?.data || null;


  return {
    directDepositInfo,
    isLoading,
    isError,
    error
  };
};

export default DirectDepositService;
