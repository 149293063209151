import { useQuery } from "@tanstack/react-query";
import HttpService from "../services/HttpService";

const _activeStatus = 'ACTIVE';
const _pendingStatus = 'PENDING';
const _suspendedStatus = 'SUSPENDED';

async function fetchCardDetails() {
  const response = await HttpService.getAxiosClient().get(
    window.API_URL + `/card`
  );
  return response;
}

const CardService = () => {
  const { isLoading, isError, data, error } = useQuery(
    ["cardDetails"],
    fetchCardDetails,
    {
      refetchOnWindowFocus: false,
      staleTime: 10000,
      retry: 0,
    }
  );

  const cardDetail = data?.data || null;
  const isActive = data?.data?.statusDisplay===_activeStatus || null;
  const isPending = data?.data?.statusDisplay===_pendingStatus || null;
  const isSuspended = data?.data?.statusDisplay===_suspendedStatus || null;

  return {
    isActive,
    isPending,
    isSuspended,
    cardDetail,
    
    isLoading,
    isError,
    error
  };
};

export default CardService;
