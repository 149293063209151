import { createSlice } from '@reduxjs/toolkit'

function getBrowserLanguage() {
  const languageCode = navigator.language ? navigator.language.slice(0, 2) : "en";
  if (languageCode === "es") {
    return "es";
  } else {
    return "en";
  }
}

export const languageSlice = createSlice({
  name: 'language',
  initialState: {
    profile: getBrowserLanguage(),
    loginLanguage: getBrowserLanguage(),
  },
  reducers: {
    setLanguage: (state, action) => {
      state.profile = action.payload;
    },
    setLoginLanguage: (state, action) => {
      state.profile = action.payload;
      state.loginLanguage = action.payload;
    },
  },
})

export const { setLanguage, setLoginLanguage } = languageSlice.actions

export default languageSlice.reducer