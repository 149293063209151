import {
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  SvgIcon,
  Box,
  Switch,
  Chip,
  Divider,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import HttpService from "../../services/HttpService";
import { useNavigate } from "react-router-dom";
import VisualMessage from "../../components/ui/VisualMessage";
import React, { useEffect } from "react";
import { ReactComponent as sendMoneyIcon } from "../../images/sendmoney.svg";
import useLabels from "../../labels/useLabels";
import Menu from "../../components/ui/Menu";
import { useQueryClient } from "@tanstack/react-query";
import NotificationService from "../../services/config/NotificationService";
import LoadingButton from "../../components/ui/LoadingButton";
import MainBackground from "../../components/ui/MainBackground";



export default function Notifications() {
  const {getLabel} = useLabels();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");

  const queryClient = useQueryClient()


  const [visualMessage, setVisualMessage] = React.useState();
  

  const notificationService = NotificationService();


  const toggleNotificationMutation = useMutation(
    (preference) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/customer/notifications/preferences`,
        {
            ...preference, active: !preference.active
        }
      );
    },
    {
      onSuccess: (value) => {
        setVisualMessage({
          title: getLabel("toggle.notification.ok.title", "Notification setup"),
          message: getLabel(
            "toggle.notification.ok.message",
            "The notification was successfully setup"
          ),
          severity: "success",
          onClose: () => {
            setVisualMessage();
            queryClient.invalidateQueries({ queryKey: ['notifications'] })
          },
        });
        setIsSending(false);
      },
      onError: (values) => {
        const message = values?.response?.data?.message;
        const exceptionMessage = values?.response?.data?.exceptionMessage;

        setVisualMessage({
          title: getLabel("toggle.notification.error.title", "Notification setup error"),
          message: getLabel(
            "toggle.notification.error.message."+message,
            exceptionMessage!==null && exceptionMessage!==undefined? exceptionMessage : "The notification was not successfully setup"
          ),
          onClose: () => setVisualMessage(),
        });
        setIsSending(false);
      },
    }
  );

  const [isSending, setIsSending] = React.useState(false);
  
  const [selectedStrategy, setSelectedStrategy] = React.useState("EMAIL");

  async function getInitials(subdomain) {}


  const [switchStates, setSwitchStates] = React.useState(
    notificationService?.notifications?.notificationPreferences?.reduce((acc, preference) => {
      acc[`${preference.type}-${preference.strategy}`] = preference.active ?? false;
      return acc;
    }, {}) ?? {}
  );
  useEffect(() => {
    if (notificationService?.notifications?.notificationPreferences) {
      setSwitchStates(
        notificationService.notifications.notificationPreferences.reduce((acc, preference) => {
          acc[`${preference.type}-${preference.strategy}`] = preference.active;
          return acc;
        }, {})
      );
    }
  }, [notificationService?.notifications?.notificationPreferences]);
  

  const handleToggleSwitch = async (preference) => {
    setIsSending(true);

    toggleNotificationMutation.mutate(preference);
  };


  return (
    <div style={{ display: true ? "block" : "none" }}>
      {visualMessage && (
        <VisualMessage
          title={visualMessage.title}
          message={visualMessage.message}
          severity={visualMessage.severity}
          onClose={visualMessage.onClose}
        />
      )}
      <MainBackground
        isMobile={isMobile}
      >
        <Grid container style={{width:isMobile?"100%":"65%", margin:!isMobile?"3rem auto":0}}  >
          <Grid item xs={12} p={1}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <SvgIcon
                fontSize="medium"
                component={sendMoneyIcon}
                inheritViewBox
              />
              <Typography variant="h6" sx={{ fontWeight: "bold" }} pl={1}>
                Notifications
              </Typography>
            </div>
          </Grid>
          { (notificationService.isLoading || switchStates===undefined ) ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width:"100%",
              }}
            >
              <CircularProgress color="secondary" />
            </Box>
          ) : notificationService.isError ? (
            <Typography variant="h6" sx={{ fontWeight: "bold" }} color="secondary">
              Error: {notificationService.error.message}
            </Typography>
          ) : (
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "1rem",
                }}
              >
                <Paper
                  xs={12}
                  sx={{
                    borderRadius: "10px",
                    maxWidth:  "95%",
                    minWidth:  "95%",
                    backgroundColor: "rgba(255, 255, 255, 0.25)",
                    margin: "0.5rem",
                  }}
                  elevation={4}                
                >
                <br></br>
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                  <Chip onClick={() => {setSelectedStrategy("EMAIL");  queryClient.invalidateQueries({ queryKey: ['notifications'] })}}  label="Email" variant={selectedStrategy==="EMAIL"  ? "outlined" : "filled"}  />
                  <Chip onClick={() => {setSelectedStrategy("SMS");  queryClient.invalidateQueries({ queryKey: ['notifications'] })}} label="SMS" variant={selectedStrategy==="SMS"  ? "outlined" : "filled"} />
                </div>
                <br></br>
                <br></br>
                  {notificationService?.notifications?.notificationPreferences?.filter((preference) => preference.strategy===selectedStrategy)?.map((preference) => { 
                    
                    return(
                    <Grid item xs={12} textAlign={"left"} padding={"0.5rem"} marginInline={"0.5rem"} key={preference.type+"-"+preference.strategy} >
                      <Box display="flex" alignItems="center" gap={2}  >                     
                        <Box display="flex" alignItems="center" flexDirection={"column"}>
                          <Typography sx={{ minWidth: isMobile ? "14rem" : "14rem", maxWidth: isMobile ? "14rem" : "14rem", fontSize: "1rem", fontWeight: "bold" }} >
                             {getLabel("config.notifications."+preference?.type, preference?.type)}
                          </Typography>
                        </Box>  
                        <Box display="flex" alignItems="center" flexDirection={"column"}>

                          <Switch
                              checked={!!switchStates[`${preference?.type}-${preference?.strategy}`]}
                              onChange={() => {
                                setSwitchStates({
                                  ...switchStates,
                                  [`${preference?.type}-${preference?.strategy}`]: !switchStates[`${preference?.type}-${preference?.strategy}`]
                                });
                                handleToggleSwitch(preference);
                              }}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </Box>  
                       </Box>
                       <Divider />

                    </Grid>
                  )})}
                  <br></br>

                </Paper>
              </div>
            </Grid>
            )
          }
          <Grid item xs={12} pt={0}>
            <div
              style={{
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
            </div>
            <Grid alignContent="center" item>
              <LoadingButton
                sx={{
                  marginTop: "1rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  navigate(-1);;
                }}
              >
                {getLabel("general.back", "Go Back")}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </MainBackground>
      <Menu isMobile={isMobile} />
    </div>
  );
}
