import * as React from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  SvgIcon,
  Paper,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import useLabels from "../labels/useLabels";
import { useNavigate } from "react-router-dom";

export default function PageMenuOption(props) {
  const {getLabel} = useLabels();
  const isMobile = props.isMobile;
  const navigate = useNavigate();

  return (
    <Grid item xs={12} p={1}>
      <Button
        fullWidth
        variant="contained"
        sx={(theme) => ({
          height: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: isMobile ? "right" : "center",
          paddingRight: "1rem",
          cursor: "pointer",
        })}
        onClick={(event) => {
          if (props.navigateTo !== null && props.navigateTo !== undefined) {
            navigate(props.navigateTo);
          }
          if (props.callToAction != null && props.callToAction !== undefined) {
            props.callToAction(event);
          }
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="body1">
            {getLabel(props.label, props.defaultLabel)}
          </Typography>
          {isMobile ? props.icon : null}
        </div>
      </Button>
    </Grid>
  );
}
