import * as React from "react";
import Box from "@mui/material/Box";
import Background from "../components/ui/Background";
import { FormControl, Grid, InputLabel, OutlinedInput, InputAdornment, IconButton, FormHelperText } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Typography from "@mui/material/Typography";

import { useFormik } from "formik";
import * as validatorYup from "yup";
import { useMutation } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import Header from "../components/ui/Header";
import { useNavigate } from "react-router-dom";
import VisualMessage from "../components/ui/VisualMessage";
import LoadingButton from "../components/ui/LoadingButton";
import useLabels from "../labels/useLabels";
import { useSelector } from "react-redux";

const validationSchema = validatorYup.object({
  code: validatorYup.string().required()
});

const vsChangePassword = validatorYup.object({
  newPassword1: validatorYup.string().min(6, "At least 6 characters")
  .max(20, "At most 20 characters")
  .matches(/\d/, "At least one number")
  .matches(/[ _!@#$%&*]/, "At least one special character (_!@#$%&*)")
  .required('Required field'),
  newPassword2: validatorYup.string().oneOf([validatorYup.ref('newPassword1')], 'Passwords must match').required('Required field'),
});

export default function RecoveryPasswordStep1() {
  const {getLabel} = useLabels();
  const formikResetPassword = useFormik({
    initialValues: {
      newPassword1: "",
      newPassword2: "",
    },
    validationSchema: vsChangePassword,
    onSubmit: (values) => {
      values.username = username;
      values.code = formik.values.code;
      resetPasswordMutation.mutate({ ...values });
    },
  });
  
  
  const [loaded, setLoaded] = React.useState(false);
  const [backgroundImage, setBackgroundImage] = React.useState(null);
  const [bgDesktop2, setBgDesktop2] = React.useState(null);
  const [emailSecureImg, setEmailSecureImg] = React.useState(null);
  const [visualMessage, setVisualMessage] = React.useState();
  const [otpIsValid, setOtpIsValid] = React.useState(false);
  
  const navigate = useNavigate();

  React.useEffect(() => {
    async function getInitials(subdomain) {
      try {
        const [backgroundImage, bgImage2, emailSecureImg] = await Promise.all([
          import(`../images/bg_login.${subdomain}.jpg`),
          import(`../images/bg_desktop.2.${subdomain}.jpg`),
          import(`../images/email_secure.${subdomain}.svg`),
        ]);
        setBackgroundImage(backgroundImage.default);
        setBgDesktop2(bgImage2.default);
        setEmailSecureImg(emailSecureImg.default);
      } catch (error) {
        getInitials("rv");
      }
    }

    getInitials(window.subdomain);
    sendOTPMutation.mutate({ recipient: username, from: "forgot-password" });
    setOtpIsValid(false);
    formik.setValues({ code: "" });
    formikResetPassword.setValues({ newPassword1: "", newPassword2: "" });
  }, []);

  const sendOTPMutation = useMutation((OTPRequest) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailRegex.test(OTPRequest.recipient)) {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/login/open/otp/generate`,
        OTPRequest,
        { avoidBearer: true }
      );
    };
  });

  const validateOTPMutation = useMutation(
    (OTPValidateRequest) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/login/open/otp/validate`,
        OTPValidateRequest
      );
    },
    {
      onSuccess: (value) => {
        setOtpIsValid(true);
      },
      onError: (values) => {
        setOtpIsValid(false);
        setVisualMessage({
          title: getLabel("recoveryPassword.step1.verify.failed.tittle", "Verification failed"),
          message: getLabel(
            "recoveryPassword.step1.verify.failed.message",
            "The security code doesn't match"
          ),
          onClose: () => setVisualMessage(),
        });
      },
    }
  );

  const resetPasswordMutation = useMutation(
    (ResetPasswordRequest) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/login/resetPassword`,
        ResetPasswordRequest
      );
    },
    {
      onSuccess: (value) => {
        setVisualMessage({
          title: getLabel("recoveryPassword.step1.reset.success.tittle", "Reset password success"),
          message: getLabel(
            "recoveryPassword.step1.reset.success.message",
            "Your password has been reset successfully."
          ),
          severity: "success",
          onClose: () => {setVisualMessage(); navigate("/");},
        });
      },
      onError: (values) => {
        setVisualMessage({
          title: getLabel("recoveryPassword.step1.reset.fail.tittle", "Reset password failed"),
          message: getLabel(
            "recoveryPassword.step1.reset.fail.message",
            "There was an error, was not able to reset the password."
          ),
          severity: "error",
          onClose: () => {setVisualMessage()},
        });
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: validationSchema,
  });

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const username = useSelector((state) => state.user.value);

  const verifyCode = (event) => {
    event.preventDefault();
    if (formik.values.code === "" || formik.values.code === null || formik.values.code === undefined) {
      setVisualMessage({
        title: getLabel("recoveryPassword.step1.verify.failed.tittle", "Verification failed"),
        message: getLabel(
          "recoveryPassword.step1.verify.failed.required",
          "The security code is required."
        ),
        severity: "error",
        onClose: () => setVisualMessage(),
      });
      return;
    }

    validateOTPMutation.mutate({
      recipient: username,
      from: "forgot-password",
      combinationCode: formik.values.code,
    });
    
  }

  const resetPasswordForm = () => (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={
        formikResetPassword.handleSubmit
      }
    >
      <Grid
        alignItems="center"
        container
        justifyContent="center"
        padding={0}
        pt={10}
        sx={{ width: "100%", height: "100%" }}
      >
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Open Sans",
            color: "loginText.main",
          }}
        >
          {getLabel(
            "login.resetPassword.title","Please reset your password"
          )}
        </Typography>
        <Grid item sx={{ width: "85%" }} textAlign={"left"}>
          <InputLabel
            htmlFor="newPassword1"
            sx={{
              fontFamily: "Open Sans",
              color: "loginText.main",
            }}
          >
            {getLabel(
              "login.changePassword.newPassword1.label", "New password:"
            )}
          </InputLabel>
        </Grid>
        <Grid item sx={{ width: "85%" }}>
          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              id="newPassword1"
              autoComplete="password"
              sx={{
                bgcolor: "white",
              }}
              type={showPassword ? "text" : "password"}
              value={formikResetPassword.values.newPassword1}
              onChange={formikResetPassword.handleChange}
              error={
                formikResetPassword.touched.newPassword1 &&
                Boolean(formikResetPassword.errors.newPassword1)
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText sx={{ color: "red"}}>
                {formikResetPassword.errors.newPassword1}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item sx={{ width: "85%" }} textAlign={"left"} pt={1}> 
          <InputLabel
            htmlFor="newPassword2"
            sx={{
              fontFamily: "Open Sans",
              color: "loginText.main",
            }}
          >
            {getLabel(
              "login.changePassword.newPassword2.label",
              "Repeat the new password:"
            )}
          </InputLabel>
        </Grid>
        <>{formikResetPassword.touched.newPassword2 &&
                Boolean(formikResetPassword.errors.newPassword2)}</>
        <Grid item sx={{ width: "85%" }}>
          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              id="newPassword2"
              autoComplete="password"
              sx={{
                bgcolor: "white",
              }}
              type={showPassword ? "text" : "password"}
              value={formikResetPassword.values.newPassword2}
              onChange={formikResetPassword.handleChange}
              error={
                formikResetPassword.touched.newPassword2 &&
                Boolean(formikResetPassword.errors.newPassword2)
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText sx={{ color: "red"}}>
                {formikResetPassword.errors.newPassword2}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item sx={{ width: "65%" }} pt={3}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={resetPasswordMutation.isLoading}
            sx={{ width: "100%", mb: "10px" }}
          >
            {getLabel("resetPassword.btnLabel", "Reset Password")}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );

  const validateOtpForm = () => {
    return (
      <>
    <Grid item xs={12} mt={10}>
    <Typography color="primary" variant="h5">
      {getLabel(
        "recoveryPassword.step1.header.line1"
      )}
    </Typography>
  </Grid>
  <Grid item xs={12} mt={1}>
    <img src={emailSecureImg} alt="email secure" />
  </Grid>
  <Grid item xs={12} mt={2}>
    <Typography color="primary">
      {getLabel(
        "recoveryPassword.step1.header.line2"
      )}
    </Typography>
  </Grid>
  <Grid item xs={12}>
    <Typography color="primary">
      {getLabel(
        "recoveryPassword.step1.header.line3"
      )}
    </Typography>
  </Grid>
  <Grid
    item
    sx={{ width: "85%" }}
    mt={1}
    textAlign={"left"}
  >
    <InputLabel
      htmlFor="code"
      sx={{
        fontFamily: "Open Sans",
        color: "loginText.main",
      }}
    >
      {getLabel("recoveryPassword.step1.code")}
    </InputLabel>
  </Grid>
  <Grid item sx={{ width: "85%" }}>
    <FormControl fullWidth variant="outlined">
      <OutlinedInput
        id="code"
        sx={{
          bgcolor: "white",
        }}
        placeholder={getLabel(
          "recoveryPassword.step1.code.placeholder"
        )}
        value={formik.values.code}
        onChange={formik.handleChange}
        error={
          formik.touched.code &&
          Boolean(formik.errors.code)
        }
      />
    </FormControl>
  </Grid>
  <Grid item sx={{ width: "75%" }} mt={9}>
    <LoadingButton
      type="submit"
      variant="contained"
      loading={validateOTPMutation.isLoading}
      sx={{ width: "100%", mb: "10px" }}
      onClick={(event) => verifyCode(event)}
    >
      {getLabel(
        "recoveryPassword.step1.btnContinue"
      )}
    </LoadingButton>
  </Grid>
  <Grid item sx={{ width: "75%" }}>
    <LoadingButton
      color="secondary"
      type="submit"
      variant="contained"
      loading={sendOTPMutation.isLoading}
      sx={{ width: "100%", mb: "10px" }}
      onClick={(event) => {
        event.preventDefault();
        sendOTPMutation.mutate({
          recipient: username,
          from: "forgot-password",
        });
      }}
    >
      {getLabel(
        "recoveryPassword.step1.btnResend"
      )}
    </LoadingButton>
  </Grid></>)
  };
  


  return (
    <div style={{ display: loaded ? "block" : "none" }}>
      <Background image={backgroundImage} onLoad={() => setLoaded(true)}>
        {visualMessage && (
          <VisualMessage
            title={visualMessage.title}
            message={visualMessage.message}
            severity={visualMessage.severity}
            onClose={visualMessage.onClose}
          />
        )}
        <main>
          <Box>
            <Grid container alignItems="flex-start" sx={{ height: "100vh" }}>
              <Grid container sx={{ height: "600px" }}>
                <Header />
                <Grid
                  item
                  xs={6}
                  sx={{
                    textAlign: "center",
                    backgroundImage: `url(${bgDesktop2})`,
                    backgroundSize: "cover",
                    backgroundPosition: "40% center",
                    width: "100%",
                    height: "100vh",
                    display: { xs: "none", sm: "block" },
                  }}
                />
                <Grid item xs={12} sm={6} sx={{ textAlign: "center" }}>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: "100vh" }}
                  >
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <main>
                        <Box>
                          <Grid
                            alignItems="center"
                            container
                            justifyContent="center"
                            padding={0}
                            pt={1}
                            sx={{ width: "100%", height: "100%" }}
                          >
                            <Grid
                              item
                              sx={{ width: "85%" }}
                              textAlign={"left"}
                              mt={5}
                            >
                              <InputLabel
                                htmlFor="username"
                                sx={{
                                  fontFamily: "Open Sans",
                                  color: "loginText.main",
                                }}
                              >
                                {getLabel(
                                  "recoveryPassword.step1.username"
                                )}
                              </InputLabel>
                            </Grid>
                            <Grid item sx={{ width: "85%" }}>
                              <FormControl fullWidth variant="outlined">
                                <OutlinedInput
                                  disabled
                                  id="username"
                                  sx={{
                                    bgcolor: "white",
                                  }}
                                  value={username}
                                />
                              </FormControl>
                            </Grid>
                            {!otpIsValid?validateOtpForm():resetPasswordForm()}
                          </Grid>
                        </Box>
                      </main>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </main>
      </Background>
    </div>
  );
}
