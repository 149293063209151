import { useQuery } from "@tanstack/react-query";
import HttpService from "../HttpService";

async function fetchConfig(employerId, attributeId) {
  const response = await HttpService.getAxiosClient().get(
    window.API_URL + `/onboarding/employer/${employerId}/attributes/${attributeId}`,
    {avoidBearer: true}
  );
  return response;
}

const OnboardingEmployerAttributeService = (employerId, attributeId) => {
  const { isLoading, isError, data, error } = useQuery(
    ["onboarding.attributes", employerId, attributeId],
    () => fetchConfig(employerId, attributeId),
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
      retry: () => false,
      enabled: !!employerId && !!attributeId,
    }
  );

  const attribute = data?.data || null;

  return {
    attribute,
    isLoading,
    isError,
    error
  };
};

export default OnboardingEmployerAttributeService;
