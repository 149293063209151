import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MainBackground from "./MainBackground";
import { Box } from "@mui/material";
import tickImage from '../../images/tick.png';

const SuccessfullTransaction = ({ message, onClose, id }) => {
    const handleClose = () => {
      if (onClose) {
        onClose();
      }
    };
  
    return (
      <MainBackground 
          style={{ paddingInline: "3rem" } }
      >
      <Grid justifyContent="center" alignItems="center" container style={{ minHeight: '100vh' }}>
        <Grid item sm={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Box component="img" src={tickImage} alt="Tick" sx={{ width:"12rem" }} />
            <Typography sx={{paddingTop: "1rem"}}>
              Your transaction has been completed successfully
            </Typography>
            <Typography  sx={{paddingTop: "1rem"}} >{message}</Typography>
            {id && <Typography sx={{paddingTop: "0.2rem", fontWeight:"bold", fontSize:"1.5rem"}}>{id}</Typography>}
            {onClose && <Button sx={{paddingTop: "1rem"}} onClick={handleClose}>Close</Button> }
          </div>
        </Grid>
      </Grid>
      </MainBackground>
    );
  };


export default SuccessfullTransaction;