import React, { useEffect } from "react";
import CardTokenService from "../../services/CardTokenService";
import { useMediaQuery, useTheme } from "@mui/material";
import CardImage from './CardImage';

const PanView = ({onTimeout, cardService}) => {
  const cardTokenService = CardTokenService();
  const [btnDisplay, setBtnDisplay] = React.useState("block");
  const isMobile = useMediaQuery("(max-width:600px)");
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerWidth);
  
  const theme = useTheme();
  
  useEffect(() => {
    if (isMobile) {
      setWidth(320);
      setHeight(340);
    } else {
      setWidth(380);
      setHeight(390);
    }
  }, [isMobile]);

    const cardImage = CardImage();

  const callWidget = (cardToken) => {
    const urlScript = window.ism_widget_url;

    const whenLoad = () => {
      const widget = {
        onLoadIframe: (iframe_loaded) => {
        },
        onSuccess: (details) => {
          onTimeout();
        },
        onFailure: (details) => {
        },
        frame: {
          container_id: "widget_container",
          iframe_class: "widget-iframe",
          filter:
            "invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%)",
        },
        target_origin: window.ism_target_origin,
        config: {
          token: cardToken,
          main: "show_pan",
          css: {
            container: { 
              border: 0,
              margin: 0,
              overflowX: "hidden",
              "padding": 0 
            },
            vc: {
              color: "black",
            },
            vc_bg: {
              "background-image": `url(${origin + cardImage})`,
            },
            vc_number: {
              top: "69%",
              left: "-10%",
              color: theme?.palette?.cardText?.[cardService.cardDetail.brand]?.[cardService.cardDetail.shortLabel] || theme?.palette?.cardText?.default || "grey",
              "font-size": "16px",
            },
            vc_logo: {
              display: "none",
            },
            vc_network: {
              display: "none",
            },
            "btn-sm": {
              display: btnDisplay,
            },
            "mt-2": {
              display: "none",
            },
            vc_exp_date: {
              color: theme?.palette?.cardText?.[cardService.cardDetail.brand]?.[cardService.cardDetail.shortLabel] || theme?.palette?.cardText?.default || "grey",
              left: "-5%",
              "font-size": "16px",
            },
            body: {},
            "col-12": {
              display: "none",
            },
          },
          content:{
            submit_text:"Show"
          }
        },
      };

      const iframe = document.getElementById("widget_iframe");
      const pciWidget = iframe.contentWindow.pciWidget;
      pciWidget(widget);

      const widget_container =
        iframe.contentDocument.getElementById("widget_container");
      if (widget_container) {
        widget_container.style.width = `${width}px`;
        widget_container.style.height = `${height}px`;
        widget_container.style.overflow = "hidden";
      }

      const widget_frame =
        iframe.contentDocument.getElementById("widget_frame");
      if (widget_frame) {
        widget_frame.style.width = widget_container.style.width;
        widget_frame.style.height = widget_container.style.height;
        widget_frame.style.overflow = "hidden";
      }

    };

    const iframe = document.getElementById("widget_iframe");

    const script = document.createElement("script");
    script.src = urlScript;
    script.addEventListener("load", () => whenLoad());

    iframe.contentDocument.body.appendChild(script);
    const div = iframe.contentDocument.createElement("div");
    div.id = "widget_container";
    div.name = "widget_container";
    iframe.contentDocument.body.appendChild(div);
    

    return () => {};
  };

  React.useEffect(() => {
    if (cardTokenService?.cardToken?.token)
      callWidget(cardTokenService?.cardToken?.token);
  }, [cardTokenService?.cardToken?.token]);

  return (
    <>
      <iframe
        title="QoloViewPanWidget"
        id="widget_iframe"
        width={width+30}
        height={height+20}
        style={{ border: "none", overflow: "hidden", margin: "auto", display: "block" }}
      ></iframe>
    </>
  );
};

export default PanView;
