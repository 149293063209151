import { useQuery } from "@tanstack/react-query";
import HttpService from "../HttpService";

async function fetchTransferFee(type) {
  const response = await HttpService.getAxiosClient().get(
    window.API_URL + `/payment/transfer/fee?recipientType=${type}`
  );
  return response;
}

const TransferFeeService = (type) => {
  const { isLoading, isError, data, error } = useQuery(
    ["transferFee", type],
    () => fetchTransferFee(type),
    {
      refetchOnWindowFocus: false,
      staleTime: 10000
    }
  );

  const fee = data?.data || null;

  return {
    fee,
    isLoading,
    isError,
    error
  };
};

export default TransferFeeService;
