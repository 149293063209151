import * as React from "react";
import { Grid } from "@mui/material";

export default function Background(props) {
  return (
    <Grid
      sx={{
        minHeight: "600px",
        minWidth: "100vw",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundImage: `url(${props.image})`,
      }}
      onLoad={props.onLoad}
    >
      {props.children}
    </Grid>
  );
}
